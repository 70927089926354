import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgBpSingle = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill={`url(#${_id})`}
        fillRule="nonzero"
        d="M18.203 14.585c1.101 0 1.994.893 1.994 1.995v.62c0 .862-.308 1.695-.868 2.35-1.619 1.891-4.089 2.803-7.333 2.803s-5.712-.912-7.328-2.804a3.6 3.6 0 0 1-.865-2.346v-.623c0-1.102.893-1.995 1.994-1.995zm0 .756H5.797a1.24 1.24 0 0 0-1.239 1.239v.623a2.86 2.86 0 0 0 .685 1.856c1.422 1.666 3.645 2.539 6.753 2.539s5.333-.872 6.759-2.539a2.86 2.86 0 0 0 .687-1.859v-.62a1.24 1.24 0 0 0-1.239-1.239M11.996 1.647a4.96 4.96 0 0 1 4.962 4.962 4.96 4.96 0 1 1-9.923 0 4.96 4.96 0 0 1 4.961-4.962m0 .755A4.21 4.21 0 0 0 7.79 6.609a4.206 4.206 0 1 0 8.413 0 4.21 4.21 0 0 0-4.207-4.207"
      />
      <defs>
        <linearGradient
          id={_id}
          x1={-0.94}
          x2={21.483}
          y1={20.63}
          y2={10.538}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939d4" />
          <stop offset={0.48} stopColor="#5d4df8" />
          <stop offset={0.74} stopColor="#b459f9" />
          <stop offset={1} stopColor="#fe3b68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgBpSingle;
