import type { ReactNode } from 'react';

import type { CustomError, NetworkError } from '@quirion/api';

import type { StackTemplates } from '../../Errors/types';

/** Type Map */
export enum BannerVariant {
  Info = 'info',
  Error = 'error',
  Success = 'success',
}

export enum BannerActionType {
  HideBanner = 'hideBanner',
  Notify = 'notify',
  ShowError = 'showError',
  ShowSuccess = 'showSuccess',
  ShowInfo = 'showInfo',
  ShowErrorNotification = 'showErrorNotification',
  ShowSuccessNotification = 'showSuccessNotification',
  ShowInfoNotification = 'showInfoNotification',
}

export type BannerVariantMap = Record<BannerVariant, ReactNode>;

export type BannerNotificationOptions = {
  /**
   * Semantic styling of banner
   */
  variant?: BannerVariant | `${BannerVariant}`;
  /*
   * Setting autoHide to a positive number will hide this banner after set amount of ms.
   * Default is false;
   */
  autoHide?: number | false;
  /*
   * Sets the animation duration for all animations in ms.
   * Default is 300.
   */
  animationDuration?: number;
  /*
   * Disable close button on Banner
   */
  disableClose?: boolean;
  onClose?: () => void;
};

export type BannerProps = BannerContent & BannerNotificationOptions;

interface NotificationFunction {
  (notificationInput: NotificationInput): Promise<string>;
  (message: ReactNode): Promise<string>;
}

export type BannerState = {
  banners: BannerProps[];
  notify: NotificationFunction;
  showError(
    title: ReactNode,
    description?: string,
    stack?: BannerStack,
  ): string;
  showSuccess(title: ReactNode, description?: string): string;
  showInfo(title: ReactNode, description?: string): string;
  showErrorNotification(
    title: ReactNode,
    description?: string,
    options?: Partial<BannerNotificationOptions>,
  ): string;
  showSuccessNotification(
    title: ReactNode,
    description?: string,
    options?: Partial<BannerNotificationOptions>,
  ): string;
  showInfoNotification(
    title: ReactNode,
    description?: string,
    options?: Partial<BannerNotificationOptions>,
  ): string;
  hideBanner(id: string): void;
};

export type BannerContentShared = {
  id?: string;
  title?: ReactNode;
  description?: string;
};

export type StackTemplateObject<T = unknown> = {
  templateName: StackTemplates | string;
  content: T;
};

export type BannerStack = string | StackTemplateObject;

export type BannerContentStack = {
  stack?: BannerStack;
};

export type BannerContent = BannerContentShared & BannerContentStack;

export type BannerOptions = {
  options: BannerNotificationOptions;
};

export type ErrorInput = {
  /**
   * Error object to be displayed in the banner.
   *
   * Message can be replaced with a custom message by passing additionally title and/or description
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: NetworkError | CustomError | Error | unknown;
};

export type NotificationInput = BannerContent &
  BannerNotificationOptions &
  ErrorInput;

export type BannerAction =
  | { type: BannerActionType.HideBanner; payload: string }
  | { type: BannerActionType.Notify; payload: NotificationInput }
  | { type: BannerActionType.ShowError; payload: BannerContent }
  | { type: BannerActionType.ShowSuccess; payload: BannerContentShared }
  | { type: BannerActionType.ShowInfo; payload: BannerContentShared }
  | {
      type: BannerActionType.ShowErrorNotification;
      payload: BannerContentShared & BannerOptions;
    }
  | {
      type: BannerActionType.ShowSuccessNotification;
      payload: BannerContentShared & BannerOptions;
    }
  | {
      type: BannerActionType.ShowInfoNotification;
      payload: BannerContentShared & BannerOptions;
    };
