import React, { FC, PropsWithChildren, ReactNode } from 'react';

import { Caption, Heading, HeadingType } from '../../Typefaces';
import { ClassStyle } from '../../types/ClassStyle.model';

import styles from './Screen.module.css';

export type ScreenProps = PropsWithChildren<
  {
    heading?: ReactNode;
    supheading?: ReactNode;
    subheading?: ReactNode;
    withSpace?: boolean;
    withPadding?: boolean;
    headingTag?: HeadingType;
    headingVariant?: HeadingType;
    classNameHeader?: string;
  } & ClassStyle
>;

export const Screen: FC<ScreenProps> = ({
  children,
  /**
   * Standard Heading
   */
  heading = undefined,
  /**
   * Heading above the standard heading.
   */
  supheading = undefined,
  /**
   * Heading below the standard heading.
   */
  subheading = undefined,
  withSpace = true,
  withPadding = false,
  headingTag = 'h1',
  headingVariant = 'h2',
  className = '',
  classNameHeader = '',
  style,
}) => (
  <div
    className={[className, styles.screen].join(' ')}
    style={style}
    data-with-space={withSpace}
    data-with-padding={withPadding}
  >
    <div className={[classNameHeader, styles.header].join(' ')}>
      {!!supheading && <Caption>{supheading}</Caption>}
      {!!heading && (
        <Heading tag={headingTag} variant={headingVariant}>
          {heading}
        </Heading>
      )}
      {!!subheading && <Caption>{subheading}</Caption>}
    </div>
    <div className={styles.content}>{children}</div>
  </div>
);
