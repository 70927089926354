import { fetchEndpoints } from '@quirion/api';
import { EndpointsDefinition, Payload } from '@quirion/api/types';
import { StorageKey } from '@quirion/utils';

/**
 * doInit - Does an init that sets required local storage items for api calls.
 * @param {String} env - environment to use. Either prod or dev. Sets the endpoints.
 */

export const doInit = (isProd: boolean, payload?: Payload) =>
  new Promise<EndpointsDefinition>((resolve, reject) => {
    fetchEndpoints(isProd, payload)
      .then((res) => {
        /**
         * @todo This is a fallback solution, that probably can be removed soon.
         */
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.quirionAppInit = res;

        localStorage.setItem(StorageKey.Endpoints, JSON.stringify(res));
        resolve(res);
      })
      .catch((err) => reject(err));
  });
