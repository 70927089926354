/**
 * isISOString - Checks if passed string is an ISO string (date).
 * @param {string} val any string
 * @returns {(boolean|string)}
 */

export function isISOString(val: string | undefined): boolean {
  const d = new Date(String(val));
  return !Number.isNaN(d.valueOf()) && d.toISOString() === val;
}
