import type { SVGProps } from 'react';
const SvgDots = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={1.5}
    className="dots_svg__icon dots_svg__icon-tabler dots_svg__icon-tabler-dots"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <circle cx={5} cy={12} r={1} />
    <circle cx={12} cy={12} r={1} />
    <circle cx={19} cy={12} r={1} />
  </svg>
);
export default SvgDots;
