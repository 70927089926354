import { ConditionCategory, ShareBondSplit, WphgMap } from '@quirion/types';

/** Map that contains information about how to map "new" unified session answers to
 * legacy session values.
 */
export const WPHG_MAP: WphgMap = {
  FINANCIAL_DATA__ASSETS__FREE_ASSETS: {
    key: 'wphg_liquid_assets',
    type: 'INPUT',
  },
  FINANCIAL_DATA__ASSETS__LOANS: {
    key: 'wphg_liabilities',
    type: 'INPUT',
  },
  FINANCIAL_DATA__ASSETS__TIED_ASSETS: {
    key: 'wphg_illiquid_assets',
    type: 'INPUT',
  },
  FINANCIAL_DATA__ASSETS__TOTAL_AVAILABLE_ASSETS: {
    key: 'wphg_total_assets',
    type: 'INPUT',
  },
  INVESTMENT_AMOUNT__MONTHLY_SAVINGS: {
    key: 'savingplan_rate',
    type: 'INPUT',
  },
  INVESTMENT_AMOUNT__ONE_TIME_DEPOSIT: {
    key: 'initial_amount',
    type: 'INPUT',
  },
  INVESTMENT_EXPERIENCE__GENERAL: {
    key: 'wphg_knowledge_self',
    type: 'SINGLE_SELECT',
    options: {
      PROFESSIONAL: {
        name: 5,
        calcValue: 1,
      },
      VERY_GOOD: {
        name: 4,
        calcValue: 0.8,
      },
      GOOD: {
        name: 3,
        calcValue: 0.6,
      },
      MEDIUM: {
        name: 2,
        calcValue: 0.4,
      },
      BAD: {
        name: 1,
        calcValue: 0.2,
      },
      NONE: {
        name: 0,
        calcValue: 0,
      },
    },
  },
  RISK_INVESTMENT_EXPERIENCE__GENERAL: {
    key: 'wphg_knowledge_self',
    type: 'SINGLE_SELECT',
    options: {
      PROFESSIONAL: {
        name: 5,
        calcValue: 1,
      },
      VERY_GOOD: {
        name: 4,
        calcValue: 0.8,
      },
      GOOD: {
        name: 3,
        calcValue: 0.6,
      },
      MEDIUM: {
        name: 2,
        calcValue: 0.4,
      },
      BAD: {
        name: 1,
        calcValue: 0.2,
      },
      NONE: {
        name: 0,
        calcValue: 0,
      },
    },
  },
  INVESTMENT_PERIOD__GENERAL: {
    key: 'wphg_holding_period',
    type: 'SINGLE_SELECT',
    options: {
      LESS_THAN_ONE: {
        name: 'very-short',
        calcValue: 3,
      },
      ONE_TO_THREE: {
        name: 'short',
        calcValue: 3,
      },
      FOUR_TO_FIVE: {
        name: 'medium',
        calcValue: 5,
      },
      MORE_OR_EQUAL_FIVE: {
        name: 'long',
        calcValue: 7,
      },
    },
  },
  FINANCIAL_DATA__MONTHLY_FINANCES__MONTHLY_EXPENSES: {
    key: 'wphg_expenses',
    type: 'INPUT',
  },
  FINANCIAL_DATA__MONTHLY_FINANCES__MONTHLY_NET_INCOME: {
    key: 'wphg_net_income',
    type: 'INPUT',
  },
  LOSS_MANAGEMENT__GENERAL: {
    key: 'wphg_drawdown_capacity',
    type: 'SINGLE_SELECT',
    options: {
      FIVE_PERCENT: {
        name: 0,
        calcValue: 0,
        calcWeight: 1,
      },
      TEN_PERCENT: {
        name: 1,
        calcValue: 0.3,
        calcWeight: 1,
      },
      TWENTYFIVE_PERCENT: {
        name: 2,
        calcValue: 0.5,
        calcWeight: 1,
      },
      FIFTY_PERCENT: {
        name: 3,
        calcValue: 0.8,
        calcWeight: 1,
      },
      ANY: {
        name: 4,
        calcValue: 1,
        calcWeight: 1,
      },
    },
  },
  RISK__GENERAL: {
    key: 'wphg_risk_preference',
    type: 'SINGLE_SELECT',
    options: {
      LOW: {
        name: 0.21,
        calcValue: 0.21,
      },
      AVERAGE: {
        name: 0.45,
        calcValue: 0.45,
      },
      MORE_THAN_AVERAGE: {
        name: 0.65,
        calcValue: 0.65,
      },
      VERY_HIGH: {
        name: 0.85,
        calcValue: 0.85,
      },
    },
  },
  META__CONDITION_CATEGORY: {
    key: 'wphg_sustainability',
    type: 'COMPUTED',
    map: [
      {
        source: ConditionCategory.AssetManagementSustainable,
        name: true,
      },
      {
        source: ConditionCategory.AssetManagement,
        name: false,
      },
      {
        source: ConditionCategory.AssetManagementOpinion,
        name: false,
      },
      {
        source: ConditionCategory.AssetManagementTopics,
        name: false,
      },
      {
        source: ConditionCategory.PensionPlan,
        name: false,
      },
    ],
  },
  META__SHARE_BOND_SPLIT: {
    key: 'user_choice_strategy',
    type: 'COMPUTED',
    map: [
      {
        /**
         * This value was added after the first implementation.
         * Since it can not be mapped correctly with `0`
         * already used for @type {ShareBondSplit.TenNinety},
         * `'unset'` is used instead.
         */
        source: ShareBondSplit.ZeroHundred,
        name: 'unset',
      },
      {
        source: ShareBondSplit.TenNinety,
        name: 0,
      },
      {
        source: ShareBondSplit.TwentyEighty,
        name: 1,
      },
      {
        source: ShareBondSplit.ThirtySeventy,
        name: 2,
      },
      {
        source: ShareBondSplit.FortySixty,
        name: 3,
      },
      {
        source: ShareBondSplit.FiftyFifty,
        name: 4,
      },
      {
        source: ShareBondSplit.SixtyForty,
        name: 5,
      },
      {
        source: ShareBondSplit.SeventyThirty,
        name: 6,
      },
      {
        source: ShareBondSplit.EightyTwenty,
        name: 7,
      },
      {
        source: ShareBondSplit.NinetyTen,
        name: 8,
      },
      {
        source: ShareBondSplit.HundredZero,
        name: 9,
      },
    ],
  },
};

/**
 * Maps the values for a given `wphg_holding_period` to an according label
 *
 * @example a `wphg_holding_period: 'medium'` maps to the label `'4-6 Jahre'`
 */
export const WPHG_HOLDING_PERIOD_TO_LABEL_MAP = {
  [WPHG_MAP.INVESTMENT_PERIOD__GENERAL.options.ONE_TO_THREE.name as string]:
    'bis 3 Jahre',
  [WPHG_MAP.INVESTMENT_PERIOD__GENERAL.options.FOUR_TO_FIVE.name as string]:
    '4-6 Jahre',
  [WPHG_MAP.INVESTMENT_PERIOD__GENERAL.options.MORE_OR_EQUAL_FIVE
    .name as string]: 'über 6 Jahre',
};

/**
 * Maps the values for a given `wphg_holding_period` to its according
 * calculation value.
 *
 * A `wphg_holding_period: 'medium'` internally maps to a calc value of `5`
 * (years). This calc value can be used if you need a definite number for some
 * calculations.
 */
export const WPHG_HOLDING_PERIOD_TO_CALC_VAL_MAP = {
  [WPHG_MAP.INVESTMENT_PERIOD__GENERAL.options.ONE_TO_THREE.name as string]:
    WPHG_MAP.INVESTMENT_PERIOD__GENERAL.options.ONE_TO_THREE.calcValue,
  [WPHG_MAP.INVESTMENT_PERIOD__GENERAL.options.FOUR_TO_FIVE.name as string]:
    WPHG_MAP.INVESTMENT_PERIOD__GENERAL.options.FOUR_TO_FIVE.calcValue,
  [WPHG_MAP.INVESTMENT_PERIOD__GENERAL.options.MORE_OR_EQUAL_FIVE
    .name as string]:
    WPHG_MAP.INVESTMENT_PERIOD__GENERAL.options.MORE_OR_EQUAL_FIVE.calcValue,
};
