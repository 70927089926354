import { lazy } from 'react';

import { SavingsPlanFormIntent } from 'pages/[userId]/setup/savingsplan/_types';

import { RestrictedAccess } from '../_components/RestrictedAccess';

// Routes: unauthenticated users
const Login = lazy(() => import('pages/authentication/login'));
const Logout = lazy(() => import('pages/authentication/logout'));
const Forgot = lazy(() => import('pages/authentication/forgot'));
const DownloadApp = lazy(() => import('pages/download/app'));

// Routes: authenticated users
const Secure = lazy(() => import('pages/authentication/login/secure'));
const Dashboard = lazy(() => import('pages/[userId]'));
const Academy = lazy(() => import('pages/[userId]/academy'));
const Market = lazy(() => import('pages/[userId]/market'));
const Orders = lazy(() => import('pages/[userId]/orders'));
const PostBox = lazy(() => import('pages/[userId]/postbox'));
const Timeline = lazy(() => import('pages/[userId]/timeline'));
const Setup = lazy(() => import('pages/[userId]/setup'));
const Tasks = lazy(() => import('pages/[userId]/tasks'));
const VoucherCard = lazy(() => import('pages/[userId]/voucher-card'));

// Routes: strategies & products
const IpsDetails = lazy(() => import('pages/[userId]/ips'));
const ClearingAccount = lazy(() => import('pages/[userId]/clearing-account'));
const DeleteIps = lazy(() => import('pages/[userId]/delete-ips'));
const StrategyCheck = lazy(() => import('pages/[userId]/strategy-check'));
const Withdrawal = lazy(() => import('pages/[userId]/withdrawal'));

// Routes: setup
const Profile = lazy(() => import('pages/[userId]/setup/profile'));
const SavingsPlanForm = lazy(() => import('pages/[userId]/setup/savingsplan'));
const SavingsPlanOverview = lazy(
  () => import('pages/[userId]/setup/savingsplans'),
);
const ServicePackages = lazy(
  () => import('pages/[userId]/setup/service-packages'),
);
const Coupons = lazy(() => import('pages/[userId]/setup/coupons'));
const Security = lazy(() => import('pages/[userId]/setup/security'));
const CloseBusinessPartnerOrAccount = lazy(
  () => import('pages/[userId]/setup/delete-account'),
);
const Notifications = lazy(() => import('pages/[userId]/setup/notifications'));
const WphgData = lazy(() => import('pages/[userId]/setup/wphg-data'));
const ReferenceAccount = lazy(
  () => import('pages/[userId]/setup/reference-account'),
);
const ExemptionOrder = lazy(
  () => import('pages/[userId]/setup/exemption-order'),
);
const ExemptionOrderForm = lazy(
  () => import('pages/[userId]/setup/exemption-order-form'),
);

const RegistrationStep1 = lazy(
  () => import('pages/[userId]/registration/step_1'),
);

const RegistrationStep2 = lazy(
  () => import('pages/[userId]/registration/step_2'),
);

const RegistrationStep3 = lazy(
  () => import('pages/[userId]/registration/step_3'),
);

const RegistrationStep4 = lazy(
  () => import('pages/[userId]/registration/step_4'),
);

export const AppRoutes = {
  authentication: {
    children: {
      signIn: {
        path: '/login',
        element: <Login />,
      },
      signOut: {
        path: '/logout',
        element: <Logout />,
        children: {
          inactivity: {
            path: '/logout/inactivity',
            element: <Logout />,
          },
        },
      },
      settings: {
        children: {
          mfa: {
            children: {
              setup: {
                path: '/login/secure',
                element: (
                  <RestrictedAccess>
                    <Secure />
                  </RestrictedAccess>
                ),
              },
            },
          },
          password: {
            children: {
              resetPassword: {
                path: '/forgot_password',
                element: <Forgot />,
              },
            },
          },
        },
      },
    },
  },
  downloadApp: {
    path: '/download/app/',
    element: <DownloadApp />,
  },
  addSingleStep1: {
    path: '/add/single/step_1/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep1 />
      </RestrictedAccess>
    ),
  },
  registrationSingleStep1: {
    path: '/registration/single/step_1/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep1 />
      </RestrictedAccess>
    ),
  },
  addJointStep1: {
    path: '/add/joint/step_1/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep1 />
      </RestrictedAccess>
    ),
  },
  registrationJointStep1: {
    path: '/registration/joint/step_1/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep1 />
      </RestrictedAccess>
    ),
  },
  addChildStep1: {
    path: '/add/child/step_1/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep1 />
      </RestrictedAccess>
    ),
  },
  registrationChildStep1: {
    path: '/registration/child/step_1/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep1 />
      </RestrictedAccess>
    ),
  },

  addSingleStep2: {
    path: '/add/single/step_2/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep2 />
      </RestrictedAccess>
    ),
  },
  registrationSingleStep2: {
    path: '/registration/single/step_2/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep2 />
      </RestrictedAccess>
    ),
  },
  addJointStep2: {
    path: '/add/joint/step_2/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep2 />
      </RestrictedAccess>
    ),
  },
  registrationJointStep2: {
    path: '/registration/joint/step_2/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep2 />
      </RestrictedAccess>
    ),
  },
  addChildStep2: {
    path: '/add/child/step_2/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep2 />
      </RestrictedAccess>
    ),
  },
  registrationChildStep2: {
    path: '/registration/child/step_2/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep2 />
      </RestrictedAccess>
    ),
  },

  addSingleStep3: {
    path: '/add/single/step_3/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep3 />
      </RestrictedAccess>
    ),
  },
  registrationSingleStep3: {
    path: '/registration/single/step_3/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep3 />
      </RestrictedAccess>
    ),
  },
  addJointStep3: {
    path: '/add/joint/step_3/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep3 />
      </RestrictedAccess>
    ),
  },
  registrationJointStep3: {
    path: '/registration/joint/step_3/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep3 />
      </RestrictedAccess>
    ),
  },
  addChildStep3: {
    path: '/add/child/step_3/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep3 />
      </RestrictedAccess>
    ),
  },
  registrationChildStep3: {
    path: '/registration/child/step_3/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep3 />
      </RestrictedAccess>
    ),
  },

  addSingleStep4: {
    path: '/add/single/step_4/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep4 />
      </RestrictedAccess>
    ),
  },
  registrationSingleStep4: {
    path: '/registration/single/step_4/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep4 />
      </RestrictedAccess>
    ),
  },
  addJointStep4: {
    path: '/add/joint/step_4/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep4 />
      </RestrictedAccess>
    ),
  },
  registrationJointStep4: {
    path: '/registration/joint/step_4/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep4 />
      </RestrictedAccess>
    ),
  },
  addChildStep4: {
    path: '/add/child/step_4/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep4 />
      </RestrictedAccess>
    ),
  },
  registrationChildStep4: {
    path: '/registration/child/step_4/:id',
    element: (
      <RestrictedAccess>
        <RegistrationStep4 />
      </RestrictedAccess>
    ),
  },
  withdrawal: {
    path: '/withdrawal/:id',
    element: (
      <RestrictedAccess>
        <Withdrawal />
      </RestrictedAccess>
    ),
  },
  strategyCheck: {
    path: '/strategy-check/:businessPartnerId',
    element: (
      <RestrictedAccess>
        <StrategyCheck />
      </RestrictedAccess>
    ),
  },
  postBox: {
    path: '/postbox/',
    element: (
      <RestrictedAccess>
        <PostBox />
      </RestrictedAccess>
    ),
  },
  timeline: {
    path: '/timeline/',
    element: (
      <RestrictedAccess>
        <Timeline />
      </RestrictedAccess>
    ),
  },
  clearingAccount: {
    path: '/clearing-account/:id',
    element: (
      <RestrictedAccess>
        <ClearingAccount />
      </RestrictedAccess>
    ),
  },
  orders: {
    path: '/orders/',
    element: (
      <RestrictedAccess>
        <Orders />
      </RestrictedAccess>
    ),
  },
  voucherCard: {
    path: '/voucher-card/',
    element: (
      <RestrictedAccess>
        <VoucherCard />
      </RestrictedAccess>
    ),
  },
  academy: {
    path: '/academy',
    element: (
      <RestrictedAccess>
        <Academy />
      </RestrictedAccess>
    ),
  },
  market: {
    path: '/market',
    element: (
      <RestrictedAccess>
        <Market />
      </RestrictedAccess>
    ),
  },
  tasks: {
    path: '/tasks',
    element: (
      <RestrictedAccess>
        <Tasks />
      </RestrictedAccess>
    ),
  },
  setup: {
    path: '/setup',
    element: (
      <RestrictedAccess>
        <Setup />
      </RestrictedAccess>
    ),
    hasOutlet: true,
    children: {
      profile: {
        path: '/setup/profile/:externalId?/:update?',
        element: <Profile />,
      },
      savingsPlanEdit: {
        path: '/setup/savingsplan/update/:id?',
        element: <SavingsPlanForm formIntent={SavingsPlanFormIntent.update} />,
      },
      savingsPlanNew: {
        path: '/setup/savingsplan/create/:productid?',
        element: <SavingsPlanForm formIntent={SavingsPlanFormIntent.create} />,
      },
      savingsPlanOverview: {
        path: '/setup/savingsplans',
        element: <SavingsPlanOverview />,
      },
      servicePackages: {
        path: '/setup/service-packages',
        element: <ServicePackages />,
      },
      coupons: {
        path: '/setup/coupons',
        element: <Coupons />,
      },
      security: {
        path: '/setup/security',
        element: <Security />,
      },
      deleteAccount: {
        path: '/setup/delete-account',
        element: <CloseBusinessPartnerOrAccount />,
      },
      marketingConsent: {
        path: '/setup/notifications',
        element: <Notifications />,
      },
      wphgData: {
        path: '/setup/wphg-data/:bpId?',
        element: <WphgData />,
      },
      referenceAccount: {
        path: '/setup/reference-account',
        element: <ReferenceAccount />,
      },
      exemptionOrder: {
        path: '/setup/exemption-order',
        element: <ExemptionOrder />,
      },
      exemptionOrderForm: {
        path: '/setup/exemption-order-form',
        element: <ExemptionOrderForm />,
      },
    },
  },
  deleteIps: {
    path: '/delete-ips/:id',
    element: (
      <RestrictedAccess>
        <DeleteIps />
      </RestrictedAccess>
    ),
  },
  ipsDetails: {
    path: '/ips/:ipsId?',
    element: (
      <RestrictedAccess>
        <IpsDetails />
      </RestrictedAccess>
    ),
  },
  dashboard: {
    path: '/',
    element: (
      <RestrictedAccess>
        <Dashboard />
      </RestrictedAccess>
    ),
  },
};
