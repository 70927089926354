import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgInvestmentCloudComputingGradient = function (
  props: SVGProps<SVGSVGElement>,
) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill={`url(#${_id})`}
        d="M25.66 28.5h-.62c-.99 0-1.79-.8-1.79-1.79v-.62c0-.99.8-1.79 1.79-1.79h.62c.99 0 1.79.8 1.79 1.79v.62c0 .99-.8 1.79-1.79 1.79m-.62-3.2c-.43 0-.79.35-.79.79v.62c0 .43.35.79.79.79h.62c.44 0 .79-.35.79-.79v-.62c0-.43-.35-.79-.79-.79zm-4.46 3.2h-.62c-.99 0-1.79-.8-1.79-1.79v-.62c0-.99.8-1.79 1.79-1.79h.62c.99 0 1.79.8 1.79 1.79v.62c0 .99-.8 1.79-1.79 1.79m-.62-3.2c-.43 0-.79.35-.79.79v.62c0 .43.35.79.79.79h.62c.43 0 .79-.35.79-.79v-.62c0-.43-.35-.79-.79-.79zm-7.94 3.2h-.62c-.99 0-1.79-.8-1.79-1.79v-.62c0-.99.8-1.79 1.79-1.79h.62c.99 0 1.79.8 1.79 1.79v.62c0 .99-.8 1.79-1.79 1.79m-.62-3.2c-.43 0-.79.35-.79.79v.62c0 .43.35.79.79.79h.62c.43 0 .79-.35.79-.79v-.62c0-.43-.35-.79-.79-.79zm-4.46 3.2h-.62c-.99 0-1.79-.8-1.79-1.79v-.62c0-.99.8-1.79 1.79-1.79h.62c.99 0 1.79.8 1.79 1.79v.62c0 .99-.8 1.79-1.79 1.79m-.62-3.2c-.44 0-.79.35-.79.79v.62c0 .43.35.79.79.79h.62c.43 0 .79-.35.79-.79v-.62c0-.43-.35-.79-.79-.79zm19.51-1.95h-1V21.7c0-.6-.49-1.09-1.09-1.09h-3.21c.14.27.23.57.23.9v1.85h-1v-1.85c0-.5-.4-.9-.9-.9h-1.29c-.64 0-1.21-.29-1.59-.74-.38.45-.96.74-1.59.74H13.1c-.5 0-.9.4-.9.9v1.85h-1v-1.85c0-.32.08-.63.23-.9H8.22c-.6 0-1.09.49-1.09 1.09v1.65h-1V21.7c0-1.15.94-2.09 2.09-2.09h6.17c.6 0 1.09-.49 1.09-1.09v-1.85h1v1.85c0 .6.49 1.09 1.09 1.09h6.17c1.15 0 2.09.94 2.09 2.09zm-5.19-7.54h-10.3c-2 0-3.62-1.62-3.62-3.62 0-1.4.83-2.69 2.09-3.28.19-1.2 1.18-2.05 2.37-2.05h.22c.73-2 2.66-3.38 4.81-3.38s3.91 1.24 4.71 3.11c2.46.17 4.36 2.17 4.36 4.6 0 1.63-.87 3.15-2.28 3.98-.7.42-1.51.63-2.34.63Zm-9.46-7.94a1.4 1.4 0 0 0-1.4 1.35v.33l-.33.19c-1.06.41-1.73 1.39-1.73 2.46 0 1.44 1.18 2.62 2.62 2.62h10.32c.65 0 1.28-.17 1.83-.5a3.63 3.63 0 0 0 1.78-3.12c0-1.99-1.62-3.61-3.62-3.61h-.31l-.27-.4a4.14 4.14 0 0 0-3.87-2.7c-1.83 0-3.46 1.23-3.96 2.98l-.16.57-.58-.15c-.1-.02-.21-.04-.34-.04Z"
      />
      <defs>
        <linearGradient
          id={_id}
          x1="0%"
          x2="50%"
          y1="50%"
          y2="0%"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939D4" />
          <stop offset={0.48} stopColor="#5D4DF8" />
          <stop offset={0.74} stopColor="#B459F9" />
          <stop offset={1} stopColor="#FE3B68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgInvestmentCloudComputingGradient;
