import React, { useContext, useMemo, useReducer } from 'react';
import type { FC, PropsWithChildren } from 'react';

import { StorageKey } from '@quirion/utils';

import { SidebarActions } from './CollapsibleSidebar.type';
import { SidebarContext } from './CollapsibleSidebarContext';
import { SidebarContainer } from './SubComponents/CollapsibleSidebarContainer';

import type {
  SidebarActionType,
  SidebarConfig,
  SidebarState,
} from './CollapsibleSidebar.type';

const initialState: SidebarState = {
  sidebars: [],
};

export const generateDummyUUID = () => `sidebar-${Date.now()}`;

export const defaultSidebarConfig: SidebarConfig = {
  title: undefined,
  content: undefined,
  footer: undefined,
  hasCloseButton: true,
  onClose: undefined,
  afterClose: undefined,
  afterOpened: undefined,
  useOverlay: false,
  variant: 'right',
};

/** Reducer */
const reducer = (state: SidebarState, action: SidebarActionType) => {
  switch (action.type) {
    case SidebarActions.OPEN_SIDEBAR: {
      return {
        sidebars: [...state.sidebars, action.payload],
      };
    }
    case SidebarActions.CLOSE_SIDEBAR: {
      const sidebars = state.sidebars.map((sb) => {
        if (sb.id === action.payload) {
          return {
            ...sb,
            isVisible: false,
          };
        }
        return sb;
      });
      return {
        sidebars,
      };
    }
    case SidebarActions.REMOVE_SIDEBAR: {
      const sidebars = state.sidebars.filter((sb) => sb.id !== action.payload);
      return {
        sidebars,
      };
    }
    case SidebarActions.CLOSE_ALL_SIDEBARS:
      return {
        sidebars: [],
      };
    default:
      throw new Error(`Reducer does not have action "${action}"`);
  }
};

export const SidebarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const memoizedSidebarContext = useMemo(() => {
    const openSidebar = (config?: SidebarConfig) => {
      const id = generateDummyUUID();
      dispatch({
        type: SidebarActions.OPEN_SIDEBAR,
        payload: {
          ...defaultSidebarConfig,
          ...config,
          isVisible: true,
          id,
        },
      });
      return id;
    };
    const closeSidebar = (id: string) => {
      dispatch({ type: SidebarActions.CLOSE_SIDEBAR, payload: id });
    };
    const removeSidebar = (id: string) => {
      dispatch({ type: SidebarActions.REMOVE_SIDEBAR, payload: id });
    };
    const closeAllSidebars = () => {
      dispatch({ type: SidebarActions.CLOSE_ALL_SIDEBARS });
    };
    const hasSidebar = (id: string) =>
      Boolean(state.sidebars.find((sb) => sb.id === id));

    return {
      sidebars: state.sidebars,
      openSidebar,
      removeSidebar,
      closeSidebar,
      hasSidebar,
      closeAllSidebars,
    };
  }, [state.sidebars, dispatch]);

  return (
    <SidebarContext.Provider value={memoizedSidebarContext}>
      {children}
      <SidebarContainer />
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => {
  const sidebarContext = useContext(SidebarContext);
  if (sidebarContext === undefined) {
    throw new Error(
      'useSidebarContext must be used inside a SidebarContext.Provider or SidebarProvider',
    );
  }
  if (!document.getElementById('sidebar-root')) {
    const sidebarRoot = document.createElement('div');
    sidebarRoot.setAttribute('id', 'sidebar-root');
    // Add direction to modal root to ensure it has proper padding;
    const direction = document.documentElement.dir || 'ltr';
    sidebarRoot.setAttribute('dir', direction);
    sidebarRoot.setAttribute('style', 'isolation: isolate;');
    document.body.append(sidebarRoot);
  } else {
    /**
     * Remove possible leftover `modal-root` element to prevent z-index issues * when another modal is opened from a sidebar.
     * @see https://quirion.atlassian.net/browse/AUF-14941
     */
    const sidebarRoot = document.getElementById('sidebar-root');
    const modalRoot = sidebarRoot?.querySelector('#modal-root');
    if (!modalRoot?.childNodes?.length) modalRoot?.remove();
  }
  return sidebarContext;
};

export const useSidebarToggle = () => {
  const { openSidebar, closeSidebar, hasSidebar } = useSidebarContext();
  const sidebarId = sessionStorage.getItem(StorageKey.SidebarId) ?? '';
  const toggle = (config: SidebarConfig) => {
    if (hasSidebar?.(sidebarId)) {
      closeSidebar?.(sidebarId);
      sessionStorage.setItem(StorageKey.SidebarId, '');
    } else {
      const id = openSidebar?.(config);
      if (id) {
        sessionStorage.setItem(StorageKey.SidebarId, id);
      }
    }
  };

  const close = () => {
    if (hasSidebar?.(sidebarId)) {
      closeSidebar?.(sidebarId);
      sessionStorage.setItem(StorageKey.SidebarId, '');
    }
  };

  return {
    toggleSidebar: toggle,
    closeSidebar: close,
  };
};
