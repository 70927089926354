import { TransactionStatus } from '@quirion/types';
import { APICallInputWithPayload, TransactionTypeParam } from '../types';
import { extendedFetch } from '../utils/extendedFetch';
import { getEndpointsDefinition } from '../utils/getEndpointsDefinition';
import {
  ReadBusinessPartnerTransactionsPayload,
  ReadBusinessPartnerTransactionsResponse,
} from './readBusinessPartnerTransactions.types';

const PAYLOAD_DEFAULTS = {
  range: 30,
  status: TransactionStatus.All,
  type: TransactionTypeParam.All,
};

/**
 * @see https://quirion.atlassian.net/browse/QUIK-3329
 */

export const readBusinessPartnerTransactions = ({
  endpointsDefinition = getEndpointsDefinition(),
  payload,
}: APICallInputWithPayload<ReadBusinessPartnerTransactionsPayload>): Promise<ReadBusinessPartnerTransactionsResponse> =>
  extendedFetch({
    endpointConfig: endpointsDefinition.endpoints.bpTransactions,
    payload: {
      ...PAYLOAD_DEFAULTS,
      ...payload,
    },
  }).then((res) => res.json());
