import { DateIsoString } from '../Date.types';

import { TaskBase, TaskType } from './Task';

// @todo: align to data structure from api, when wphg tasks are ready
export type TaskWpHG = TaskBase & {
  taskType: TaskType.WpHG;
  metaData?: {
    businessPartnerId?: number;
    wphgExpiresAt?: DateIsoString;
  };
};

export const isTaskWpHG = (task: TaskBase): task is TaskWpHG =>
  task.taskType === TaskType.WpHG;
