import type { ISIN } from '../IPS';

/**
 * Internal strategy. Includes and estimations for profit and other metrics.
 */
export type Strategy = {
  asset: number;
  averageTer: number;
  isins?: ISIN[];
  lost: number;
  profit: number;
  relax: number;
  risk: number;
  vola: number;
  yield: number;
};

export enum StrategySpardepot {
  Spardepot = 'spardepot',
}

export enum ShareBondSplit {
  ZeroHundred = '0:100',
  TenNinety = '10:90',
  TwentyEighty = '20:80',
  ThirtySeventy = '30:70',
  FortySixty = '40:60',
  FiftyFifty = '50:50',
  SixtyForty = '60:40',
  SeventyThirty = '70:30',
  EightyTwenty = '80:20',
  NinetyTen = '90:10',
  HundredZero = '100:0',
}

export enum StrategyApproach {
  Moderat = 'moderat',
  Defensiv = 'defensiv',
  Offensive = 'offensiv',
}

/**
 * Strategy ID / name. Used to indicate used strategy.
 */
export type StrategyName =
  | ShareBondSplit.ZeroHundred
  | ShareBondSplit.TenNinety
  | ShareBondSplit.TwentyEighty
  | ShareBondSplit.ThirtySeventy
  | ShareBondSplit.FortySixty
  | ShareBondSplit.FiftyFifty
  | ShareBondSplit.SixtyForty
  | ShareBondSplit.SeventyThirty
  | ShareBondSplit.EightyTwenty
  | ShareBondSplit.NinetyTen
  | ShareBondSplit.HundredZero
  | StrategyApproach.Moderat
  | StrategyApproach.Defensiv
  | StrategyApproach.Offensive
  /** @deprecated */
  | StrategySpardepot.Spardepot;
