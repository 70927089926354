import React, {
  FC,
  NamedExoticComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  SvgChevronDown,
  SvgCircleCheck,
  SvgCircleX,
  SvgInfoCircle,
} from '@quirion/assets';
import { isRenderable } from '@quirion/utils';

import { Button } from '../../Atoms/Button';
import { Icon } from '../../Atoms/Icon';
import { StackTemplates } from '../../Errors/types';
import {
  DEFAULT_AUTO_HIDE_DURATION,
  DEFAULT_BANNER_TYPE,
  DEFAULT_DISABLE_CLOSE,
  DEFAULT_ERROR_DESCRIPTION,
  DEFAULT_ERROR_TITLE,
} from '../constants';
import {
  BannerContentStack,
  BannerProps,
  BannerVariant,
  BannerVariantMap,
} from '../types/Banner.types';

import { NetworkErrorContent, StackTemplate } from './StackTemplates';

import styles from './SingleBanner.module.css';

const typeMap: BannerVariantMap = {
  info: (
    <Icon
      className={styles.icon}
      name={SvgInfoCircle}
      size="md"
      color="var(--colors-light-3)"
    />
  ),
  error: (
    <Icon
      className={styles.icon}
      name={SvgCircleX}
      size="md"
      color="var(--colors-light-3)"
    />
  ),
  success: (
    <Icon
      className={styles.icon}
      name={SvgCircleCheck}
      size="md"
      color="var(--colors-light-3)"
    />
  ),
};

type BannerErrorDetailsProps = BannerContentStack & {
  // type: BannerType;
  isExpanded: boolean;
};

const BannerErrorDetails: FC<BannerErrorDetailsProps> = ({
  stack,
  isExpanded,
}) => {
  const stackText = JSON.stringify(stack) ?? 'No Stack defined';

  const copy = () => {
    navigator.clipboard.writeText(stackText);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.expandingContainer} data-is-open={isExpanded}>
        <div className={styles.errorWrapper}>
          <div>
            {(() => {
              if (typeof stack === 'string') {
                return <div className={styles.contained}>{stack}</div>;
              }
              switch (stack?.templateName) {
                case StackTemplates.NetworkError:
                  return (
                    <StackTemplate.NetworkError
                      templateName={stack.templateName}
                      content={stack.content as NetworkErrorContent}
                    />
                  );
                case StackTemplates.CustomError:
                  return (
                    <StackTemplate.CustomError
                      templateName={stack.templateName}
                      content={stack.content as ReactNode}
                    />
                  );
                default:
                  return <div className={styles.contained}>{stackText}</div>;
              }
            })()}
          </div>
          <div className={styles.copyButtonContainer}>
            <Button
              onClick={() => copy()}
              size="sm"
              variant="text-inverted"
              className={[styles.lightButton, styles.button].join(' ')}
            >
              <span>Kopieren</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

type SingleBannerProps = BannerProps & {
  /*
   * Pass this function to add a close button
   */
  hide?: (id: string) => void;
};

export const SingleBanner: NamedExoticComponent<SingleBannerProps> = React.memo(
  ({
    variant = DEFAULT_BANNER_TYPE,
    title = DEFAULT_ERROR_TITLE,
    description,
    stack,
    id,
    hide = undefined,
    animationDuration = 300,
    autoHide = DEFAULT_AUTO_HIDE_DURATION,
    disableClose = DEFAULT_DISABLE_CLOSE,
  }: SingleBannerProps) => {
    const [isAnimatingOut, setAnimatingOut] = useState(false);
    const [errorIsOpen, setErrorIsOpen] = useState(false);

    const toggleErrorOpen = () => setErrorIsOpen(!errorIsOpen);

    const animatedHide = useCallback(() => {
      // handle no window. Only ever useful for build step or testing
      if ((!window || !window?.matchMedia) && hide && id) {
        hide(id);
        return;
      }
      //  skip animation, when either duration is 0 or user prefers reduced motion
      if (
        (!animationDuration ||
          window?.matchMedia('(prefers-reduced-motion: reduce)')?.matches) &&
        hide &&
        id
      ) {
        hide(id);
        return;
      }

      setAnimatingOut(true);
      setTimeout(() => {
        if (id) hide?.(id);
      }, animationDuration);
    }, [id, animationDuration, hide]);

    // Add a timeout for hiding the banner
    useEffect(() => {
      let timeout: ReturnType<typeof setTimeout>;
      if (autoHide) {
        timeout = setTimeout(() => {
          animatedHide();
        }, autoHide);
      }

      return () => {
        if (timeout) {
          return clearTimeout(timeout);
        }
        return undefined;
      };
    }, [autoHide, animatedHide]);

    return (
      <output
        className={[
          styles.banner,
          isAnimatingOut ? styles.animatingOut : '',
        ].join(' ')}
        style={{
          '--animation-duration': `${animationDuration ?? 0}ms`,
        }}
      >
        <div className={styles.body}>
          {(variant && typeMap?.[variant]) || typeMap.info}
          <div className={styles.headline}>
            {isRenderable(title) ? title : DEFAULT_ERROR_TITLE}
          </div>
        </div>
        {(description || (!description && variant === BannerVariant.Error)) && (
          <p className={styles.message}>
            {description ?? DEFAULT_ERROR_DESCRIPTION}
          </p>
        )}
        {stack && <BannerErrorDetails stack={stack} isExpanded={errorIsOpen} />}
        {/*  If hide is passed, show footer with close button */}
        {hide && !disableClose ? (
          <div className={styles.footer}>
            {stack && (
              <Button
                size="sm"
                variant="text-inverted"
                className={[styles.detailsButton, styles.button].join(' ')}
                onClick={toggleErrorOpen}
              >
                Details{' '}
                <Icon
                  name={SvgChevronDown}
                  size="inline"
                  className={[
                    styles.triggerIcon,
                    errorIsOpen ? styles.isOpen : '',
                  ].join(' ')}
                  aria-hidden
                />
              </Button>
            )}
            {variant === BannerVariant.Error && (
              <Button
                as="a"
                href="https://www.quirion.de/kontakt"
                target="_blank"
                size="sm"
                variant="secondary"
                className={[styles.button, styles.support].join(' ')}
              >
                Support
              </Button>
            )}
            <Button
              size="sm"
              variant="secondary"
              onClick={() => animatedHide()}
              className={[styles.button, styles.close].join(' ')}
            >
              Schließen
            </Button>
          </div>
        ) : null}
      </output>
    );
  },
);
