import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgInvestmentHalbleiterGradient = function (
  props: SVGProps<SVGSVGElement>,
) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill={`url(#${_id})`}
        d="M24.46 27.5h-.96v-1.96h.96zm-2.66 0h-.96v-1.96h.96zm-2.66 0h-.96v-1.96h.96zm-2.66 0h-.96v-1.96h.96zm-2.66 0h-.96v-1.96h.96zm-2.66 0h-.96v-1.96h.96zm-2.66 0h-.96v-1.96h.96zm19-3.04h-1.96v-.96h1.96zm-3.04 0H7.55V7.54h16.91zM8.51 23.5H23.5v-15h-15v14.99Zm-2.05.96H4.5v-.96h1.96zm14.22-2.52h-2.37c-.33 0-.65-.13-.88-.37l-.41-.41a.29.29 0 0 0-.21-.09h-1.62q-.12 0-.21.09l-.41.41c-.24.24-.55.37-.88.37h-2.37c-.69 0-1.25-.56-1.25-1.25v-9.38c0-.69.56-1.25 1.25-1.25h9.36c.69 0 1.25.56 1.25 1.25v9.38c0 .69-.56 1.25-1.25 1.25m-5.49-1.82h1.62c.33 0 .65.13.88.37l.41.41c.05.05.13.09.21.09h2.37c.16 0 .29-.13.29-.29v-9.39a.29.29 0 0 0-.29-.29h-9.36a.29.29 0 0 0-.29.29v9.38c0 .16.13.29.29.29h2.37q.12 0 .21-.09l.41-.41c.24-.24.55-.37.88-.37Zm12.31 1.67h-1.96v-.96h1.96zm-21.04 0H4.5v-.96h1.96zm21.04-2.66h-1.96v-.96h1.96zm-21.04 0H4.5v-.96h1.96zm21.04-2.66h-1.96v-.96h1.96zm-21.04 0H4.5v-.96h1.96zm21.04-2.66h-1.96v-.96h1.96zm-21.04 0H4.5v-.96h1.96zm21.04-2.66h-1.96v-.96h1.96zm-21.04 0H4.5v-.96h1.96zM27.5 8.49h-1.96v-.96h1.96zm-21.04 0H4.5v-.96h1.96zm18-2.05h-.96V4.48h.96zm-2.66 0h-.96V4.48h.96zm-2.66 0h-.96V4.48h.96zm-2.66 0h-.96V4.48h.96zm-2.66 0h-.96V4.48h.96zm-2.66 0h-.96V4.48h.96zm-2.66 0h-.96V4.48h.96z"
      />
      <defs>
        <linearGradient
          id={_id}
          x1="0%"
          x2="50%"
          y1="50%"
          y2="0%"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939D4" />
          <stop offset={0.48} stopColor="#5D4DF8" />
          <stop offset={0.74} stopColor="#B459F9" />
          <stop offset={1} stopColor="#FE3B68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgInvestmentHalbleiterGradient;
