import { PricePackage, SessionAnswers } from '@quirion/types';

export const isExistingPrivate = (
  sessionAnswers: SessionAnswers | undefined,
): boolean => {
  if (!sessionAnswers) return false;
  return (
    'META__BUSINESS_PARTNER_ID' in sessionAnswers &&
    sessionAnswers.META__PRICE_PACKAGE === PricePackage.Private
  );
};
