import type { SVGProps } from 'react';
const SvgX = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={1.5}
    className="x_svg__icon x_svg__icon-tabler x_svg__icon-tabler-x"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="M18 6 6 18M6 6l12 12" />
  </svg>
);
export default SvgX;
