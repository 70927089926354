/**
 * Checks if a string is a valid JSON string
 * @param str - The string to check
 */
export const isJSON = (str: string): boolean => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};
