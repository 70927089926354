import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';

import { SvgDots, SvgX } from '@quirion/assets';

import { Button } from '../Atoms/Button';
import { Icon } from '../Atoms/Icon';
import { Stack } from '../Container';
import { Divider } from '../Divider';
import { Logo, LogoVariant } from '../Logo';
import { NavItem, NavItemOwnProps } from '../NavItem';
import {
  OverflowItem,
  OverflowMoreNavItem,
  OverflowProvider,
  useOverflowContext,
} from '../OverflowMenu';
import { useHidingElementWithAnimation, useKeyListener } from '../utils';

import styles from './Navigation.module.css';

export type NavBarItemPropsForOverflow =
  React.ComponentPropsWithoutRef<'button'> &
    NavItemOwnProps & { itemKey: string; overflowStyle?: CSSProperties };

export type NavigationProps = React.ComponentPropsWithoutRef<'div'> & {
  /**
   * Add a style to the container holding the nav items.
   */
  innerContainerStyle?: CSSProperties;
  /**
   * Add a class to the container holding the nav items.
   */
  innerContainerClassName?: string;
  /**
   * Navigation item props. Each element will be a NavItem
   */
  navItems: NavBarItemPropsForOverflow[];
  /**
   * If you pass a logout function, a logout button will be rendered.
   */
  logout?: () => void;
  /**
   * Add a style to the logout button.
   */
  logoutStyle?: CSSProperties;
  /**
   * Add a class to the logout button.
   */
  logoutClassName?: string;
  /**
   * Add a suffix text to the logo
   */
  logoSuffix?: string;
  /**
   * Add a function for clicking the logo.
   */
  onLogoClick?: () => void;
  /**
   * Is only applied if `onLogoClick` is not passed
   */
  logoProps?: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    as: React.ElementType<any>;
    to?: string;
  };
  logoVariant?: LogoVariant;
};

export const NavigationContainer: FC<NavigationProps> = ({
  innerContainerClassName = '',
  innerContainerStyle,
  navItems,
  logout,
  logoutClassName = '',
  logoutStyle,
  onLogoClick,
  logoProps = {
    as: 'div',
  },
  logoVariant = 'vertical',
  children,
  logoSuffix,
  ...rest
}) => {
  const { as: LogoComponent } = logoProps;
  const { ref, overflowingItems, setOrientation, forceUpdate } =
    useOverflowContext();
  const overflowMenuRef = useRef<HTMLDivElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentItems, setCurrentItems] = React.useState<
    NavBarItemPropsForOverflow[]
  >([]);
  useEffect(() => {
    if (currentItems.length !== navItems.length) {
      setCurrentItems(navItems);
      forceUpdate?.();
    }
  }, [navItems, forceUpdate, currentItems.length]);

  useKeyListener('Escape', () => setMenuOpen(false));
  useHidingElementWithAnimation(overflowMenuRef.current, menuOpen);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(min-width: 1024px)');
    const handleOrientation = (mq: MediaQueryListEvent | MediaQueryList) => {
      setOrientation?.(mq.matches ? 'vertical' : 'horizontal');
    };
    if (mediaQuery) {
      mediaQuery.addEventListener('change', handleOrientation);
      handleOrientation(mediaQuery);
    }
    return () => {
      mediaQuery?.removeEventListener('change', handleOrientation);
    };
  }, [setOrientation]);

  /** Close menu when no more overflowing items remain */
  useEffect(() => {
    if (overflowingItems.length === 0) {
      setMenuOpen(false);
    }
  }, [overflowingItems.length]);

  return (
    <div className={styles.outer} {...rest}>
      <nav className={styles.container}>
        {onLogoClick ? (
          <button type="button" onClick={onLogoClick} className={styles.logo}>
            <Logo variant={logoVariant} inlineSize="5rem" blockSize="3.5rem" />
            {!!logoSuffix && (
              <span className={styles.logoSuffix}>{logoSuffix}</span>
            )}
          </button>
        ) : (
          <LogoComponent className={styles.logo} to={logoProps?.to}>
            <Logo variant={logoVariant} inlineSize="5rem" blockSize="3.5rem" />
            {!!logoSuffix && (
              <span className={styles.logoSuffix}>{logoSuffix}</span>
            )}
          </LogoComponent>
        )}
        <div
          ref={ref}
          className={[styles.inner, innerContainerClassName].join(' ')}
          style={innerContainerStyle}
        >
          {navItems.map(
            ({
              itemKey,
              label,
              icon,
              active,
              onClick,
              overflowStyle,
              ...itemRest
            }) => (
              <OverflowItem
                itemKey={itemKey}
                className={styles.navItem}
                key={itemKey}
                overflowStyle={overflowStyle ?? {}}
              >
                <NavItem
                  label={label}
                  icon={icon}
                  active={active}
                  onClick={() => {
                    setMenuOpen(false);
                    onClick?.();
                  }}
                  {...itemRest}
                />
              </OverflowItem>
            ),
          )}
          {logout ? (
            <OverflowItem
              itemKey="LogOut"
              data-logout-visible={overflowingItems.length === 0}
              className={[
                styles.navItem,
                styles.logoutWrap,
                logoutClassName,
              ].join(' ')}
              overflowStyle={logoutStyle}
            >
              <Button
                type="button"
                variant="outlined"
                onClick={() => {
                  setMenuOpen(false);
                  logout();
                }}
                className={styles.logout}
              >
                Abmelden
              </Button>
            </OverflowItem>
          ) : null}

          <OverflowMoreNavItem
            onClick={() => setMenuOpen((isOpen) => !isOpen)}
            isVisible={overflowingItems.length > 0}
            overflowClass={[styles.navItem].join(' ')}
            aria-expanded={menuOpen}
            label="Mehr"
            icon={SvgDots}
          />
        </div>
      </nav>
      <div
        className={styles.overflowContainer}
        ref={overflowMenuRef}
        data-is-active={menuOpen}
        role="dialog"
      >
        <div className={styles.overflowContent}>
          <Stack gap="var(--space-6)">
            {navItems
              .filter((item) => overflowingItems.includes(item.itemKey))
              .map(
                ({
                  itemKey,
                  label,
                  icon,
                  active,
                  onClick,
                  overflowStyle,
                  ...itemRest
                }) => (
                  <NavItem
                    label={label}
                    icon={icon}
                    active={active}
                    key={`overflow-${label}`}
                    onClick={() => {
                      setMenuOpen(false);
                      onClick?.();
                    }}
                    horizontal
                    {...itemRest}
                  />
                ),
              )}
          </Stack>
          {logout ? (
            <div style={{ marginTop: 'auto' }}>
              <Divider />

              <Button
                type="button"
                variant="outlined"
                onClick={() => {
                  setMenuOpen(false);
                  logout();
                }}
                className={styles.logout}
              >
                Abmelden
              </Button>
            </div>
          ) : null}
        </div>
        <Button
          onClick={() => setMenuOpen(false)}
          variant="light"
          size="sm"
          aria-label="Menü schließen"
          className={styles.close}
        >
          <Icon name={SvgX} size="inline" />
        </Button>
      </div>
    </div>
  );
};

export const Navigation: FC<NavigationProps> = ({
  navItems,
  logout,
  children,
  ...rest
}) => (
  <OverflowProvider>
    <NavigationContainer navItems={navItems} logout={logout} {...rest} />
  </OverflowProvider>
);
