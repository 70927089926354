import { useEffect, useState } from 'react';

/**
 * Debounces a given value with a given delay.
 * This is especially useful for search fields.
 * @param {unknown} value The value to be debounced
 * @param {number} delay The delay of the debounce in miliseconds
 * @returns The debounced value
 *
 * @see https://www.30secondsofcode.org/react/s/use-debounce/
 */
export const useDebounce = <T extends string | number | object>(
  value: T,
  delay?: number,
): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay ?? 500);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
