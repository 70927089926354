import type { FC } from 'react';

import { useSelector } from 'react-redux';

import type { User } from '@quirion/types';
import { StorageKey } from '@quirion/utils';

import { isProd } from 'lib/env';

import styles from './AppStatusIndicators.module.css';

export const AppStatusIndicators: FC = () => {
  const user: User = useSelector(
    (state: { user: User }) => state.user,
    (oldVal: User, newVal: User) =>
      Object.keys(oldVal).length === Object.keys(newVal).length,
  );

  const mode = sessionStorage.getItem(StorageKey.BankingMode);

  const isProdMode = isProd();

  return (
    <>
      {!isProdMode && (
        <div
          className={styles.env}
          // onClick={() => alert(sessionStorage.getItem(StorageKey.AppToWeb))}
        >
          <span>{import.meta.env.VITE_APP_BRANCH || 'LOCAL'}</span>
        </div>
      )}
      {!!mode && (
        <div className={styles.mode}>
          <span>
            {!!user?.username && `${user.username} – `}
            {mode.toUpperCase()} MODE
          </span>
        </div>
      )}
    </>
  );
};
