import { AuthParams, ParsedAuth } from '@quirion/types';

import { parseAccessToken } from './parseAccessToken';
import { parseIdToken } from './parseIdToken';
import { parseRefreshToken } from './parseRefreshToken';

export function parseAuth(auth?: AuthParams | null): ParsedAuth | undefined {
  if (auth) {
    return {
      ...auth,
      accessToken: parseAccessToken(auth.accessToken),
      idToken: parseIdToken(auth.idToken),
      refreshToken: parseRefreshToken(auth.refreshToken),
    };
  }
  return undefined;
}
