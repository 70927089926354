import React, { CSSProperties, FC, PropsWithChildren } from 'react';

import styles from './DetailList.module.css';

export type SharedDetailListProps = {
  className?: string;
  style?: CSSProperties;
};

export type DetailListEntryProps = {
  key: string;
  value: string[] | string | React.ReactNode;
  highlighted?: boolean;
};

export type DetailListVariant = 'default' | 'inverted' | 'dense' | 'separated';

export type DetailListProps = SharedDetailListProps & {
  entries: DetailListEntryProps[];
  preventWrapping?: boolean;
  stacked?: boolean;
  padded?: boolean;
  variant?: DetailListVariant;
};

export type DetailListValueProps = PropsWithChildren<SharedDetailListProps>;

export type DetailListKeyProps = PropsWithChildren<SharedDetailListProps>;

export const DetailListKey: FC<DetailListKeyProps> = ({
  children,
  className,
  style,
}) => (
  <dt className={[className, styles.key].join(' ')} style={style}>
    {children}
  </dt>
);

export const DetailListValue: FC<DetailListValueProps> = ({
  children,
  className,
  style,
}) => (
  <dd className={[className, styles.value].join(' ')} style={style}>
    {children}
  </dd>
);

export const DetailList: FC<DetailListProps> = ({
  entries,
  preventWrapping = false,
  stacked = false,
  padded = false,
  variant = 'default',
  className,
  style,
}) => (
  <dl
    className={[styles.detailList, className].join(' ')}
    style={style}
    data-is-padded={padded}
    data-prevent-wrap={preventWrapping}
    data-is-stacked={stacked}
    data-variant={variant}
  >
    {entries?.map(({ key, value, highlighted }) => (
      <div
        key={`DetailListKeyEntry-${key.toString()}`}
        className={styles.entry}
        data-is-highlighted={highlighted}
      >
        <DetailListKey key={`DetailListKey-${key.toString()}`}>
          {key}
        </DetailListKey>
        {(Array.isArray(value) ? value : [value ?? '']).map((val) => (
          <DetailListValue key={`DetailListValue-${key}-${val}`}>
            {val}
          </DetailListValue>
        ))}
      </div>
    ))}
  </dl>
);
