import { AuthConfig } from '@quirion/types';
import { EndpointConfig } from '../fetchEndpoints.types';

import { AUTH_CONFIG_BANKING } from '../constants/authConfig';
import { getCustomHeaders } from './getCustomHeaders';

export const fetchWithoutPayload = ({
  authConfig = AUTH_CONFIG_BANKING,
  endpointConfig,
}: {
  authConfig?: AuthConfig;
  endpointConfig: EndpointConfig;
}) => {
  const { method, url, private: isPrivateEndpoint } = endpointConfig;
  return fetch(url, {
    method,
    headers: getCustomHeaders(isPrivateEndpoint, authConfig),
  });
};
