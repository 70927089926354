export enum TransactionStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
  Pending = 'PENDING',
  All = 'ALL',
}

export enum TransactionType {
  IncomeCredit = 'Ertragsgutschrift',
  CollectionOfFees = 'Gebühreneinzug',
  DebitNoteReceipt = 'Lastschrifteingang',
  DebitReturn = 'Lastschriftrückgabe',
  TaxPosting = 'Steuerbuchung',
  OutgoingTransfer = 'Überweisungsausgang',
  TransferReceived = 'Überweisungseingang',
  Transaction = 'Transaktion',
  Transfer = 'Umbuchung',
  SecuritiesPurchase = 'Wertpapierkauf',
  SecuritiesSale = 'Wertpapierverkauf',
  All = 'ALL',
}

export type Transaction = {
  amount: number;
  creditorBic: string;
  currency: string; // ISO 4217
  debtorIban: string;
  creditorIban: string;
  status: TransactionStatus;
  creditorAccountHolder: string;
  createdAt: string; // ISO 8601
  debtorAccountHolder: string;
  Verwendungszweck: string;
  type: TransactionType;
  debtorBic: string;
  bookingDate?: string;
  valutaDate?: string;
};
