import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgInvestmentKuenstlicheIntelligenzGradient = function (
  props: SVGProps<SVGSVGElement>,
) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill={`url(#${_id})`}
        d="M20.64 27.69c-.67 0-1.33-.22-1.92-.65-.92-.68-1.5-1.79-1.5-2.89V6.94c.25-1.4 1.35-2.44 2.68-2.6 1.07-.13 2.11.28 2.79 1.11.44.54.69 1.19.71 1.87.89-.36 1.81-.34 2.67.08 1.24.6 2.1 1.95 2.15 3.35.04 1.06-.4 2.01-1.22 2.7.85.74 1.38 1.77 1.48 2.92.11 1.28-.33 2.5-1.22 3.4.44 1.18.28 2.48-.46 3.54a3.81 3.81 0 0 1-3.03 1.65 3.27 3.27 0 0 1-2.26 2.6c-.29.09-.58.13-.88.13ZM20.3 5.32c-.09 0-.18 0-.28.02-.9.11-1.64.81-1.81 1.7v17.11c0 .79.43 1.59 1.1 2.08.59.44 1.27.57 1.91.37.83-.26 1.43-.95 1.57-1.79-.86-.32-1.98-1.12-1.92-2.66l1 .04c-.06 1.33 1.33 1.71 1.53 1.76 1.03.1 2-.36 2.59-1.21.53-.77.66-1.71.36-2.57-.72.01-2.19-.09-2.93-1.24l.84-.55c.52.8 1.77.8 2.21.78.74-.7 1.11-1.68 1.03-2.71-.08-1.01-.6-1.91-1.43-2.48-.62.13-2.34.34-3.4-.99l.78-.62c.78.97 2.23.68 2.52.61.85-.51 1.3-1.28 1.27-2.19-.03-1.03-.69-2.05-1.59-2.49-.56-.27-1.43-.44-2.47.28-.23.43-1.03 1.67-2.65 1.55l.07-1c1.12.09 1.64-.91 1.72-1.06.21-.68.07-1.42-.39-1.97-.41-.5-.99-.77-1.62-.77Zm-3.55 19.1c-3.06 0-5.87-1.81-7.24-4.63h-.8a1.609 1.609 0 1 1 0-1h1.13v.5l.45-.2c1.16 2.63 3.69 4.33 6.46 4.33zm-9.57-5.74c-.34 0-.61.27-.61.61s.27.61.61.61.61-.27.61-.61-.27-.61-.61-.61m9.57 2.76c-2.89 0-5.24-2.44-5.24-5.43s2.35-5.43 5.24-5.43v1c-2.34 0-4.24 1.99-4.24 4.43s1.9 4.43 4.24 4.43zM5.11 17.62a1.609 1.609 0 1 1 1.53-2.11h2.01c.04-.79.19-1.57.45-2.31h-.46a1.609 1.609 0 1 1 0-1h1.19v.5l.46.2c-.43.98-.65 2.02-.65 3.11h-.5v.5h-2.5a1.62 1.62 0 0 1-1.53 1.11m0-2.22c-.34 0-.61.27-.61.61s.27.61.61.61.61-.27.61-.61-.27-.61-.61-.61m2.01-3.31c-.34 0-.61.27-.61.61s.27.61.61.61.61-.27.61-.61-.27-.61-.61-.61m4.08-.7-.79-.61c1.55-2.02 3.86-3.18 6.35-3.18v1c-2.17 0-4.2 1.02-5.55 2.79Z"
      />
      <defs>
        <linearGradient
          id={_id}
          x1="0%"
          x2="50%"
          y1="50%"
          y2="0%"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939D4" />
          <stop offset={0.48} stopColor="#5D4DF8" />
          <stop offset={0.74} stopColor="#B459F9" />
          <stop offset={1} stopColor="#FE3B68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgInvestmentKuenstlicheIntelligenzGradient;
