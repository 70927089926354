import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgIpsAvThin = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill={`url(#${_id})`}
        d="M3.4 1.66c.18-.05.38.05.43.24l2.93 10.25H18c.19 0 .35.16.35.35s-.16.35-.35.35h-2.45l3.15 6.75.39-.26c.69-.46 1.33-.99 1.91-1.59a.354.354 0 0 1 .6.25c0 .09-.04.18-.1.25a13.355 13.355 0 0 1-19 0 .37.37 0 0 1-.1-.25c0-.19.16-.35.35-.35.09 0 .18.04.25.1a12.7 12.7 0 0 0 1.94 1.61l.37.25 3.61-6.76H6.5c-.16 0-.29-.1-.34-.25l-3-10.5c-.05-.18.06-.38.24-.43zm11.37 11.19H9.71l-3.79 7.11.36.18c3.6 1.83 7.87 1.82 11.47-.02l.34-.18-3.32-7.11zm-6.9-1.25h10.07c.19 0 .35-.16.35-.35s-.16-.35-.35-.35H7.87c-.19 0-.35.16-.35.35s.16.35.35.35"
      />
      <defs>
        <linearGradient
          id={_id}
          x1={2}
          x2={26.47}
          y1={22.62}
          y2={9.73}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939d4" />
          <stop offset={0.48} stopColor="#5d4df8" />
          <stop offset={0.74} stopColor="#b459f9" />
          <stop offset={1} stopColor="#fe3b68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgIpsAvThin;
