import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgInvestmentEMobilitaetGradient = function (
  props: SVGProps<SVGSVGElement>,
) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill={`url(#${_id})`}
        d="M24.7 26.02c-.66 0-1.21-.47-1.34-1.09h-1.11c-1.54 0-2.82-1.12-3.08-2.59h-5.64c-.56 0-1.02-.46-1.02-1.02v-.61c0-.34-.28-.62-.62-.62-.36 0-.64.28-.64.62v2.25c0 .89-.73 1.62-1.62 1.62s-1.64-.73-1.64-1.62v-2.25c0-.34-.28-.62-.62-.62-.36 0-.64.28-.64.62v2.25c0 .89-.73 1.62-1.62 1.62s-1.62-.73-1.62-1.62V9.5c0-.8.4-1.53 1.07-1.96s1.5-.49 2.22-.15c.33.15.58.3.74.41h.01c.79.04 1.59-.17 2.39-.64 1.33-.77 2.9-1.18 4.53-1.18h2.31c.87 0 1.74.21 2.52.6l1.81.9c.66.33 1.37.52 2.13.56 2.29.13 3.55.57 4.1.82.34.16.55.51.5.89v.29l.24.16c.26.17.41.46.41.79v1.24c.02.2-.06.4-.21.54s-.34.23-.54.23h-1.06a2.179 2.179 0 0 1-4.13.48H13.4a2.176 2.176 0 0 1-4.14-.56H8.17c-1.44 0-1.83-1.44-1.83-2.2 0-.71.28-1.27.44-1.52v-.68c-.11-.06-.24-.13-.4-.21a1.29 1.29 0 0 0-1.26.09c-.38.24-.61.66-.61 1.12v13.47c0 .34.28.62.62.62s.62-.28.62-.62v-2.25c0-.89.73-1.62 1.62-1.62s1.64.73 1.64 1.62v2.25c0 .34.28.62.62.62.36 0 .64-.28.64-.62v-2.25c0-.89.73-1.62 1.62-1.62s1.64.73 1.64 1.62v.61l5.65.02a3.116 3.116 0 0 1 3.09-2.66h1.11c.13-.62.69-1.09 1.34-1.09.76 0 1.37.61 1.38 1.37h1.92v1H26.1v3.68h1.92v1H26.1c0 .76-.62 1.37-1.38 1.37Zm0-7.42a.38.38 0 0 0-.38.38v5.67a.38.38 0 1 0 .76 0v-5.67a.38.38 0 0 0-.38-.38m-2.45 1.09c-1.17 0-2.12.95-2.12 2.13s.95 2.12 2.12 2.12h1.08v-4.25zm2.29-8.27c-.65 0-1.18.53-1.18 1.18s.53 1.18 1.18 1.18 1.18-.53 1.18-1.18-.53-1.18-1.18-1.18m-13.13 0c-.65 0-1.18.53-1.18 1.18s.53 1.18 1.18 1.18 1.18-.53 1.18-1.18-.53-1.18-1.18-1.18m2.17 1.06h8.78a2.174 2.174 0 0 1 2.17-2.06c.99 0 1.83.67 2.09 1.58h.87v-1.01l-.65-.41v-.85c-.46-.19-1.59-.58-3.67-.7-.58-.03-1.13-.14-1.67-.31-.63.53-1.4.81-2.21.81h-4.87c-1.36 0-2.62-.72-3.32-1.86-.23.1-.46.22-.68.35-.88.51-1.78.77-2.66.78v.04h1.15v1H7.54c-.1.19-.22.5-.22.87 0 .12.04 1.21.83 1.21h1.18c.28-.87 1.1-1.5 2.07-1.5 1.16 0 2.12.92 2.17 2.06Zm2.62-3.94h3.1c.4 0 .78-.1 1.13-.28l-1.58-.79c-.64-.32-1.36-.49-2.08-.49h-.57zm-4.12-1.22c.54.75 1.41 1.22 2.36 1.22h.77V6.98h-.74c-.82 0-1.63.12-2.38.34Z"
      />
      <defs>
        <linearGradient
          id={_id}
          x1="0%"
          x2="50%"
          y1="50%"
          y2="0%"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939D4" />
          <stop offset={0.48} stopColor="#5D4DF8" />
          <stop offset={0.74} stopColor="#B459F9" />
          <stop offset={1} stopColor="#FE3B68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgInvestmentEMobilitaetGradient;
