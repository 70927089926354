import type { NonIndexRouteObject } from 'react-router-dom';

type RouteType = Omit<NonIndexRouteObject, 'children'> & {
  hasOutlet?: boolean;
  children?: Record<string, RouteType>;
};

export const getSubNavigationsAsArray = (
  routes: Record<string, RouteType>,
): NonIndexRouteObject[] =>
  Object.values(routes).flatMap((route) => {
    if (!route.path || !route.element) {
      return route.children ? getSubNavigationsAsArray(route.children) : [];
    }

    const baseRoute: NonIndexRouteObject = {
      path: route.path,
      element: route.element,
      children: [],
    };

    if (route.hasOutlet) {
      return route.children
        ? [{ ...baseRoute, children: getSubNavigationsAsArray(route.children) }]
        : [baseRoute];
    }

    return route.children
      ? [baseRoute, ...getSubNavigationsAsArray(route.children)]
      : [baseRoute];
  });
