/**
 * Status of a task.
 */
export enum TaskState {
  Closed = 'CLOSED',
  Done = 'DONE',
  Open = 'OPEN',
}

/**
 * Used to differentiate between different types of tasks.
 */
export enum TaskType {
  GWG = 'GWG',
  TermsAndConditions = 'TERMS_AND_CONDITIONS',
  WpHG = 'WPHG',
}

// export type TaskMetaData = {
//   deadline: string;
//   escalationLevel: string;
//   businessPartnerId?: number;
//   wphgExpiresAt?: string;
// };

export type TaskBase = {
  /**
   * Date of task creation.
   * Format: ISO-Date
   */
  createdAt: string;
  // metaData: TaskMetaData;
  /**
   * Date of last task update.
   * Format: ISO-Date
   */
  updatedAt?: string;
  state: TaskState;
  taskType: TaskType;
  type: 'Task';
  /**
   * `fake` is `true` for Tasks generated by FRONTEND code. They are not part of API responses.
   */
  fake?: boolean;
};
