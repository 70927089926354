/*
 * FORMATTING
 */

export const DEFAULT_LOCALE = 'de-DE';
const DEFAULT_NUMBER_OF_FRACTION_DIGITS = 2;

const DEFAULT_FORMATTING_OPTIONS = {
  minimumFractionDigits: DEFAULT_NUMBER_OF_FRACTION_DIGITS,
  maximumFractionDigits: DEFAULT_NUMBER_OF_FRACTION_DIGITS,
  useGrouping: true,
};

export const DEFAULT_CURRENCY_FORMATTING_OPTIONS: Intl.NumberFormatOptions = {
  ...DEFAULT_FORMATTING_OPTIONS,
  currency: 'EUR',
  style: 'currency',
};

export const DEFAULT_PERCENTAGE_FORMATTING_OPTIONS: Intl.NumberFormatOptions =
  DEFAULT_FORMATTING_OPTIONS;

export const DATE_FORMATS: Record<string, Intl.DateTimeFormatOptions> = {
  DATE_TIME: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  },
  DATE: {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
};

export const DATE_FORMAT_API = 'YYYY-MM-DD';
export const DATE_FORMAT_DE = 'DD.MM.YYYY';

/*
 * CHARACTERS
 */

export enum CharsetNames {
  numbers = 'numbers',
  alphabetic = 'alphabetic',
  alphabeticLowerCase = 'alphabeticLowerCase',
  alphabeticUpperCase = 'alphabeticUpperCase',
  alphanumeric = 'alphanumeric',
  alphanumericReadable = 'alphanumericReadable',
  special = 'special',
  password = 'password',
}

export const CHARACTERS_SPECIAL = '!?+=.#@';
export const CHARACTERS_NUMBERS = '0123456789';
export const CHARACTERS_ALPHABET_LOWERCASE = 'abcdefghijklmnopqrstuvwxyz';
export const CHARACTERS_ALPHABET_UPPERCASE =
  CHARACTERS_ALPHABET_LOWERCASE.toUpperCase();
export const CHARACTERS_ALPHABETIC = `${CHARACTERS_ALPHABET_LOWERCASE}${CHARACTERS_ALPHABET_UPPERCASE}`;

export const CHARACTERS_ALPHANUMERIC_LOWERCASE = `${CHARACTERS_NUMBERS}${CHARACTERS_ALPHABET_LOWERCASE}`;
export const CHARACTERS_ALPHANUMERIC_UPPERCASE = `${CHARACTERS_NUMBERS}${CHARACTERS_ALPHABET_UPPERCASE}`;
export const CHARACTERS_ALPHANUMERIC = `${CHARACTERS_NUMBERS}${CHARACTERS_ALPHABETIC}`;
export const CHARACTERS_ALPHANUMERIC_READABLE = CHARACTERS_ALPHANUMERIC.replace(
  /[0OIl]/g,
  '',
);

export const CHARACTERS_PASSWORD = `${CHARACTERS_ALPHANUMERIC_READABLE}${CHARACTERS_SPECIAL}`;

export const CHARACTER_SETS: Record<CharsetNames, string> = {
  [CharsetNames.alphabeticLowerCase]: CHARACTERS_ALPHABET_LOWERCASE,
  [CharsetNames.alphabeticUpperCase]: CHARACTERS_ALPHABET_UPPERCASE,
  [CharsetNames.alphabetic]: CHARACTERS_ALPHABETIC,
  [CharsetNames.alphanumeric]: CHARACTERS_ALPHANUMERIC,
  [CharsetNames.alphanumericReadable]: CHARACTERS_ALPHANUMERIC_READABLE,
  [CharsetNames.numbers]: CHARACTERS_NUMBERS,
  [CharsetNames.password]: CHARACTERS_PASSWORD,
  [CharsetNames.special]: CHARACTERS_SPECIAL,
};

/*
 * PASSWORDS / MFA / CODES
 */

export type PasswordRequirement = {
  label: React.ReactNode;
  validator: (password: string) => boolean;
};

export const HAS_NUMBER_REGEXP = /(?=.*[0-9])/;
export const HAS_UPPERCASE_LETTER_REGEXP = /(?=.*[A-Z])/;
export const HAS_LOWERCASE_LETTER_REGEXP = /(?=.*[a-z])/;
export const HAS_SPECIAL_REGEXP = new RegExp(`(?=.*[${CHARACTERS_SPECIAL}])`);

export const PASSWORD_SHARED_REQUIREMENTS: PasswordRequirement[] = [
  {
    label: 'Großbuchstabe',
    validator: (password) => HAS_UPPERCASE_LETTER_REGEXP.test(password),
  },
  {
    label: 'Kleinbuchstabe',
    validator: (password) => HAS_LOWERCASE_LETTER_REGEXP.test(password),
  },
  {
    label: 'Zahl',
    validator: (password) => HAS_NUMBER_REGEXP.test(password),
  },
];

export const PASSWORD_DEFAULT_MIN_LENGTH = 8;
export const PASSWORD_DEFAULT_REQUIREMENTS_REGEXP = new RegExp(
  `(?=.{${PASSWORD_DEFAULT_MIN_LENGTH},})(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])`,
);

export const PASSWORD_DEFAULT_REQUIREMENTS: PasswordRequirement[] = [
  ...PASSWORD_SHARED_REQUIREMENTS,
  {
    label: `Mindestens ${PASSWORD_DEFAULT_MIN_LENGTH} Zeichen`,
    validator: (password) => password.length >= PASSWORD_DEFAULT_MIN_LENGTH,
  },
];

export const PASSWORD_SECURE_REQUIREMENTS: PasswordRequirement[] = [
  ...PASSWORD_DEFAULT_REQUIREMENTS,
  {
    label: `Sonderzeichen (${CHARACTERS_SPECIAL})`,
    validator: (password) => HAS_SPECIAL_REGEXP.test(password),
  },
];

export const CODE_COGNITO_STRUCTURE = [1, 1, 1, 1, 1, 1];
export const CODE_VOUCHER_STRUCTURE = [4, 4, 4, 4];

/*
 * LABELS
 */

export const ORDER_TYPE_LABELS = {
  ASSET: 'Kauf',
  CHANGEPACKAGE: 'Paketwechsel',
  CHANGEPERSON: 'Persönliche Angaben',
  CLEARING_PLUS: 'Verrechnungskonto PLUS',
  DEFAULT: 'Auftrag',
  DELETEBP: 'Kontoauflösung',
  DELETESTRATEGY: 'Strategieauflösung',
  DISBURSEMENT: 'Auszahlung',
  FIRST: 'Eröffnung',
  FSA: 'Freistellungsauftrag', // (tax) exemption order
  STRATEGYCHANGE: 'Strategiewechsel',
  SUBSCRIPTION: 'Servicepaketwechsel',
  TOPUP: 'Aufstockung',
  WITHDRAWAL: 'Entnahme',
} as const;

/*
 * PROJECT CONFIGS
 * (formerly `@quirion/project-configs`)
 */

export enum ProjectName {
  Banking = 'banking',
  Onboarding = 'onboarding',
  Admin = 'admin',
}

export enum Env {
  Development = 'development',
  DevelopmentLocal = 'development-local',
  DevelopmentE2E = 'development-e2e',
  Preproduction = 'preproduction',
  Production = 'production',
}

export const PROJECT_DEVELOPMENT_PORTS: { [key in ProjectName]: number } = {
  [ProjectName.Banking]: 3000,
  [ProjectName.Onboarding]: 3001,
  [ProjectName.Admin]: 3003,
};

/**
 * Default Google Tag Manager ID (for production).
 */
export const DEFAULT_GTM_ID = 'GTM-WSGGKZB';

/**
 * Google Tag Manager IDs for different environments.
 */
export const GTM_IDS = {
  [Env.Development]: DEFAULT_GTM_ID,
  [Env.DevelopmentLocal]: DEFAULT_GTM_ID,
  [Env.Preproduction]: DEFAULT_GTM_ID,
  [Env.Production]: DEFAULT_GTM_ID,
};

export enum MailAddresses {
  MailConsultants = 'beratung@quirion.de',
  MailSupport = 'info@quirion.de',
}

export enum PhoneNumbers {
  ConsultantPool = '+49 (0)30 890 21-442',
  SupportHotline = '+49 (0)30 890 21-400',
}

export const SUPPORT_WORKING_HOURS = 'Mo.–Fr. von 9:00–18:00 Uhr';

export const URL_QUIRION_DE = 'https://www.quirion.de';
export const URL_QUIRION_MEDIA_BASE =
  'https://media.quirion.de/fileadmin/redaktion';

export enum ExternalLinks {
  BlogPostStay = `${URL_QUIRION_DE}/post/dabei-sein-und-bleiben`,
  ConceptPerformance = `${URL_QUIRION_DE}/anlagekonzept/performance`,
  ConsultantPool = 'tel:+493089021442',
  /**
   * Only used for "Zinskonto" (business partner without product / IPS) and {@link AccountType.JointAccount}.
   */
  ContractFormInterestJointAccount = `${URL_QUIRION_MEDIA_BASE}/PDFs/QPB_QUI_Vertragsformular_Eroeffnung_Gemeinschaftskonto_2023-06-01.pdf`,
  /**
   * Only used for "Zinskonto" (business partner without product / IPS) and {@link AccountType.SingleAccount} or {@link AccountType.ChildSingleAccount} or {@link AccountType.ChildJointAccount}.
   */
  ContractFormInterestSingleChildAccount = `${URL_QUIRION_MEDIA_BASE}/PDFs/QPB_QUI_Vertragsformular_Eroeffnung_Einzelkonto_2023-06-01.pdf`,
  DepositInsurance = `${URL_QUIRION_DE}/sicherheit`,
  Imprint = `${URL_QUIRION_DE}/impressum`,
  MailConsultants = `mailto:${MailAddresses.MailConsultants}`,
  MailSupport = `mailto:${MailAddresses.MailSupport}`,
  Privacy = `${URL_QUIRION_DE}/datenschutz`,
  PrivacyNotice = `${URL_QUIRION_MEDIA_BASE}/user_upload/Dokumente/quirion_datenschutzinformationen_kunden.pdf`,
  PrivacyNoticeQuirin = `${URL_QUIRION_MEDIA_BASE}/user_upload/Dokumente/qpb_datenschutzhinweise-kunden.pdf`,
  RisksOfFinancialInvestment = `${URL_QUIRION_MEDIA_BASE}/user_upload/Dokumente/quirion_risikenderfinanzanlage.pdf`,
  Support = `${URL_QUIRION_DE}/kontakt`,
  SupportHotline = 'tel:+493089021400',
  TermsClearingAccountPlus = `${URL_QUIRION_MEDIA_BASE}/PDFs/qpb-zinsbedingungen.pdf`,
  TermsNotice = `${URL_QUIRION_MEDIA_BASE}/PDFs/quirion_AGB.pdf`,
  TermsNoticeExtra = `${URL_QUIRION_MEDIA_BASE}/user_upload/Dokumente/qpb-zusatzbedingungen.pdf`,
  /**
   * Only used for "Zinskonto" (business partner without product / IPS).
   */
  TermsNoticeExtraInterest = `${URL_QUIRION_MEDIA_BASE}/PDFs/QPB_QUI_Zusatzbedingungen_Konto_2023-06-01.pdf`,
  TermsNoticeQuirin = `${URL_QUIRION_MEDIA_BASE}/user_upload/Dokumente/QPB_Allgemeine-Geschaeftsbedingungen.pdf`,
  UpdatePersonalData = `${URL_QUIRION_MEDIA_BASE}/user_upload/Dokumente/quirion_aenderung_personenbezogener_daten.pdf`,
}

/** Base URL for API calls to onboarding API */
export enum OnboardingApiBase {
  Prod = 'https://48junsr744.execute-api.eu-central-1.amazonaws.com/v1',
  Dev = 'https://zfyhicjnmf.execute-api.eu-central-1.amazonaws.com/v1',
}

/*
 * OTHERS
 */

export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const FORM_FIELD_OPTION_SEPARATOR = '__';
export const FORM_FIELD_NAME_SEPARATOR = '_';
export const CONDITION_ID_SEPARATOR = '_';

export const NO_NAME = '(ohne Namen)';

export enum StorageKey {
  AdminAuthorization = 'qat', // quirion admin ...
  AdminAuthorizationChallenge = 'qac',
  /**
   * used in some `index.html` files and injected by apps into webviews.. don't change value.
   */
  AppToWeb = 'appToWeb',
  Authorization = 'qbt', // quirion banking token
  AuthorizationChallenge = 'qbc', // quirion banking challenge
  /**
   * set / used by support-login in admin-tool
   */
  BankingMode = 'quirion_mode',
  DeviceId = 'qid',
  DynamicHints = 'quirion_dynamic_hints',
  Endpoints = 'quirion_application',
  ErrorReload = 'quirion_error_reload',
  MigrationShown = 'quirion_migration_shown',
  SidebarId = 'quirion_sidebar',
  UpdateDataPersonal = 'quirion_update_personal',
  UpdateDataWpHG = 'quirion_update_wphg',
  /**
   * set / used by quirion.de iframes.. don't change value.
   */
  Utm = 'quirion_utm',
}

export const DYNAMIC_HINT_SEPARATOR = ';';
