import { Env, GTM_IDS } from '../constants';

/**
 * Get the Google Tag Manager ID for the given environment.
 */
export const getGtmId = (env: Env) => {
  if (env === Env.DevelopmentE2E) return null;

  return GTM_IDS[env];
};
