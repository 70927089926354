import React from 'react';

export type OverflowResizeState = {
  overflowingItems: string[];
  ref: React.MutableRefObject<HTMLDivElement | null>;
  orientation: OverflowResizeOrientation;
  renderId: number;
  addOverflowing?(itemKey: string): void;
  removeOverflowing?(itemKey: string): void;
  setOrientation?(orientation: OverflowResizeOrientation): void;
  forceUpdate?(): void;
};

export type OverflowResizeOrientation = 'horizontal' | 'vertical';

export enum OverflowResizeActions {
  ADD_ITEM = 'add_item',
  REMOVE_ITEM = 'remove_item',
  SET_ORIENTATION = 'set_orientation',
  FORCE_UPDATE = 'force_update',
}

export type OverflowResizeActionType =
  | { type: OverflowResizeActions.ADD_ITEM; payload: string }
  | { type: OverflowResizeActions.REMOVE_ITEM; payload: string }
  | {
      type: OverflowResizeActions.SET_ORIENTATION;
      payload: OverflowResizeOrientation;
    }
  | { type: OverflowResizeActions.FORCE_UPDATE };

export type OverflowMode = 'auto' | 'always' | 'hidden';
