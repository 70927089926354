export const setTask = (task) => (dispatch) => {
  dispatch({ type: 'SET_TASK', task });
};
export const closeTaskWpHG = (businessPartnerId) => (dispatch) => {
  dispatch({ type: 'CLOSE_TASK_WPHG', businessPartnerId });
};

// API STUFF --> move to separate File apiActions.js ??
// OR: merge all actions to one file.. are not so many..

export const getUser = (response, id) => (dispatch) => {
  dispatch({ type: 'API_GET_USER', response, id });
};
export const getBp = (response, id) => (dispatch) => {
  dispatch({ type: 'API_GET_BP', response, id });
};
export const getIPS = (response, id) => (dispatch) => {
  dispatch({ type: 'API_GET_IPS', response, id });
};
export const setSavingsPlans = (payload) => (dispatch) => {
  dispatch({ type: 'API_SET_SAVINGSPLANS', payload });
};
export const deleteSavingsPlan = (id) => (dispatch) => {
  dispatch({ type: 'API_DELETE_SAVINGSPLAN', id });
};
export const getConditions = (response) => (dispatch) => {
  dispatch({ type: 'API_GET_CONDITIONS', response });
};

export const setMigrationDetails = (payload) => (dispatch) => {
  dispatch({ type: 'SET_MIGRATION_DETAILS', payload });
};

export const clearReduxStore = (dispatch) => {
  dispatch({ type: 'CLEAR_STORE' });
};
