import { SVGProps } from 'react';

import {
  SvgBuildingBank,
  SvgCoins,
  SvgCreditCard,
  SvgTransferIn,
} from '@quirion/assets';
import { Heading, Hint, Icon, RamGrid, Stack, Tag } from '@quirion/components';
import { PaymentMethod } from '@quirion/types';

import {
  DESCRIPTION_CLEARING_SHORT,
  DESCRIPTION_SEPA_DEBIT_SHORT,
  DESCRIPTION_SEPA_REFERENCE_SHORT,
  DESCRIPTION_VOUCHER_SHORT,
  DESCRIPTION_WIRE_SHORT,
} from '../constants';

import styles from './PaymentSelection.module.css';

export type PaymentOption = {
  description: string;
  disabled: boolean;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  paymentMethod: PaymentMethod;
  title: string;
};
export type PaymentOptions = PaymentOption[];

export const AVAILABLE_PAYMENT_OPTIONS: PaymentOptions = [
  {
    title: 'Referenzkonto',
    description: DESCRIPTION_SEPA_REFERENCE_SHORT,
    disabled: false,
    paymentMethod: PaymentMethod.ReferenceAccount,
    icon: SvgBuildingBank,
  },
  {
    title: 'Lastschrift',
    description: DESCRIPTION_SEPA_DEBIT_SHORT,
    disabled: false,
    paymentMethod: PaymentMethod.Debit,
    icon: SvgBuildingBank,
  },
  {
    title: 'Überweisung',
    description: DESCRIPTION_WIRE_SHORT,
    disabled: false,
    paymentMethod: PaymentMethod.Wire,
    icon: SvgTransferIn,
  },
  {
    title: 'Gutscheinkarte',
    description: DESCRIPTION_VOUCHER_SHORT,
    disabled: false,
    paymentMethod: PaymentMethod.Voucher,
    icon: SvgCreditCard,
  },
  {
    title: 'Verrechnungskonto (PLUS)',
    description: DESCRIPTION_CLEARING_SHORT,
    disabled: false,
    paymentMethod: PaymentMethod.ClearingAccount,
    icon: SvgCoins,
  },
];

export function getPaymentDisabledReason(item?: PaymentOption) {
  if (!item) return 'fehlt';
  if (item.disabled) {
    return 'nicht verfügbar';
  }
  return undefined;
}

export const PaymentSelection = ({
  getDisabledReason = getPaymentDisabledReason,
  heading = 'Bitte wählen Sie die Zahlungsmethode aus.',
  hideDisabled = false,
  onSelect,
  paymentOptions = AVAILABLE_PAYMENT_OPTIONS,
}: {
  getDisabledReason?: (item: PaymentOption) => string | undefined;
  heading?: string;
  hideDisabled?: boolean;
  onSelect: (paymentMethod: PaymentMethod) => void;
  paymentOptions?: PaymentOptions;
}) => {
  const selectableItems = paymentOptions
    ?.sort((a, b) => {
      // put disabled items to bottom
      if (getDisabledReason?.(b) && !getDisabledReason?.(a)) {
        return -1;
      }
      return 0;
    })
    .flatMap((item) => {
      const disabledReason = getDisabledReason?.(item);
      if (hideDisabled && disabledReason) return [];
      return [
        <button
          className={styles.button}
          type="button"
          key={item.title}
          onClick={() => onSelect(item.paymentMethod)}
          disabled={!!disabledReason}
        >
          <Stack
            className={styles.stack}
            justifyItems="stretch"
            gap="var(--space-2)"
          >
            <Icon name={item.icon} size="var(--sizes-8)" />
            <Heading tag="h5" variant="h5">
              {item.title}
            </Heading>
            <div className={styles.description}>{item.description}</div>
            <div>
              {!!disabledReason && (
                <Tag size="sm" variant="muted" text={disabledReason} />
              )}
            </div>
          </Stack>
        </button>,
      ];
    });

  return (
    <Stack justifyItems="stretch">
      <Heading tag="h4">{heading}</Heading>
      <RamGrid minWidth="10rem">
        {selectableItems?.length ? (
          selectableItems
        ) : (
          <Hint>Keine Zahlungsmethode verfügbar.</Hint>
        )}
      </RamGrid>
    </Stack>
  );
};
