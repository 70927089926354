import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgIpsNhThin = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill={`url(#${_id})`}
        d="M5.41 17.66c.12-.11.14-.29.05-.43-2-3.24-1.8-6.9 1.01-9.7 5.9-5.9 14.07-5.18 14.39-5.15.16.01.29.14.3.3 0 0 .74 8.5-5.15 14.39-2.8 2.8-6.46 3.02-9.7 1.01a.334.334 0 0 0-.43.05L2.92 21.1c-.06.06-.15.1-.23.1-.18 0-.33-.15-.33-.33 0-.09.04-.17.1-.23l2.97-2.97zM6.94 8c-2.49 2.48-2.84 5.82-.86 8.91.06.09 2.79-2.71 2.79-2.71V9.5c.01-.17.16-.31.33-.31s.31.13.33.3v4.06l3.04-3.04V7.53c0-.18.15-.33.33-.33s.33.15.33.33v2.31l4.24-4.24a.3.3 0 0 1 .22-.09.333.333 0 0 1 .25.55c0 .01-4.26 4.25-4.26 4.25H16c.17.02.3.16.3.33s-.13.31-.29.33h-2.97L10 14.02l4.07-.01c.18 0 .33.15.33.33s-.15.33-.33.33H9.34l-2.79 2.74c3.16 2.04 6.51 1.67 8.99-.82 4.75-4.75 5.03-11.25 4.99-13.25 0-.19-.16-.34-.34-.34-2-.04-8.5.24-13.25 4.99z"
      />
      <defs>
        <linearGradient
          id={_id}
          x1={2}
          x2={25.2}
          y1={21.54}
          y2={8.49}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939d4" />
          <stop offset={0.48} stopColor="#5d4df8" />
          <stop offset={0.74} stopColor="#b459f9" />
          <stop offset={1} stopColor="#fe3b68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgIpsNhThin;
