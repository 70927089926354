export default [
  { '01': 'Arbeitend / Angestellt' },
  { '02': 'Arbeitslos' },
  { '03': 'Beamte oder Beamter' },
  { '04': 'Rentnerin oder Rentner / Pensioniert' },
  { '05': 'Privatière oder Privatier' },
  { '06': 'Hausfrau/-mann' },
  { '07': 'Schülerin oder Schüler / Studierend' },
  { '08': 'Auszubildende oder Auszubildender' },
  { '09': 'Zivildienstleistend' },
  { 10: 'Selbständig' },
  { 11: 'sonstige Privatperson' },
];
