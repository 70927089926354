import React, {
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
} from 'react';

import {
  OverflowMode,
  OverflowResizeActions,
  OverflowResizeActionType,
  OverflowResizeOrientation,
  OverflowResizeState,
} from './OverflowMenu.types';

/** Reducer */
const reducer = (
  state: OverflowResizeState,
  action: OverflowResizeActionType,
) => {
  switch (action.type) {
    case OverflowResizeActions.ADD_ITEM: {
      return {
        ...state,
        overflowingItems: Array.from(
          new Set([...state.overflowingItems, action.payload]),
        ),
      };
    }
    case OverflowResizeActions.REMOVE_ITEM: {
      return {
        ...state,
        overflowingItems: Array.from(
          new Set(
            [...state.overflowingItems].filter(
              (item) => item !== action.payload,
            ),
          ),
        ),
      };
    }
    case OverflowResizeActions.SET_ORIENTATION: {
      return {
        ...state,
        orientation: action.payload,
      };
    }
    case OverflowResizeActions.FORCE_UPDATE: {
      return {
        ...state,
        renderId: state.renderId + 1,
      };
    }
    default:
      throw new Error(`Reducer does not have action "${action}"`);
  }
};

const OverflowContext = React.createContext<OverflowResizeState | undefined>(
  undefined,
);

const getSizeFromEntry = (entry: ResizeObserverEntry) => {
  if (entry.contentBoxSize) {
    // Firefox implements `contentBoxSize` as a single content rect, rather than an array
    const contentBoxSize = Array.isArray(entry.contentBoxSize)
      ? entry.contentBoxSize[0]
      : entry.contentBoxSize;
    return {
      width: contentBoxSize.inlineSize,
      height: contentBoxSize.blockSize,
    };
  }

  return { width: entry.contentRect.width, height: entry.contentRect.height };
};

export const OverflowProvider: FC<PropsWithChildren> = ({ children }) => {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const [state, dispatch] = useReducer(reducer, {
    overflowingItems: [],
    ref: parentRef,
    orientation: 'horizontal',
    renderId: 0,
  });

  const addOverflowing = (itemKey: string) => {
    dispatch({ type: OverflowResizeActions.ADD_ITEM, payload: itemKey });
  };

  const removeOverflowing = (itemKey: string) => {
    dispatch({ type: OverflowResizeActions.REMOVE_ITEM, payload: itemKey });
  };

  const forceUpdate = () => {
    dispatch({ type: OverflowResizeActions.FORCE_UPDATE });
  };

  useEffect(() => {
    if (!parentRef.current) {
      return () => {};
    }

    const handleResize = (entries: ResizeObserverEntry[]) => {
      entries.forEach((entry) => {
        if (!parentRef.current) {
          return;
        }

        // Get children that should reflow
        const flowingChildren = Array.from(parentRef.current.children).filter(
          (item) => (item as HTMLElement).dataset.targetid,
        );

        // Get more button
        const moreButton = Array.from(parentRef.current.children).find(
          (item) => (item as HTMLElement).dataset.isMore,
        );

        // get size of observed box
        const { width, height } = getSizeFromEntry(entry);

        let availableSpace;
        // Compare widths when horizontal, else compare height
        if (state.orientation === 'horizontal') {
          availableSpace = moreButton
            ? width - moreButton.getBoundingClientRect().width
            : width;
        } else {
          availableSpace = moreButton
            ? height - moreButton.getBoundingClientRect().height
            : height;
        }

        flowingChildren.reduce((remainingSpace, item) => {
          const itemId = (item as HTMLElement).dataset.targetid;
          const overflowMode = (item as HTMLElement).dataset
            .overflowMode as OverflowMode;

          if (!itemId) {
            return remainingSpace;
          }

          // If no space is remaining we can ignore further items
          if (remainingSpace <= 0) {
            window.requestAnimationFrame(() => {
              addOverflowing(itemId);
            });
            return remainingSpace;
          }

          // if overflow mode is hidden, item should be always hidden
          if (overflowMode === 'hidden') {
            return remainingSpace;
          }
          // if overflow mode is always, item should be always in overflow
          if (overflowMode === 'always') {
            window.requestAnimationFrame(() => {
              addOverflowing(itemId);
            });
            return remainingSpace;
          }

          // get item width and compare to remaining space
          const itemSize =
            state.orientation === 'horizontal'
              ? item.getBoundingClientRect().width
              : item.getBoundingClientRect().height;
          // if it fits, set to flow normal and return remaining space
          if (itemSize < remainingSpace) {
            window.requestAnimationFrame(() => {
              removeOverflowing(itemId);
            });
            return remainingSpace - itemSize;
          }
          // else set it to overflow and return negative space to make sure
          window.requestAnimationFrame(() => {
            addOverflowing(itemId);
          });
          return -1;
        }, availableSpace);
      });
    };

    const observer = new ResizeObserver(handleResize);
    observer.observe(parentRef.current);

    return () => observer.disconnect();
  }, [state.orientation, state.renderId]);

  const memoizedContext = useMemo(() => {
    const setOrientation = (orientation: OverflowResizeOrientation) => {
      dispatch({
        type: OverflowResizeActions.SET_ORIENTATION,
        payload: orientation,
      });
    };
    return {
      overflowingItems: state.overflowingItems,
      ref: parentRef,
      orientation: state.orientation,
      renderId: state.renderId,
      setOrientation,
      forceUpdate,
    };
  }, [state.overflowingItems, state.orientation, state.renderId]);

  return (
    <OverflowContext.Provider value={memoizedContext}>
      {children}
    </OverflowContext.Provider>
  );
};

export const useOverflowContext = () => {
  const overflowingContext = useContext(OverflowContext);
  if (overflowingContext === undefined) {
    throw new Error(
      'useOverflowContext must be used inside a OverflowContext.Provider or OverflowProvider',
    );
  }

  return overflowingContext;
};
