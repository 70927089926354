/* eslint-disable no-console */
import {
  Component,
  createElement,
  ErrorInfo,
  FunctionComponent,
  ReactNode,
} from 'react';

import { StorageKey } from '@quirion/utils';

import { ErrorBoundaryFallbackProps, ErrorState } from '../types';

import { ErrorBoundaryDefaultFallback } from './ErrorBoundaryDefaultFallback';

const initialErrorState: ErrorState = {
  hasError: false,
  error: null,
  errorInfo: null,
};

type ErrorBoundaryProps = {
  children: ReactNode;
  fallback?: FunctionComponent<Partial<ErrorBoundaryFallbackProps>>;
};

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = initialErrorState;
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error);
    console.warn(errorInfo);

    // if there has been an asset or chunk load error, do a full reload once
    if (
      error.name === 'ChunkLoadError' ||
      error.message?.includes('dynamically imported module')
    ) {
      console.error(`ChunkLoadError detected in '${window.location.href}'`);
      if (sessionStorage.getItem(StorageKey.ErrorReload) === 'true') {
        sessionStorage.removeItem(StorageKey.ErrorReload);
      } else {
        sessionStorage.setItem(StorageKey.ErrorReload, 'true');
        const { href } = window.location;
        setTimeout(() => {
          window.location.href = `${href}${
            href.includes('?') ? '&' : '?'
          }timestamp=${Date.now()}`;
        }, 200);
        return;
      }
    }

    this.setState({ hasError: true, error, errorInfo });
  }

  render() {
    const { children, fallback } = this.props;
    const { hasError, error, errorInfo } = this.state;
    const componentStack = errorInfo?.componentStack;

    if (hasError) {
      const fallbackProps: ErrorBoundaryFallbackProps = {
        hasError,
        error,
        errorName: error?.name,
        errorMessage: error?.message,
        componentStack,
      };

      if (fallback) {
        return createElement(fallback, fallbackProps);
      }

      return <ErrorBoundaryDefaultFallback {...fallbackProps} />;
    }

    return children;
  }
}
