import React, { CSSProperties, FC, PropsWithChildren } from 'react';

import styles from './Heading.module.css';

export type HeadingType = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'legend';

export type HeadingProps = PropsWithChildren<{
  variant?: HeadingType;
  tag: HeadingType;
  withMargins?: boolean;
  hyphenate?: boolean;
  className?: string;
  style?: CSSProperties;
  id?: string;
}>;

/**
 * Renders to a `<h1-6>` element depending on the `tag` given.
 */
export const Heading: FC<HeadingProps> = ({
  variant,
  tag,
  children,
  withMargins,
  hyphenate = false,
  className,
  id = '',
  style,
}) => {
  const TagName = tag;

  return (
    <TagName
      data-withmargins={withMargins}
      data-variant={variant}
      data-hyphenate={hyphenate}
      className={[className, styles.heading].join(' ')}
      id={id}
      style={style}
    >
      {children}
    </TagName>
  );
};
