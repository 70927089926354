import { TextLink } from '@quirion/components';
import { ExternalLinks } from '@quirion/utils';

import styles from './Footer.module.css';

export const Footer = ({
  id,
  className,
}: {
  id: string;
  className?: string;
}) => (
  <footer className={['footer', className ?? ''].join(' ')} id={id}>
    <TextLink
      className={styles.link}
      target="_blank"
      href={ExternalLinks.PrivacyNotice}
    >
      Datenschutz
    </TextLink>
    <TextLink
      className={styles.link}
      target="_blank"
      href={ExternalLinks.Imprint}
    >
      Impressum
    </TextLink>
  </footer>
);
