import { useEffect, useMemo, useState } from 'react';
import type { ComponentType, ElementType, FC } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { isAuthenticated, logout } from '@quirion/api';
import {
  SvgBuildingStore,
  SvgCalendarSearch,
  SvgCash,
  SvgCreditCard,
  SvgHome,
  SvgListCheck,
  SvgMail,
  SvgSchool,
  SvgSettings,
} from '@quirion/assets';
import { Navigation as NavigationComponent } from '@quirion/components';
import { isTaskWpHG, TaskState, TaskType } from '@quirion/types';
import type {
  BusinessPartnerRecord,
  SubscriptionPackage,
} from '@quirion/types';
import { getHighestPackageFromBps, StorageKey } from '@quirion/utils';

import { useChecks } from 'lib/contexts/ChecksContext';
import { clearReduxStore, setTask } from 'lib/redux/actions/userActions';
import type { State } from 'lib/redux/types';
import { useCheckWphgDataValidity } from 'lib/utils/wphg/useCheckWphgDataValidity';

import styles from './Navigation.module.css';

type NavigationPoint = {
  label: string;
  icon?: ComponentType;
  itemKey: string;
  onClick?: () => void;
  as?: ElementType<any>;
  to?: string;
  customIcon?: JSX.Element;
  overflowStyle?: any;
  active?: boolean;
};

export const Navigation: FC = () => {
  const dispatch = useDispatch();

  const [subscriptionType, setSubscriptionType] =
    useState<SubscriptionPackage>();
  const tasks = useSelector((state: State) => state.tasks);
  const user = useSelector((state: State) => state.user);
  const bps: BusinessPartnerRecord = useSelector(
    (state: State) => state.businessPartner,
  );

  // const unreadPostBoxElements = useSelector((state: State)
  // => state.unreadedPostBoxElements)  // defined but not yet populated in redux

  const location = useLocation();

  const highestPackage = getHighestPackageFromBps(bps);
  useEffect(() => {
    if (highestPackage !== subscriptionType) {
      setSubscriptionType(highestPackage);
    }
  }, [highestPackage, subscriptionType]);

  const unreadMessagesAmount = useMemo(
    () => user?.postbox?.filter((msg) => msg.status === 'UNREAD')?.length ?? 0,
    [user.postbox],
  );

  const signOut = () => {
    logout().then(() => {
      clearReduxStore(dispatch);
      sessionStorage.removeItem(StorageKey.BankingMode);
      document.location.href = '/logout/';
    });
  };

  /*
    WpHG-Task frontend fake generation.
    Can be deleted, if `WPHG` tasks are implemented correctly by middleware.

    NOTES:
    - Some weird behaviour may occur, when using hot reloading / fast refresh.
    - Reloading on `Aufgaben` screen breaks the amount indicator in nav icon.
  */
  const { bpsWithInvalidWphg } = useCheckWphgDataValidity();
  const { hasSomeActiveBP, hasSomeOpenIps } = useChecks();

  useEffect(() => {
    bpsWithInvalidWphg.forEach((bp) => {
      const { businessPartnerId, products, wphgExpiresAt } = bp;

      if (
        !tasks.some(
          (task) =>
            isTaskWpHG(task) &&
            task.metaData?.businessPartnerId === businessPartnerId,
        )
      ) {
        const expiredDate = wphgExpiresAt
          ? new Date(wphgExpiresAt).toISOString()
          : new Date().toISOString();

        // only BPs with IPS get a task
        if (products?.length) {
          setTask({
            createdAt: expiredDate,
            updatedAt: expiredDate,
            state: TaskState.Open,
            taskType: TaskType.WpHG,
            type: 'Task',
            metaData: {
              businessPartnerId,
              wphgExpiresAt: expiredDate,
            },
            fake: true, // informs the reducer, that this task should be kept by `getUser` action
          })(dispatch);
        }
      }
    });
  }, [bpsWithInvalidWphg, tasks]);
  // END: WpHG-Task frontend fake generation.

  const openTasks = tasks.filter((task) => task.state === TaskState.Open);

  const navPoints: NavigationPoint[] = useMemo(() => {
    if (!user.loaded) return [];
    const hasActiveBpsAndIps = hasSomeActiveBP && hasSomeOpenIps;

    const points: NavigationPoint[] = [
      {
        label: 'Home',
        icon: SvgHome,
        itemKey: 'Home',
        active: location.pathname === '/',
        as: Link,
        to: '/',
      },
    ];

    if (hasSomeActiveBP) {
      points.push({
        label: 'Marktplatz',
        icon: SvgBuildingStore,
        itemKey: 'Market',
        as: Link,
        to: '/market',
        // overflowMode: 'always',
        // overflowStyle: { marginBlockStart: 'auto' },
        active: location.pathname === '/market',
      });
    }

    points.push({
      label: 'Aufgaben',
      customIcon: (
        <div className={styles.iconContainer}>
          {openTasks.length !== 0 && (
            <div className={styles.numberCounter}>{openTasks.length}</div>
          )}
          <SvgListCheck />
        </div>
      ),
      itemKey: 'Aufgaben',
      active: location.pathname === '/tasks',
      as: Link,
      to: '/tasks',
    });

    if (user.identStatus) {
      points.push(
        ...[
          {
            label: 'Aufträge',
            icon: SvgCash,
            itemKey: 'Aufträge',
            as: Link,
            to: '/orders',
            active: location.pathname.includes('/orders'),
          },
          {
            label: 'Postbox',
            customIcon: (
              <div className={styles.iconContainer}>
                {unreadMessagesAmount !== 0 && (
                  <div className={styles.numberCounter}>
                    {unreadMessagesAmount}
                  </div>
                )}
                <SvgMail />
              </div>
            ),
            itemKey: 'Postbox',
            as: Link,
            to: '/postbox',
            active: location.pathname.includes('/postbox'),
          },
        ],
      );
    }

    if (hasActiveBpsAndIps) {
      points.push({
        label: 'Gutscheinkarte',
        icon: SvgCreditCard,
        itemKey: 'Gutscheinkarte',
        as: Link,
        to: '/voucher-card',
        active: location.pathname.includes('/voucher-card'),
      });
    }

    if (subscriptionType === 'PREMIUM' || subscriptionType === 'PRIVAT') {
      points.push({
        label: 'Akademie',
        icon: SvgSchool,
        itemKey: 'Akademie',
        as: Link,
        to: '/academy',
        // overflowMode: 'always',
        // overflowStyle: { marginBlockStart: 'auto' },
        active: location.pathname.includes('/academy'),
      });
    }

    if (user.identStatus) {
      points.push({
        active: location.pathname.includes('/timeline'),
        as: Link,
        icon: SvgCalendarSearch,
        itemKey: 'Aktivitäten',
        label: 'Aktivitäten',
        to: '/timeline',
      });
    }

    points.push(
      ...[
        {
          label: 'Einstellungen',
          icon: SvgSettings,
          itemKey: 'Settings',
          as: Link,
          to: '/setup/profile',
          // overflowMode: 'always',
          overflowStyle: { marginBlockStart: 'auto' },
          active: location.pathname.includes('/setup'),
        },
      ],
    );

    return points;
  }, [
    user.loaded,
    user.identStatus,
    hasSomeActiveBP,
    hasSomeOpenIps,
    location.pathname,
    openTasks.length,
    subscriptionType,
    unreadMessagesAmount,
  ]);

  return (
    <div id="navigation" className={`${styles.navigation} webview-hidden`}>
      {isAuthenticated() && navPoints.length > 0 && (
        <NavigationComponent
          navItems={navPoints}
          logout={signOut}
          logoProps={{
            as: Link,
            to: '/',
          }}
          logoutClassName={!user.identStatus ? styles.logout : undefined}
          innerContainerClassName={
            !user.identStatus ? styles.innerContainer : undefined
          }
        />
      )}
    </div>
  );
};
