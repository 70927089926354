import { SavingsPlanInterval } from '@quirion/types';

export type SavingsPlanFormInput = {
  savingsPlanId: string;
  businessPartnerId: number;
  ipsId: number;
  startDate: string;
  endDate?: string;
  savingsPlanAmount: number;
  savingsPlanCounter: number;
  savingsPlanInterval: SavingsPlanInterval;
  savingsPlanSepaMandate: string;
  savingsPlanProceedReferenceAccount: boolean;
  savingsPlanProceedAccountHolder: string | undefined;
  savingsPlanProceedIban: string | undefined;
  savingsPlanProceedBic: string | undefined;
  savingsPlanProceedBankName: string | undefined;
};

export enum SavingsPlanFormFieldName {
  ipsId = 'ipsId',
  savingsPlanAmount = 'savingsPlanAmount',
  interval = 'interval',
  startMonth = 'startMonth',
  endMonth = 'endMonth',
  paymentMethod = 'paymentMethod',
  sepaSourceType = 'sepaSourceType',
  sepaFromAccount = 'sepaFromAccount',
  bankAccount = 'bankAccount',
  acceptMandate = 'acceptMandate',
}

export enum SavingsPlanFormIntent {
  create = 'create',
  update = 'update',
}

export enum SavingsPlanPaymentMethod {
  sepa = 'sepa',
  wire = 'wire',
}

export enum SavingsPlanSepaSourceType {
  referenceAccount = 'referenceAccount',
  other = 'other',
}

export enum SavingsPlanSepaAccount {
  newAccount = 'newAccount',
}

export enum SavingsPlanEnd {
  unbefristet = 'unbefristet',
}

export type SavingsPlanMonthOption = {
  value: SavingsPlanEnd | string;
  text: SavingsPlanEnd | string;
};
