import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgInvestmentSmartCitiesGradient = function (
  props: SVGProps<SVGSVGElement>,
) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill={`url(#${_id})`}
        d="M27.5 25.96h-.93V20.7l-1.95-2.39h-1.46v7.65h-.93V15.32h-3.7v10.64h-.93v-4.88l-1.35-1.26-1.35 1.26v4.88h-.93v-4.88l-1.35-1.26-1.35 1.26v4.88h-.93v-7.67c0-.33-.27-.6-.6-.6H7.53c-.33 0-.6.27-.6.6v7.67H6v-7.67c0-.65.41-1.21.99-1.43v-.82c0-.75.5-1.38 1.19-1.59v-1.22h.93v1.22c.69.2 1.19.84 1.19 1.59v.82c.58.22.99.78.99 1.43v1.52l1.35-1.26 1.81 1.69 1.81-1.69 1.35 1.26v-5.42h5.56v2.99h1.91l2.33 2.86.1.12v5.6Zm-19.58-9.2h1.44v-.72c0-.4-.32-.72-.72-.72s-.72.32-.72.72zm17.15 6.77h-.93v-.46h.93zm-4.23 0h-.93v-.46h.93zm-4.13 0h-.93v-.46h.93zm-3.62 0h-.93v-.46h.93zm11.98-1.63h-.93v-.46h.93zm-4.23 0h-.93v-.46h.93zm-4.13 0h-.93v-.46h.93zm-3.62 0h-.93v-.46h.93zm-3.98-.62h-.93v-.46h.93zm15.96-1.02h-.93v-.46h.93zm-4.23 0h-.93v-.46h.93zM9.1 19.64h-.93v-.46h.93zm11.74-1.02h-.93v-.46h.93zm0-1.68h-.93v-.46h.93zm-12.2-4.43a.57.57 0 1 1 0-1.14.57.57 0 0 1 0 1.14m0-.93c-.2 0-.35.16-.35.36s.16.35.35.35.35-.16.35-.35-.16-.36-.35-.36m.8-.51c-.48-.31-1.11-.31-1.59 0l-.5-.78c.78-.5 1.82-.5 2.6 0l-.5.78ZM6.32 9.65l-.5-.78c1.69-1.09 3.96-1.09 5.65 0l-.5.78c-1.39-.89-3.25-.89-4.65 0m5.96-1.64c-2.18-1.4-5.1-1.4-7.28 0l-.5-.78c2.48-1.6 5.8-1.6 8.28 0z"
      />
      <defs>
        <linearGradient
          id={_id}
          x1="0%"
          x2="50%"
          y1="50%"
          y2="0%"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939D4" />
          <stop offset={0.48} stopColor="#5D4DF8" />
          <stop offset={0.74} stopColor="#B459F9" />
          <stop offset={1} stopColor="#FE3B68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgInvestmentSmartCitiesGradient;
