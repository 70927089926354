export enum ErrorValidationKey {
  OneTimeDeposit = 'ONE_TIME_DEPOSIT',
  FreeAssets = 'FREE_ASSETS',
  MonthlyExpenses = 'MONTHLY_EXPENSES',
  MonthlyNetIncome = 'MONTHLY_NET_INCOME',
  MonthlySavings = 'MONTHLY_SAVINGS',
  SustainableDegreeSelection = 'SUSTAINABILITY_DEGREE_SELECTION',
  SustainabilityChoice = 'SUSTAINABILITY_CHOICE',
  /**
   * This only validates that sustainabiliy will not be selected
   */
  SustainabilityChoiceNotAvail = 'SUSTAINABILITY_CHOICE_NOT_AVAIL',
  SustainabilityDetermination = 'SUSTAINABILITY_DETERMINATION',
  EcologicalSelection = 'ECOLOGICAL_SELECTION',
  PaiSelection = 'PAI_SELECTION',
}

export enum WarningValidationKey {
  FreeAssets = 'FREE_ASSETS',
  Loans = 'LOANS',
}
