import { Payload } from '../types';

/** Options for {@link buildUrlForFetch} */
export type BuildUrlForFetchInput = {
  url: string;
  method: string;
  payload?: Payload;
};

/**
 * @returns URL with search params if payload exists and method is GET
 * @throws Error when payload contains non-strings.
 */
export const buildUrlForFetch = ({
  url,
  method,
  payload,
}: BuildUrlForFetchInput): string => {
  const newUrl = new URL(url);
  if (payload && (method === 'GET' || method === 'DELETE')) {
    Object.keys(payload).forEach((key) => {
      const value = payload[key];
      if (typeof value !== 'string') {
        throw new Error(
          `You can not pass parameters of type ${typeof value} as search params!`,
        );
      }
      newUrl.searchParams.set(key, value);
    });
  }
  return newUrl.href;
};
