export enum AccountType {
  /**
   * @deprecated joinedAccount is legacy only, don't use in future projects
   */
  ChildAccount = 'childAccount',
  SingleAccount = 'singleAccount',
  JointAccount = 'jointAccount',
  /**
   * @deprecated joinedAccount is legacy only, don't use in future projects
   */
  JoinedAccount = 'joinedAccount',
  ChildSingleAccount = 'childSingleAccount',
  ChildJointAccount = 'childJointAccount',
  /**
   * @deprecated childJoinedAccount is legacy only, don't use in future projects
   */
  ChildJoinedAccount = 'childJoinedAccount',
}
