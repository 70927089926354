import React, { ComponentProps, ElementType, PropsWithChildren } from 'react';

import { ClassStyle } from '../../types/ClassStyle.model';

import styles from './Stack.module.css';

export type StackOwnProps = PropsWithChildren<
  {
    /**
     * Defines the gap between the individual items.
     */
    gap?: string;
    /**
     * Justifies the items using flexbox' justify-items values.
     */
    justifyItems?: string;
    /**
     * Set all items to be 100% width
     */
    fullWidthItems?: boolean;
  } & ClassStyle
>;

const defaultElement = 'div';

export type StackProps<E extends ElementType = typeof defaultElement> = {
  as?: E;
} & StackOwnProps &
  Omit<ComponentProps<E>, keyof StackOwnProps>;

export const Stack = <E extends ElementType = typeof defaultElement>({
  as,
  children,
  gap = 'var(--space-4)',
  justifyItems = 'start',
  fullWidthItems = false,
  className = '',
  style,
  ...rest
}: StackProps<E>) => {
  const Element: ElementType = as || defaultElement;
  return (
    <Element
      {...rest}
      className={[className, styles.stack].join(' ')}
      data-full-width-items={fullWidthItems}
      style={{
        ...style,
        '--justify-items': justifyItems,
        '--gap': gap,
      }}
    >
      {children}
    </Element>
  );
};
