import { PropsWithChildren, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { isAuthenticated, refreshTokens } from '@quirion/api';

export const RestrictedAccess = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const userIsAuthenticated = isAuthenticated();

  useEffect(() => {
    if (userIsAuthenticated)
      refreshTokens().catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        document.location.href = '/logout/inactivity'; // not using `navigate` to clear local state
      });
  }, [userIsAuthenticated]);

  if (!children || !userIsAuthenticated) {
    navigate('/login/');
    return null;
  }

  return children as JSX.Element;
};
