import { ParsedRefreshToken } from '@quirion/types';

export function parseRefreshToken(
  token: string | undefined,
): ParsedRefreshToken | undefined {
  const split = token?.split('.');
  return split
    ? [JSON.parse(atob(split[0])), split[1], split[2], split[3], split[4]]
    : undefined;
}
