import React, { ComponentProps, ElementType } from 'react';

import { ClassStyle } from '../../types/ClassStyle.model';

import styles from './TextLink.module.css';

export type TextLinkOwnProps = {
  variant?: 'primary' | 'inherit';
} & ClassStyle;

const defaultElement = 'a';

export type TextLinkProps<E extends ElementType = typeof defaultElement> = {
  as?: E;
} & TextLinkOwnProps &
  Omit<ComponentProps<E>, keyof TextLinkOwnProps>;

export const TextLink = <E extends ElementType = typeof defaultElement>({
  as,
  style,
  className = '',
  variant = 'primary',
  ...rest
}: TextLinkProps<E>) => {
  const Element: ElementType = as || defaultElement;
  return (
    <Element
      className={[styles.container, className].join(' | ')}
      style={style}
      data-variant={variant}
      {...rest}
    />
  );
};
