/*
 * Only does shallow merging for the moment. Will expand if necessary or new API becomes stable.
 */
export const mergeWithDefault = <T>(
  newOptions: Partial<T>,
  defaultOptions: T,
): T => ({
  ...defaultOptions,
  ...newOptions,
});
