export enum AuthMethod {
  Cookie = 'COOKIE',
  Local = 'LOCAL_STORAGE',
}

/**
 * @see https://docs.amplify.aws/lib/auth/mfa/q/platform/js/#sign-in-with-custom-auth-challenges
 */

export enum AuthChallengeName {
  MfaSetup = 'MFA_SETUP',
  NewPasswordRequired = 'NEW_PASSWORD_REQUIRED',
  SelectMfaType = 'SELECT_MFA_TYPE',
  SmsMfa = 'SMS_MFA',
  SoftwareToken = 'SOFTWARE_TOKEN_MFA',
}

export type AuthAdminMfaChoice = 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA';

export type AuthChallengeParameters = {
  // NEW_PASSWORD_REQUIRED
  USER_ID_FOR_SRP?: string;
  requiredAttributes?: string;
  userAttributes?: string;

  // MFA_SETUP
  allowedMethods?: string[];

  // SMS_MFA
  attributeName?: 'phone_number';
  deliveryMedium?: 'SMS';
  destination?: string;
  CODE_DELIVERY_DESTINATION?: string; // still used in 'challenge' endpoint

  // SOFTWARE_TOKEN_MFA
  friendlyDeviceName?: string;
};

export type AuthConfig = {
  authKey?: string;
  challengeKey?: string;
  method?: AuthMethod;
  eventName?: string;
  navigation?: AuthNavItem[];
};

export type AuthParams = {
  accessToken?: string;
  expiresIn?: number;
  idToken?: string;
  refreshToken?: string;
  tokenType?: string;
  twoFactorType?: string;
};

export type AuthChallenge = {
  authenticationResult?: AuthParams;
  challengeName?: AuthChallengeName;
  challengeParameters?: AuthChallengeParameters;
  expiresIn?: number;
  session?: string;
};

export type ParsedAuth = {
  accessToken?: ParsedAccessToken;
  expiresIn?: number;
  idToken?: ParsedIdToken;
  refreshToken?: ParsedRefreshToken;
  tokenType?: string;
  twoFactorType?: string;
};

export type ParsedAccessToken = [
  {
    alg: 'RS256';
    kid: string;
  },
  {
    auth_time: number;
    client_id: string;
    'cognito:groups'?: string[];
    event_id: string;
    exp: number;
    iat: number;
    iss: string;
    jti: string;
    origin_jti?: string;
    scope: string;
    sub: string;
    token_use: string;
    username: string;
  },
  string,
];

export type ParsedIdToken = [
  {
    alg: 'RS256';
    kid: string;
  },
  {
    aud: string;
    auth_time: number;
    call_id?: string;
    'cognito:groups'?: string[];
    'cognito:username': string;
    email?: string;
    email_verified?: boolean;
    event_id: string;
    exp: number;
    iat: number;
    iss: string;
    jti?: string;
    origin_jti?: string;
    preferred_username?: string;
    sub: string;
    token_use: string;
  },
  string,
];

export type ParsedRefreshToken = [
  {
    alg: string;
    cty: string;
    enc: string;
  },
  string,
  string,
  string,
  string,
];

export type AuthNavSubItem = {
  id: string;
  label: string;
  path: string;
  hidden?: boolean;
  allowed?: string | string[];
  required?: string | string[];
};

export type AuthNavItem = {
  id: string;
  label: string;
  path: string;
  hidden?: boolean;
  allowed?: string | string[];
  required?: string | string[];
  sub?: AuthNavSubItem[];
};

/*
  AuthParamsCompat & AuthChallengeCompat include both property name casing
  styles currently in use:
  - PascalCase is used in Banking (legacy authentication)
  - camelCase is used in Admin-Tool (authentication with MFA)
*/

export type AuthParamsCompat = {
  AccessToken?: string; // deprecated
  accessToken?: string;
  ExpiresIn?: number; // deprecated
  expiresIn?: number;
  TokenType?: string; // deprecated
  tokenType?: string;
  RefreshToken?: string; // deprecated
  refreshToken?: string;
  IdToken?: string; // deprecated
  idToken?: string;
};

export type AuthChallengeCompat = {
  ChallengeName?: AuthChallengeName; // deprecated
  challengeName?: AuthChallengeName;
  ChallengeParameters?: AuthChallengeParameters; // deprecated
  challengeParameters?: AuthChallengeParameters;
  AuthenticationResult?: AuthParams; // deprecated
  authenticationResult?: AuthParams;
  Session?: string; // deprecated
  session?: string;
  ExpiresIn?: number; // deprecated, maybe not in use
  expiresIn?: number;
};

/**
 * Types of Multi Factor Authentication
 */
export enum AuthMFAMethod {
  SMS = 'SMS',
}

export type MfaCodeDeliveryDetails = {
  attributeName: string;
  deliveryMedium: string;
  destination: string;
};
