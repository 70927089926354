/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useState } from 'react';
import type { CSSProperties, FC, MouseEvent } from 'react';

import { useNavigate } from 'react-router-dom';

import { readCMSProxy } from '@quirion/api';
import type { DynamicHintSlot } from '@quirion/api';
import type { WebflowDashboardGlobalsResponse } from '@quirion/api/types';
import { SvgX } from '@quirion/assets';
import { Button, Hint, Icon, Stack } from '@quirion/components';
import { DYNAMIC_HINT_SEPARATOR, StorageKey } from '@quirion/utils';

import { isProd } from 'lib/env';

import styles from './DynamicHint.module.css';

const COLLECTION_ID = isProd()
  ? '62c1ee0df970984cf4f919c9'
  : '628b7c95210e292a619c5f1b';

type DynamicHintType = Partial<WebflowDashboardGlobalsResponse> & {
  /**
   * will be set when user dismisses the hint
   */
  hidden: boolean;
};

function isHintHidden(id: DynamicHintType['_id']) {
  const sessionHints: DynamicHintType[] = JSON.parse(
    sessionStorage.getItem(StorageKey.DynamicHints) || '[]',
  );
  return !!sessionHints.find((hint) => hint.hidden && hint._id === id);
}

function filterHints(
  hints: DynamicHintType[],
  slot: DynamicHintSlot,
  filters?: string,
) {
  const filtersArray = filters?.split(DYNAMIC_HINT_SEPARATOR);
  return hints.filter((hint) => {
    if (
      hint.slot === slot ||
      (hint.slot?.includes(`${slot}${DYNAMIC_HINT_SEPARATOR}`) &&
        !isHintHidden(hint._id))
    ) {
      const conditions = hint?.conditions?.split(DYNAMIC_HINT_SEPARATOR);
      if (!conditions) return true;
      return conditions.every((condition) => filtersArray?.includes(condition));
    }
    return false;
  });
}

export type DynamicHintProps = {
  className?: string;
  /**
   *  Filters are constructed as key:VALUES;
   *  Their purpose is to filter hints from Webflow's Dashboard globals collection.
   *  @example `category:VV.CI;status:CLOSED;`
   */
  filters?: string;
  /**
   *  Descriptive name of DynamicHint placement.
   *  Has to be defined in CAPITALS SEPARATED BY UNDERSCORES.
   *  @example 'IPS' 'DASHBOARD' 'DASHBOARD_SIDE, see Slot definition in @quirion/api
   */
  slot: DynamicHintSlot;
  style?: CSSProperties;
};

/**
 * @param className
 * @param slot
 * @param filters
 * @returns A Hint component which is populated with hints from Webflow's Dashboard globals
 * collection.
 */
export const DynamicHint: FC<DynamicHintProps> = ({
  className,
  slot,
  filters,
  style,
}) => {
  const navigate = useNavigate();
  const [hints, setHints] = useState<DynamicHintType[]>([]);

  const loadHints = useCallback(() => {
    const savedHints = sessionStorage.getItem(StorageKey.DynamicHints);
    if (
      savedHints?.length &&
      savedHints?.length > 2 // length of stringified empty array '[]'
    ) {
      const filteredHints = filterHints(JSON.parse(savedHints), slot, filters);
      setHints(filteredHints);
      return;
    }
    readCMSProxy<WebflowDashboardGlobalsResponse>({
      payload: { collectionId: COLLECTION_ID },
    })
      .then((response) => {
        if (response?.items?.length > 0) {
          const activeHints = response?.items.filter((hint) => hint.active);
          const hintsToSave: DynamicHintType[] = activeHints.map((hint) => ({
            _id: hint._id,
            'link-text': hint['link-text'],
            'link-url': hint['link-url'],
            active: hint.active,
            conditions: hint.conditions,
            description: hint.description,
            dismissible: hint.dismissible,
            hidden: false,
            name: hint.name,
            slot: hint.slot,
            variant: hint.variant,
          }));
          const filteredHints = filterHints(hintsToSave, slot, filters);
          setHints(filteredHints);
          sessionStorage.setItem(
            StorageKey.DynamicHints,
            JSON.stringify(hintsToSave),
          );
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [filters, slot]);

  const saveHintAsHidden = (id: DynamicHintType['_id']) => {
    const sessionHints: DynamicHintType[] = JSON.parse(
      sessionStorage.getItem(StorageKey.DynamicHints) || '[]',
    );
    const hintToSave = sessionHints.find((hint) => hint._id === id);
    if (hintToSave?.hidden === false) {
      hintToSave.hidden = true;
      sessionStorage.setItem(
        StorageKey.DynamicHints,
        JSON.stringify(sessionHints),
      );
    }
    const filtered = hints?.filter((hint) => hint._id !== id);
    setHints(filtered);
  };

  useEffect(() => {
    loadHints();
  }, [loadHints]);

  const closeHint = (
    e: MouseEvent<HTMLElement>,
    id: DynamicHintType['_id'],
  ) => {
    e.preventDefault();
    saveHintAsHidden(id);
    const filtered = hints?.filter((m) => m._id !== id);
    setHints(filtered);
  };

  return (
    hints?.length > 0 && (
      <Stack justifyItems="stretch" className={className}>
        {hints.map((hint) => {
          if (hint.hidden) return null;
          const id = hint._id;
          const linkUrl = hint['link-url'];
          return (
            <Hint
              className={styles.DynamicHint}
              variant={hint.variant || 'default'}
              style={style}
              key={`key_${id}`}
            >
              <div className={styles.content}>
                <p className={styles.text}>
                  <b>{hint.name}</b>
                  {hint.description}
                  {!!linkUrl && (
                    <Button
                      className={styles.button}
                      size="sm"
                      variant="outlined"
                      onClick={(e) => {
                        e.preventDefault();
                        if (linkUrl.startsWith('/')) {
                          // internal link, use `react-router`
                          navigate(linkUrl);
                        } else {
                          // external link, open in new window
                          window.open(`${linkUrl}`);
                        }
                      }}
                    >
                      {hint['link-text']}
                    </Button>
                  )}
                </p>
                {hint?.dismissible && (
                  <Button
                    className={styles.dismiss}
                    variant="secondary"
                    onClick={(e) => closeHint(e, id)}
                  >
                    <Icon name={SvgX} size="var(--sizes-4)" />
                  </Button>
                )}
              </div>
            </Hint>
          );
        })}
      </Stack>
    )
  );
};
