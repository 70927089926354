import React, { CSSProperties } from 'react';

import {
  SvgAlertCircle,
  SvgCircleCheck,
  SvgCircleNotch,
  SvgCircleX,
} from '@quirion/assets';

import { ReactRouterLocation } from '../../types/ReactRouter.model';
import { Icon } from '../Icon';

import styles from './Button.module.css';

/** Variants */
export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'outlined'
  | 'outlined-inverted'
  | 'light'
  | 'text'
  | 'text-inverted'
  | 'monotone'
  | 'inverted'
  | 'destructive'
  | 'gradient'
  | 'gradient-inverted';

/** Sizes */
export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg';

export type ButtonRadius = 'xs' | 'round';

export type StatusIconType = 'success' | 'warning' | 'error' | 'loading';
type StatusIconMap = { [B in StatusIconType]: React.ComponentType };
/** Statuses Icon Map */
const statusesIconMap: StatusIconMap = {
  success: SvgCircleCheck,
  warning: SvgAlertCircle,
  error: SvgCircleX,
  loading: SvgCircleNotch,
};

/** Types */
type ButtonType = 'button' | 'submit' | 'reset';

/** Link Targets */
type LinkTarget = '_blank' | '_self' | '_parent' | '_top';

/**
 * Determines if the Button's only child is an icon to apply according styles if true.
 */
const hasIcon = (children: React.ReactNode): boolean => {
  // Workaround: Using the code snippet below causes bug with addon-controls.
  // See Bug Issue: https://github.com/storybookjs/storybook/issues/12401
  // const hasIcon = children.type?.name === 'Icon';

  if (React.isValidElement(children)) {
    if (children?.type === undefined) {
      return false;
    }

    const compareType = (<Icon name={() => <div />} />).type.name;

    if (typeof children.type === 'string') {
      return compareType === children.type;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - TypeScript doesn't know about `name` property
    return children.type.name === compareType;
  }

  return false;
};

export type ButtonProps = {
  /**
   * Use the disabled attribute to make the example button unusable but still visible.
   */
  disabled?: boolean;
  /**
   * Modifiers represent different style modifications of the example button.
   * The style modifier chosen depends on the situation (e.g. warning button) it is used in or
   * the role it plays in the fe-ui (e.g. "primary" for most important button).
   */
  variant?: ButtonVariant;
  /**
   * Shows the status of the button.
   */
  status?: StatusIconType;
  /**
   * Size of the Button
   */
  size?: ButtonSize;
  /**
   * Set button radius explicitly
   */
  radius?: ButtonRadius;
  /**
   * Takes any function and executes it on click
   */
  onClick?(e: React.MouseEvent<HTMLButtonElement>): void;
  /**
   * Takes any function and executes it on mouseover
   */
  onMouseOver?(e: React.MouseEvent<HTMLButtonElement>): void;
  /**
   * Takes any function and executes it on mouseout
   */
  onMouseLeave?(e: React.MouseEvent<HTMLButtonElement>): void;
  /**
   * Used to provide an accessible label to the `<Button>` component.
   * It is required when an icon is used as the button's children.
   * See https://www.w3.org/TR/wai-aria/#aria-label
   */
  ariaLabel?: string;
  /**
   * Describes the type (purpose) of button.
   */
  type?: ButtonType;
  /**
   * When used with React Router, the `to` props takes the Link location.
   * See https://reactrouter.com/web/api/Link
   */
  to?:
    | string
    | ReactRouterLocation
    | ((location: ReactRouterLocation) => string | ReactRouterLocation);
  /**
   * Overwrite element with custom tag.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  as?: React.ElementType<any>;
  /**
   * Holds the link location of an `<a>` element.
   * Thus, the `component` needs to hold the value `a`.
   */
  href?: string;
  /**
   * Specifies where to open the linked document.
   */
  target?: LinkTarget;
  /**
   * Specifies the tab order of an element.
   */
  tabIndex?: number;
  /**
   * Sets the inline-size (width) of the button to 100%.
   */
  fullWidth?: boolean;
  /**
   * CSS property sets the flex grow factor of a flex item's main size.
   * See https://developer.mozilla.org/en-US/docs/Web/CSS/flex-grow
   */
  flexGrow?: number;
  justifyContent?: string;
  className?: string;
  style?: CSSProperties;
  tooltipid?: string;
  // Will be unnecessary soon.
  id?: string;
  children: React.ReactNode;
  hidden?: boolean;
};

export const Button = React.forwardRef<HTMLElement, ButtonProps>(
  (
    {
      disabled = false,
      variant = 'primary',
      status,
      size = 'md',
      radius,
      children,
      onClick,
      onMouseOver,
      onMouseLeave,
      ariaLabel = '',
      as,
      to,
      href,
      target,
      tabIndex,
      type = 'button',
      fullWidth = false,
      flexGrow = 0,
      style,
      className = '',
      tooltipid,
      id,
      ...rest
    }: ButtonProps,
    ref,
  ) => {
    const buttonStatus = status;
    const isSubmitButton = type === 'submit';

    const Tag = as ?? (href ? 'a' : undefined) ?? 'button';

    if (href && !as) {
      // eslint-disable-next-line no-console
      console.warn(
        "DEPRECATION WARNING: Using href without explicitly setting as to 'a' will be deprecated soon. Please properly define your tag, if you want it changed.",
      );
    }

    const hasStatus = !!buttonStatus && (isSubmitButton || !!status);
    const buttonRadius = radius || (hasIcon(children) ? 'round' : 'xs');

    return (
      <Tag
        id={id}
        role="button"
        type={type}
        disabled={disabled || buttonStatus === 'loading'}
        data-variant={variant}
        data-size={size}
        // change this later
        data-radius={buttonRadius}
        data-full-width={fullWidth}
        data-flex-grow={flexGrow}
        data-has-icon={hasIcon(children)}
        data-is-loading={buttonStatus === 'loading'}
        data-has-status={hasStatus}
        data-status={buttonStatus}
        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
          if (disabled || buttonStatus === 'loading') {
            e.preventDefault();
            e.stopPropagation();
          } else if (onClick) {
            onClick(e);
          }
        }}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        aria-label={
          ariaLabel || (typeof children === 'string' ? children : ariaLabel)
        }
        aria-busy={buttonStatus === 'loading'}
        to={to}
        href={href}
        target={target}
        style={style}
        className={[className, styles.button].join(' ')}
        tooltipid={tooltipid}
        ref={ref}
        tabIndex={tabIndex}
        {...rest}
      >
        {children}
        {hasStatus && (
          <div
            className={styles.status}
            data-variant={buttonStatus}
            role="status"
          >
            <Icon
              name={statusesIconMap[buttonStatus]}
              size="28px"
              animation={buttonStatus === 'loading' ? 'spinning' : undefined}
            />
          </div>
        )}
      </Tag>
    );
  },
);
