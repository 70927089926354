import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgInvestmentBatterienGradient = function (
  props: SVGProps<SVGSVGElement>,
) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill={`url(#${_id})`}
        d="M17.71 27.5H7.7c-.96 0-1.75-.78-1.75-1.75V9.22c0-.95.77-1.73 1.73-1.73h1.38c.1 0 .19-.09.19-.19V5.95c0-.8.65-1.44 1.44-1.44h4.02c.8 0 1.44.65 1.44 1.44V7.3c0 .1.09.19.19.19h1.38c.95 0 1.73.77 1.73 1.73V11h-.92V9.22c0-.45-.36-.81-.81-.81h-1.38c-.61 0-1.11-.5-1.11-1.11V5.95c0-.29-.24-.52-.52-.52h-4.02c-.29 0-.52.24-.52.52V7.3c0 .61-.5 1.11-1.11 1.11H7.68c-.44 0-.81.36-.81.81v16.53c0 .46.37.83.83.83h10.01c.46 0 .83-.37.83-.83v-.99h.92v.99c0 .96-.78 1.75-1.75 1.75m1.18-3.37c-2.4 0-4.61-1.41-5.62-3.59l.83-.39c.86 1.86 2.74 3.06 4.79 3.06s3.86-1.14 4.75-2.97l.83.4a6.16 6.16 0 0 1-5.58 3.49m-.51-3.38-.73-.56 1.29-1.69h-1.92l2.48-3.24.73.56-1.35 1.76h1.92l-2.43 3.17Zm6.11-.96-1.34-1.57.7-.6.74.87.87-.74.6.7zm-11.89-.11-.71-.58 1.31-1.59 1.59 1.31-.58.71-.88-.72-.72.88Zm11.49-2.7c-.45-2.51-2.64-4.33-5.2-4.33s-4.6 1.69-5.15 4.1l-.9-.2c.65-2.84 3.13-4.82 6.04-4.82s5.57 2.14 6.1 5.09l-.91.16Zm-9.7-8.57H11v-.92h3.39z"
      />
      <defs>
        <linearGradient
          id={_id}
          x1="0%"
          x2="50%"
          y1="50%"
          y2="0%"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939D4" />
          <stop offset={0.48} stopColor="#5D4DF8" />
          <stop offset={0.74} stopColor="#B459F9" />
          <stop offset={1} stopColor="#FE3B68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgInvestmentBatterienGradient;
