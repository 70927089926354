import { Payload } from '../types';

/**
 * Function to replace query parameter placeholders with actual values
 * @param url - URL of the API endpoint
 * @param payload - Objects with keys matching bracket surrounded variable in the url
 * @returns String with correct query paramters
 *
 * @example
 * ```js
 * replaceUrlPlaceholders(
 *   'https://y6dmkcrmab.execute-api.eu-central-1.amazonaws.com/conditions/{id}',
 *   {
 *     id: 'Q_VV.NH_SA_S'
 *   },
 * )
 *
 * // becomes https://y6dmkcrmab.execute-api.eu-central-1.amazonaws.com/conditions/Q_VV.NH_SA_S
 * ```
 */
export const replaceUrlPlaceholders = (
  url: string,
  payload: Payload,
): string => {
  let finalUrl: string = url;
  Object.keys(payload).forEach((key) => {
    finalUrl = finalUrl.replace(`{${key}}`, `${payload[key]}`);
  });
  return finalUrl;
};
