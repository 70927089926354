import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgIpsThThin = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill={`url(#${_id})`}
        fillRule="nonzero"
        d="m8.34 20.03-.01.01-1.05 1.05a.35.35 0 0 1-.22.08c-.18 0-.33-.15-.33-.32 0-.08.03-.15.08-.21l1.06-1.06c.07-.07.15-.11.24-.11.18 0 .32.15.32.32 0 .09-.04.18-.1.24zM6.39 18.1l-2.47 2.47a.3.3 0 0 1-.22.09c-.18 0-.33-.15-.33-.32 0-.08.03-.16.09-.22l2.47-2.47a.3.3 0 0 1 .22-.09c.18 0 .33.15.33.32 0 .08-.03.16-.09.22m8.13-1.41-.17.17c-.25.25-.59.39-.94.39s-.69-.14-.94-.39l-.89-.89-1.07 1.88a.33.33 0 0 1-.51.07l-3.89-3.89a.32.32 0 0 1-.09-.23c0-.12.06-.22.16-.28l1.88-1.07-.89-.89a1.327 1.327 0 0 1-.02-1.86l.19-.19.33-.33-1.91-1.87a.32.32 0 0 1 0-.46L7 5.6c1.07-1.08 2.8-1.2 4.01-.29l.29.22 1.26-1.25a6.35 6.35 0 0 1 6.35-1.57l.66.21c.73.23 1.3.8 1.53 1.53l.09.3.11.37c.7 2.24.09 4.69-1.57 6.35l-1.26 1.26.22.29c.91 1.22.79 2.94-.29 4.01l-1.24 1.24a.32.32 0 0 1-.46 0l-1.87-1.91-.32.32zm3.81-3.06-.28-.47-2.77 2.77 1.66 1.66 1.01-1.01c.8-.8.92-2.02.37-2.94zM4.44 15.69c.13.13.13.33 0 .46l-1.06 1.06a.31.31 0 0 1-.24.1c-.18 0-.33-.15-.33-.32 0-.09.04-.17.1-.23l1.07-1.07c.06-.06.14-.1.23-.1s.17.03.23.1m5.71 1.46.95-1.66-2.58-2.57-1.66.95 3.29 3.29zm9.1-13.65.13.04-.66-.21a5.7 5.7 0 0 0-5.69 1.41l-5.4 5.4c-.26.26-.26.69 0 .95l5.31 5.31c.26.26.69.26.95 0l5.4-5.4a5.7 5.7 0 0 0 1.41-5.7l-.21-.66a1.66 1.66 0 0 0-1.1-1.1l-.13-.04zm-5.89 4.23c.39-.39.92-.61 1.47-.61 1.14 0 2.08.94 2.08 2.07a2.08 2.08 0 0 1-2.08 2.08c-1.14 0-2.08-.94-2.08-2.08 0-.55.22-1.08.61-1.47zm2.48.47s-.2-.18-.31-.24a1.45 1.45 0 0 0-.71-.19c-.78 0-1.43.64-1.43 1.43 0 .38.15.74.42 1.01.27.26.62.4.99.4a1.428 1.428 0 0 0 1.02-2.42zM7.46 6.06 6.45 7.08l1.66 1.66 2.77-2.77-.47-.28c-.95-.56-2.17-.41-2.94.37zM21.6 4.62l-.09.12.09-.13z"
      />
      <defs>
        <linearGradient
          id={_id}
          x1={2.4}
          x2={25.69}
          y1={21.62}
          y2={8.52}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939d4" />
          <stop offset={0.48} stopColor="#5d4df8" />
          <stop offset={0.74} stopColor="#b459f9" />
          <stop offset={1} stopColor="#fe3b68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgIpsThThin;
