import type { SVGProps } from 'react';
const SvgCircleNotch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={1.5}
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path d="M15 3.513c3.494 1.236 6 4.571 6 8.487 0 4.967-4.033 9-9 9s-9-4.033-9-9c0-3.913 2.503-7.246 5.993-8.485" />
  </svg>
);
export default SvgCircleNotch;
