import { FC, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { useParams } from 'react-router-dom';

import { getCostSheet, getStrategy } from '@quirion/api';
import { DetailList, Heading, Stack } from '@quirion/components';
import {
  Condition,
  CostSheet,
  IPS,
  Strategy,
  WphgDetailsGeneric,
} from '@quirion/types';
import {
  formatAsCurrency,
  getAssetsByScenarios,
  WPHG_HOLDING_PERIOD_TO_CALC_VAL_MAP,
  WPHG_HOLDING_PERIOD_TO_LABEL_MAP,
  WPHG_MAP,
} from '@quirion/utils';

dayjs.locale('de');

function humanizeDateDiff(dateString1: string, dateString2?: string): string {
  const humanized: string[] = [];
  const date1 = dayjs(dateString1);
  const date2 = dayjs(dateString2);
  const years = date2.diff(date1, 'year');
  const months = date2.diff(date1, 'month') % 12;
  if (years > 0) {
    humanized.push(String(years), ' Jahr');
    if (years > 1) humanized.push('e');
    if (months > 0) humanized.push(' ');
  }
  if (months > 0) {
    humanized.push(String(months), ' Monat');
    if (months > 1) humanized.push('e');
  }
  if (!(years > 0) && !(months > 0)) return 'unter 1 Monat';
  return humanized.join('');
}

export type RetentionBoxProps = {
  allIps: Record<string, IPS>;
  conditions: Condition[];
};

export const RetentionBox: FC<RetentionBoxProps> = (props) => {
  const { ipsId } = useParams<{ ipsId: string }>();
  const [costSheet, setCostSheet] = useState<CostSheet>();
  const [strategyObj, setStrategyObj] = useState<Strategy>();
  const { allIps, conditions } = props;
  const ips = allIps?.[ipsId ?? 0];
  const { wphg, conditionId, strategy } = ips;
  const wphgData = wphg as WphgDetailsGeneric;
  const absoluteReturnOnInvestment = ips?.returnOnInvestment?.absolute ?? 0;

  useEffect(() => {
    if (
      conditionId &&
      strategy &&
      wphgData?.initial_amount &&
      wphgData?.savingplan_rate
    ) {
      getCostSheet({
        payload: {
          conditionId,
          initialAmount: Number(wphgData?.initial_amount),
          savingsPlanAmount: Number(wphgData?.savingplan_rate),
          strategy,
        },
      }).then((res) => setCostSheet(res));
    }
  }, [
    conditionId,
    setCostSheet,
    strategy,
    wphgData?.initial_amount,
    wphgData?.savingplan_rate,
  ]);

  useEffect(() => {
    if (conditionId && strategy) {
      getStrategy({
        payload: {
          conditionId,
          strategy,
        },
      }).then((res) => setStrategyObj(res));
    }
  }, [conditionId, strategy]);

  const currentCondition = conditions?.find(
    (condition) => condition.conditionId === ips.conditionId,
  );

  const { wphg_holding_period: wphgHoldingPeriod } = wphgData ?? {
    wphg_holding_period:
      WPHG_MAP.INVESTMENT_PERIOD__GENERAL.options.FOUR_TO_FIVE.name,
  };

  const allowance = currentCondition?.allowance;
  const feeQuirion = currentCondition?.feeQuirion;
  const category = ips?.category;
  const amountOfYears =
    WPHG_HOLDING_PERIOD_TO_CALC_VAL_MAP?.[wphgHoldingPeriod];
  const strategyYield = strategyObj?.yield;
  const vola = strategyObj?.vola;

  if (
    !ips ||
    !costSheet ||
    !strategyObj ||
    !wphgData ||
    !category ||
    conditions.length === 0 ||
    !wphgHoldingPeriod ||
    allowance === undefined ||
    feeQuirion === undefined ||
    amountOfYears === undefined ||
    strategyYield === undefined ||
    vola === undefined
  )
    return null;

  const assetScenario = getAssetsByScenarios({
    category,
    allowance,
    feeQuirion,
    gesamtKostenProzent: costSheet?.gesamtKostenProzent,
    fondsKostenProzent: costSheet?.fondsKostenProzent,
    strategyYield,
    vola,
    monthlySavings: Number(wphgData.savingplan_rate),
    oneTimeDeposit: Number(wphgData.initial_amount),
    amountOfYears,
  });

  const expectedReturnOnInvestment =
    assetScenario.expected - assetScenario.payed;

  if (
    expectedReturnOnInvestment < absoluteReturnOnInvestment &&
    dayjs(ips.createdAt).add(amountOfYears, 'year').isBefore(dayjs())
  )
    return null;

  return (
    <Stack>
      <Heading variant="h4" tag="h3">
        Persönliche Anlagedauer
      </Heading>
      <p>
        Zum Erreichen eines Anlageziels ist die Anlagedauer neben der Höhe des
        angelegten Betrags ein sehr wichtiger Faktor. Bitte überlegen Sie
        einfach nochmal, ob es nicht besser ist, Ihre Investition komplett
        beizubehalten.
      </p>
      <DetailList
        entries={[
          {
            key: 'Geplante Anlagedauer',
            value: WPHG_HOLDING_PERIOD_TO_LABEL_MAP[wphgHoldingPeriod] ?? 'n/a',
          },
          {
            key: 'Anlagedauer seit Start',
            value: humanizeDateDiff(ips.createdAt),
          },
          {
            key: 'Wertentwicklung seit Start',
            value: formatAsCurrency(absoluteReturnOnInvestment),
          },
          {
            key: 'Erwartete Wertentwicklung',
            value: formatAsCurrency(expectedReturnOnInvestment),
          },
        ]}
      />
    </Stack>
  );
};
