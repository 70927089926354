import { APICallInputWithPayload, ItemStatus } from '../types';
import { extendedFetch } from '../utils/extendedFetch';
import { getEndpointsDefinition } from '../utils/getEndpointsDefinition';
import { ReadOrderPayload, ReadOrderResponse } from './readOrder.types';

const PAYLOAD_DEFAULTS = {
  businessPartner: false,
  ips: false,
  status: ItemStatus.All,
  asset: false,
};

export const readOrder = ({
  endpointsDefinition = getEndpointsDefinition(),
  payload,
}: APICallInputWithPayload<ReadOrderPayload>): Promise<ReadOrderResponse> =>
  extendedFetch({
    endpointConfig: endpointsDefinition.endpoints.getOrder,
    payload: {
      ...PAYLOAD_DEFAULTS,
      ...payload,
    },
  }).then((res) => res.json());
