import { useEffect, useState } from 'react';

export type AnimationPlayState = 'initial' | 'animateIn' | 'animateOut';

/**
 * Thin wrapper to handle the three animation play states
 *
 * @see AnimationPlayState
 */
export const useAnimationPlayState = () => {
  const [animationState, setAnimationState] =
    useState<AnimationPlayState>('initial');

  const animate = (state: AnimationPlayState) => {
    setAnimationState(state);
  };

  return {
    animationState,
    animate,
  };
};

/**
 * Keeps track of your animation state between visibilty.
 * Prevents unnecessary updates and has initial state, so you can prevent an
 * unwantend initial transition.

 */
export const useHandleAnimationOnVisibilityChange = (isVisible: boolean) => {
  const [animationState, setAnimationState] =
    useState<AnimationPlayState>('initial');
  const [lastState, setLastState] = useState(isVisible);

  useEffect(() => {
    if (isVisible === lastState) {
      return;
    }

    if (isVisible) {
      setAnimationState('animateIn');
    }

    if (!isVisible && lastState) {
      setAnimationState('animateOut');
    }
    setLastState(isVisible);
  }, [isVisible, setLastState, lastState]);

  return {
    animationState,
  };
};

export const appendStyle = (element: HTMLElement, style: string) => {
  const currentStyle = element.getAttribute('style');
  element.setAttribute('style', `${currentStyle}; ${style}`);
};

export const useCallbackOnVisibilityChange = (
  element: HTMLElement | null,
  isVisible: boolean,
  callbackOut: () => void,
  callbackIn: () => void,
) => {
  const [initialised, setInitialised] = useState(false);
  const [lastState, setLastState] = useState(false);

  return useEffect(() => {
    if (!element) {
      return;
    }
    if (!initialised) {
      callbackOut();
      setInitialised(true);
      return;
    }

    if (isVisible) {
      callbackIn();
    } else if (lastState) {
      element.addEventListener(
        'transitionend',
        () => {
          callbackOut();
        },
        { once: true },
      );
    }

    setLastState(isVisible);
  }, [isVisible, element, initialised, lastState, callbackIn, callbackOut]);
};

export const hideElement = (element: HTMLElement | null) => {
  if (!element) {
    return;
  }
  element.setAttribute('hidden', 'true');
  // eslint-disable-next-line no-param-reassign
  element.style.pointerEvents = 'none';
};

export const showElement = (element: HTMLElement | null) => {
  if (!element) {
    return;
  }
  element.removeAttribute('hidden');
  // eslint-disable-next-line no-param-reassign
  element.style.pointerEvents = 'all';
};

export const useHidingElementWithAnimation = (
  element: HTMLElement | null,
  isVisible: boolean,
) =>
  useCallbackOnVisibilityChange(
    element,
    isVisible,
    () => hideElement(element),
    () => showElement(element),
  );
