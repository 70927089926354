import { number } from 'yup';

import { WarningValidationKey } from '@quirion/types';

import { ERROR_VALIDATION_MESSAGES } from './errorValidationMessages';
import {
  ValidationProps,
  WarningValidationBuilderMap,
} from './validation.types';
import { WARNING_VALIDATION_MESSAGES } from './warningsValidationMessages';

// Map of functions that build warning validation schemas for defined keys
export const WARNING_VALIDATION_BUILDERS: WarningValidationBuilderMap = {
  [WarningValidationKey.FreeAssets]: ({ sessionAnswers }: ValidationProps) => {
    if (sessionAnswers.INVESTOR_PERSON__GENERAL === 'CHILD') {
      return number().nullable();
    }
    return number()
      .test(
        'min',
        WARNING_VALIDATION_MESSAGES.FREE_ASSETS_THREE_TIMES_EXPENSES,
        (value, testContext) => {
          if (
            !testContext.parent
              .FINANCIAL_DATA__MONTHLY_FINANCES__MONTHLY_EXPENSES
          ) {
            return true;
          }
          return value
            ? value >=
                parseFloat(
                  testContext.parent
                    .FINANCIAL_DATA__MONTHLY_FINANCES__MONTHLY_EXPENSES,
                ) *
                  3
            : true;
        },
      )
      .typeError(ERROR_VALIDATION_MESSAGES.DEFAULT_INPUT)
      .nullable();
  },
  [WarningValidationKey.Loans]: ({ sessionAnswers }: ValidationProps) => {
    if (sessionAnswers.INVESTOR_PERSON__GENERAL === 'CHILD') {
      return number().nullable();
    }
    return number()
      .test('max', WARNING_VALIDATION_MESSAGES.LOANS_NOT_REDEEMED, (value) =>
        value ? value === 0 : true,
      )
      .typeError(ERROR_VALIDATION_MESSAGES.DEFAULT_INPUT)
      .nullable();
  },
};
