import React, { FC } from 'react';

import { ClassStyle } from '../types/ClassStyle.model';

import styles from './Loader.module.css';

export type LoaderProps = {
  width: string;
  height: string;
} & ClassStyle;

export const Loader: FC<LoaderProps> = ({
  width = 'var(--sizes-40)',
  height = 'var(--sizes-6)',
  className = '',
  style,
}) => (
  <div
    className={[className, styles.loader].join(' ')}
    style={{
      '--width': width,
      '--height': height,
      ...style,
    }}
  />
);
