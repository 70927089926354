import { SVGProps } from 'react';

import {
  SvgInvestmentBatterienGradient,
  SvgInvestmentCannabisGradient,
  SvgInvestmentCloudComputingGradient,
  SvgInvestmentCybersicherheitGradient,
  SvgInvestmentDigitalisierungGradient,
  SvgInvestmentEMobilitaetGradient,
  SvgInvestmentFintechGradient,
  SvgInvestmentGesundheitInnovationGradient,
  SvgInvestmentHalbleiterGradient,
  SvgInvestmentInfrastrukturGradient,
  SvgInvestmentKuenstlicheIntelligenzGradient,
  SvgInvestmentNeueEnergienGradient,
  SvgInvestmentSmartCitiesGradient,
  SvgIpsAvThin,
  SvgIpsCiThin,
  SvgIpsNhThin,
  SvgIpsThThin,
  SvgIpsVvThin,
} from '@quirion/assets';
import { ConditionCategory } from '@quirion/types';

export const ProductIcons: Record<
  ConditionCategory,
  (props: SVGProps<SVGSVGElement>) => JSX.Element
> = {
  [ConditionCategory.AssetManagement]: SvgIpsVvThin,
  [ConditionCategory.AssetManagementSustainable]: SvgIpsNhThin,
  [ConditionCategory.AssetManagementTopics]: SvgIpsThThin,
  [ConditionCategory.Batteries]: SvgInvestmentBatterienGradient,
  [ConditionCategory.CannabisAndWellness]: SvgInvestmentCannabisGradient,
  [ConditionCategory.CashInvest]: SvgIpsCiThin,
  [ConditionCategory.CleanEnergy]: SvgInvestmentNeueEnergienGradient,
  [ConditionCategory.CloudComputing]: SvgInvestmentCloudComputingGradient,
  [ConditionCategory.CyberSecurity]: SvgInvestmentCybersicherheitGradient,
  [ConditionCategory.DigitalInfrastructure]:
    SvgInvestmentDigitalisierungGradient,
  [ConditionCategory.EMobility]: SvgInvestmentEMobilitaetGradient,
  [ConditionCategory.Fintech]: SvgInvestmentFintechGradient,
  [ConditionCategory.HealthcareAndInnovation]:
    SvgInvestmentGesundheitInnovationGradient,
  [ConditionCategory.Infrastructure]: SvgInvestmentInfrastrukturGradient,
  [ConditionCategory.MachineLearning]:
    SvgInvestmentKuenstlicheIntelligenzGradient,
  [ConditionCategory.PensionPlan]: SvgIpsAvThin,
  [ConditionCategory.Semiconductor]: SvgInvestmentHalbleiterGradient,
  [ConditionCategory.SmartCities]: SvgInvestmentSmartCitiesGradient,
  /**
   * deprecated
   */
  [ConditionCategory.AssetManagementOpinion]: SvgIpsVvThin, // fallback
  /**
   * deprecated
   */
  [ConditionCategory.Marketplace]: SvgIpsVvThin, // fallback
  /**
   * deprecated
   */
  [ConditionCategory.VideoGamesAndESport]: SvgIpsVvThin, // fallback
};

export const getProductIcon = (conditionCategory: ConditionCategory) => {
  const icon = ProductIcons[conditionCategory];
  return icon || SvgIpsVvThin;
};
