import { Payload } from '../types';

/**
 * @param payload
 * @returns Object with all booleans and numbers converted into strings.
 */
export const unifyPayload = (payload: Payload): Payload => {
  const newPayload = { ...payload };
  Object.keys(newPayload).forEach((key) => {
    const value = newPayload[key];
    switch (typeof value) {
      case 'boolean':
        newPayload[key] = `${value}`;
        break;
      case 'number':
        newPayload[key] = value.toString();
        break;
      default:
        break;
    }
  });
  return newPayload;
};
