import { WphgDetailsGeneric } from '@quirion/types';

type Mapping = Partial<{
  strategy: string[];
  hold: number[];
  shortHold: number[];
  mediumHold: number[];
  longHold: number[];
  percent: number[];
  vola: number[];
}>;

type HoldingPeriod = 'medium' | 'long' | 'retirement' | 'short';

// generate strategies base on type of account and user input

const strategy = [
  '10:90',
  '20:80',
  '30:70',
  '40:60',
  '50:50',
  '60:40',
  '70:30',
  '80:20',
  '90:10',
  '100:0',
];

const vola = [3.22, 4.66, 6.1, 8.1, 10.1, 12.3, 14.5, 16.7, 18.9, 21.2];

const maxRisk = vola[vola.length - 1] / 0.95;
const percent = vola.map((v) => (100 * v) / maxRisk);

/**
 * Returns the sum of current and following value in an array, divided by 2
 * @method
 * @returns { number }  ( val + arr[ idx + 1 ] ) / 2
 */
const mapMath = (
  /** array of values */
  arr: number[],
  /** value at arr[idx] */
  val: number,
  /** current index in array */
  idx: number,
) => {
  const calculated = (val + arr[idx + 1]) / 2;
  return Number.isNaN(calculated) ? 100 : Math.min(100, calculated);
};

/**
 * full mapping of strategies
 */
const defaultMapping: Mapping = {
  strategy,
  vola,
  percent,
  longHold: percent.map((curr, idx) => mapMath(percent, curr, idx) - 10),
  mediumHold: percent.map((curr, idx) => mapMath(percent, curr, idx) + 0),
  shortHold: percent.map((curr, idx) => mapMath(percent, curr, idx) + 10),
};

/**
 * Returns a filtered array depending on the holding type
 * @method
 * @returns {{ strategy:[]; vola:[]; percent:[]; map:[] }} filtered array based on holding
 */
const filterMapping = (
  /** holding period */
  holding: HoldingPeriod,
  /** refers to the mapping of strategies */
  mapping: Mapping,
) => {
  switch (holding) {
    case 'medium':
      return {
        strategy: mapping.strategy || [],
        vola: mapping.vola || [],
        percent: mapping.percent || [],
        map: mapping.mediumHold || [],
      };
    case 'long':
      return {
        strategy: mapping.strategy || [],
        vola: mapping.vola || [],
        percent: mapping.percent || [],
        map: mapping.longHold || [],
      };
    case 'retirement':
      return {
        strategy: mapping.strategy || [],
        map: mapping.hold || [],
      };
    case 'short':
    default:
      return {
        strategy: mapping.strategy || [],
        vola: mapping.vola || [],
        percent: mapping.percent || [],
        map: mapping.shortHold || [],
      };
  }
};

/* RETIREMENT STRATEGIES */

const retirementMapping: Mapping = {
  strategy: ['defensiv', 'moderat', 'offensiv'],
  hold: [30, 60],
};

/**
 * return the correct strategy based on userData
 * @exports
 * @method
 * @returns {string} strategy
 */
export const calculateStrategy = (
  /** investment purpose */
  purpose: WphgDetailsGeneric['wphg_investment_purpose'],
  /** holding period */
  period: WphgDetailsGeneric['wphg_holding_period'],
  /** finalResult score of risk assessment */
  risk: WphgDetailsGeneric['wphg_risk_preference'],
) => {
  const mapping = purpose === 'retirement' ? retirementMapping : defaultMapping;
  const holdingPeriod =
    period !== null && typeof period !== 'undefined' ? period : 'retirement';
  const finalResult = risk;
  const filtered = filterMapping(holdingPeriod as HoldingPeriod, mapping);
  const holdIndex = filtered.map.reduce(
    // eslint-disable-next-line
    (acc, curr, idx) =>
      // what is happening here? :D
      // eslint-disable-next-line
      (parseInt(String(finalResult), 10) - curr > 0 ? (acc = idx + 1) : (acc = acc)),
    0,
  );
  return filtered.strategy[Math.min(holdIndex, 9)];
};
