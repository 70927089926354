import { TransactionStatus } from '@quirion/types';
import { APICallInputWithPayload, TransactionTypeParam } from '../types';
import { extendedFetch } from '../utils/extendedFetch';
import { getEndpointsDefinition } from '../utils/getEndpointsDefinition';
import {
  ReadIpsTransactionsPayload,
  ReadIpsTransactionsResponse,
} from './readIpsTransactions.types';

const PAYLOAD_DEFAULTS = {
  range: 30,
  status: TransactionStatus.All,
  type: TransactionTypeParam.All,
};

/**
 * @see https://quirion.atlassian.net/browse/QUIK-3330
 */

export const readIpsTransactions = ({
  endpointsDefinition = getEndpointsDefinition(),
  payload,
}: APICallInputWithPayload<ReadIpsTransactionsPayload>): Promise<ReadIpsTransactionsResponse> =>
  extendedFetch({
    endpointConfig: endpointsDefinition.endpoints.ipsTransactions,
    payload: {
      ...PAYLOAD_DEFAULTS,
      ...payload,
    },
  }).then((res) => res.json());
