import React, {
  ComponentType,
  CSSProperties,
  FC,
  PropsWithChildren,
} from 'react';

import { CustomAttributes } from '../../types/CustomAttribute.model';

import styles from './Caption.module.css';

export type CaptionProps = CustomAttributes &
  PropsWithChildren<{
    id?: string;
    className?: string;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    hidden?: boolean;
    role?: string;
    colSpan?: number;
    style?: CSSProperties;
    /**
     * Allows to switch the used HTML tag used for this component.
     */
    as?: ComponentType | keyof JSX.IntrinsicElements;
  }>;

const Caption: FC<CaptionProps> = ({
  id = '',
  children,
  className = '',
  onClick = undefined,
  hidden = false,
  role = '',
  colSpan = 1,
  style,
  as = 'span',
  ...rest
}) => {
  const Tag = as;
  return (
    <Tag
      id={id}
      className={[className, styles.caption].join(' ')}
      style={style}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore typescript looks for specific span click handler, but we're using dynamic tags
      onClick={onClick}
      hidden={hidden}
      role={role}
      colSpan={colSpan}
      {...rest}
    >
      {children}
    </Tag>
  );
};

const CaptionUppercase: FC<CaptionProps> = ({
  children,
  onClick,
  hidden,
  role,
  className,
  colSpan,
  style,
  as = 'span',
  ...rest
}) => {
  const Tag = as;
  return (
    <Tag
      className={[className, styles.captionUppercase].join(' ')}
      style={style}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore typescript looks for specific span click handler, but we're using dynamic tags
      onClick={onClick}
      hidden={hidden}
      role={role}
      colSpan={colSpan}
      {...rest}
    >
      {children}
    </Tag>
  );
};

const CaptionSmall: FC<CaptionProps> = ({
  children,
  onClick,
  hidden,
  role,
  className,
  colSpan,
  style,
  as = 'span',
  ...rest
}) => {
  const Tag = as;
  return (
    <Tag
      className={[className, styles.captionSmall].join(' ')}
      style={style}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore typescript looks for specific span click handler, but we're using dynamic tags
      onClick={onClick}
      hidden={hidden}
      role={role}
      colSpan={colSpan}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export { Caption, CaptionUppercase, CaptionSmall };
