import { AuthConfig } from '@quirion/types';

import { EndpointsDefinition } from '../fetchEndpoints.types';

export type BankAccount = {
  currency: string; // ISO 4217
  accountHolder: string;
  iban: string;
  createdAt: string; // ISO 8601
  bic: string;
  name: string;
  exchangeRate: number;
  type: string; // @todo replace with enum
};

export enum ItemStatus {
  All = 'ALL',
  Closed = 'CLOSED',
  Open = 'OPEN',
}

/**
 * Do not map values from TransactionType to TransactionTypeParam for filtering purposes
 * as the values are not the same.
 */
export enum TransactionTypeParam {
  SepaIn = 'SEPAIN',
  SepaOut = 'SEPAOUT',
  Transfer = 'TRANSFER',
  All = 'ALL',
}

export type APICallInput = {
  endpointsDefinition?: EndpointsDefinition;
};

export type APICallInputWithAuth = {
  authConfig?: AuthConfig;
} & APICallInput;

export type APICallInputWithPayload<T> = {
  payload: T;
} & APICallInput;

export type APICallInputWithOptionalPayload<T> = {
  payload?: T;
} & APICallInput;

export type APICallInputWithAuthAndPayload<T> = {
  payload: T;
} & APICallInputWithAuth;

export type Payload = Record<string, unknown>;
