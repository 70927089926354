import { ENDPOINTS_URL } from './constants';
import { EndpointsDefinition } from './fetchEndpoints.types';
import { Payload } from './types';
import { buildUrlForFetch } from './utils/buildUrlForFetch';

// see https://quirion.atlassian.net/browse/QUIK-3188

export const fetchEndpoints = (
  isProd: boolean,
  payload?: Payload,
): Promise<EndpointsDefinition> => {
  const url = isProd ? ENDPOINTS_URL.prod : ENDPOINTS_URL.dev;
  return fetch(buildUrlForFetch({ url, method: 'GET', payload }))
    .then((res) => res.json())
    .then((json) => json as EndpointsDefinition);
};
