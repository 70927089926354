import { Suspense } from 'react';
import type { FC } from 'react';

import { Outlet } from 'react-router-dom';

import { Caption, WrappedLoader } from '@quirion/components';
import { StorageKey } from '@quirion/utils';

import { Footer } from 'lib/components';

import { Navigation } from './Navigation';

import styles from './AppFrame.module.css';

// hide Navigation & Footer in App Webviews.. supplemented by <script> in `public/index.html`
export const inWebview =
  sessionStorage?.getItem(StorageKey.AppToWeb) &&
  JSON.parse(sessionStorage.getItem(StorageKey.AppToWeb) || '{}')?.inWebview;

export const AppFrame: FC = () => (
  <>
    {!inWebview && <Navigation />}
    <div className={styles.banking}>
      <main id="content" className={styles.content}>
        <Suspense
          fallback={
            <WrappedLoader logo global>
              <Caption>Wird geladen…</Caption>
            </WrappedLoader>
          }
        >
          <Outlet />
        </Suspense>
      </main>
      {!inWebview && <div id="action-bar" className={styles.actionBar} />}
      {!inWebview && <Footer id="footer-dashboard" className={styles.footer} />}
    </div>
  </>
);
