import { AuthConfig } from '@quirion/types';
import { AuthHelper } from './utils/AuthHelper';
import { AUTH_CONFIG_BANKING } from '../constants';

export const getExpiryTime = (
  authConfig: AuthConfig = AUTH_CONFIG_BANKING,
): number | undefined => {
  const auth = new AuthHelper(authConfig);
  return auth.getExpiryTime();
};
