import { APICallInputWithPayload } from '../types';
import { extendedFetch } from '../utils/extendedFetch';
import { getEndpointsDefinition } from '../utils/getEndpointsDefinition';
import { ReadWphgPayload, ReadWphgResponse } from './readWphg.types';

const PAYLOAD_DEFAULTS = {
  wphgHistory: false,
};

export const readWphg = ({
  endpointsDefinition = getEndpointsDefinition(),
  payload,
}: APICallInputWithPayload<ReadWphgPayload>): Promise<ReadWphgResponse> =>
  extendedFetch({
    endpointConfig: endpointsDefinition.endpoints.getWphg,
    payload: {
      ...PAYLOAD_DEFAULTS,
      ...payload,
    },
  }).then((res) => res.json());
