import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgInvestmentInfrastrukturGradient = function (
  props: SVGProps<SVGSVGElement>,
) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill={`url(#${_id})`}
        d="m17.78 28.1-.48-.83 1.16-.67.48.83zm3.11-.27-.1-.96 1.19-.13-.17-.54.92-.28.51 1.66-2.36.25Zm-4.66-.62-.48-.83 1.16-.67.48.83zM14.14 7.45l6.07-3.51 3.64 4.8v7.65l-5.9 3.41-.24.14-.24-.14-5.36-3.09-3.39 1.96 12.27 7.08-.48.83-12.99-7.5-.24-.15V6.3h.96v11.52l3.32-1.92V9.33l.2-.15 2.37-1.73Zm-1.6 8.38 5.18 2.99 5.18-2.99v-5.42l-5.33 3.08-3.28-4.94-1.74 1.28v6.01Zm2.55-7.82 2.78 4.18 4.82-2.79-2.78-4.18zm-.41 18.31-.48-.83 1.16-.67.48.83zm-1.61-.89-.48-.83 1.16-.67.48.83zm-1.61-.89-.48-.83 1.16-.67.48.83zm-1.61-.89-.48-.83 1.16-.67.48.83zm-1.61-.89-.48-.83 1.16-.67.48.83zm12.43-.82-1.68-.97.48-.83 1.2.69 1-.58-.72-.41.48-.83 1.2.69 1-.58-.72-.41.48-.83 1.2.69 1-.58-.72-.41.48-.83L27.51 18l-6.86 3.96Zm-14.04-.07-.48-.83 1.16-.67.48.83zm-1.61-.89-.48-.83 1.16-.67.48.83zm13.45-3.75v-3.4l3.68-2.12v3.4zm.96-2.84v1.18l1.75-1.01v-1.18zM6.85 6.18l-.68-.68 1.59-1.59L9.35 5.5l-.68.68-.91-.91z"
      />
      <defs>
        <linearGradient
          id={_id}
          x1="0%"
          x2="50%"
          y1="50%"
          y2="0%"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939D4" />
          <stop offset={0.48} stopColor="#5D4DF8" />
          <stop offset={0.74} stopColor="#B459F9" />
          <stop offset={1} stopColor="#FE3B68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgInvestmentInfrastrukturGradient;
