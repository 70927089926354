export enum ErrorName {
  Default = 'Error',
  Network = 'NetworkError',
}

export type ErrorOptions = {
  cause?: Error;
  description?: string;
  body?: string;
};

export const DEFAULT_ERROR_DESCRIPTION =
  'Versuchen Sie es später erneut oder wenden Sie sich an unseren Support.';
