import { AuthParams, AuthParamsCompat } from '@quirion/types';

export function migrateAuthProperties(
  authParams: AuthParamsCompat,
): AuthParams {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const auth: any = { ...authParams };

  if (auth.AccessToken) {
    auth.accessToken = auth.AccessToken;
    delete auth.AccessToken;
  }
  if (auth.IdToken) {
    auth.idToken = auth.IdToken;
    delete auth.IdToken;
  }
  if (auth.RefreshToken) {
    auth.refreshToken = auth.RefreshToken;
    delete auth.RefreshToken;
  }
  if (auth.ExpiresIn) {
    auth.expiresIn = auth.ExpiresIn;
    delete auth.ExpiresIn;
  }
  if (auth.TokenType) {
    auth.tokenType = auth.TokenType;
    delete auth.TokenType;
  }

  return auth;
}
