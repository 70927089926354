import type { SVGProps } from 'react';
const SvgBuildingCommunity = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={1.5}
    className="building-community_svg__icon building-community_svg__icon-tabler building-community_svg__icon-tabler-building-community"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="m8 9 5 5v7H8v-4m0 4H3v-7l5-5m1 1V4a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v17h-8M13 7v.01M17 7v.01M17 11v.01M17 15v.01" />
  </svg>
);
export default SvgBuildingCommunity;
