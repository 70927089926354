import { AuthConfig } from '@quirion/types';

import { AUTH_CONFIG_BANKING } from '../constants/authConfig';
import { EndpointConfig } from '../fetchEndpoints.types';
import { Payload } from '../types';

import { buildErrorMessageFromFetch } from './buildErrorMessageFromFetch';
import { NetworkError } from './errors';
import { fetchWithoutPayload } from './fetchWithoutPayload';
import { fetchWithPayload } from './fetchWithPayload';

/** Options for {@link extendedFetch} */
export type ExtendedFetchInput = {
  endpointConfig: EndpointConfig;
  payload?: Payload;
  authConfig?: AuthConfig;
};

/**
 * Prepares fetch function for API call. Takes care of headers, body, query and search params.
 * @returns A Promise containing the API response.
 */
export const extendedFetch = async ({
  authConfig = AUTH_CONFIG_BANKING,
  endpointConfig,
  payload,
}: ExtendedFetchInput): Promise<Response> => {
  const fetcher = payload
    ? fetchWithPayload({ endpointConfig, authConfig, payload })
    : fetchWithoutPayload({ endpointConfig, authConfig });

  const res = await fetcher;

  if (res.status !== 200) {
    const body = typeof res.json === 'function' ? await res.json() : res;
    const message =
      buildErrorMessageFromFetch(body, endpointConfig.url) ||
      'Anfrage fehlgeschlagen';
    throw new NetworkError(message, res.status, endpointConfig.url, {
      description: 'Bitte laden Sie diese Seite neu und versuchen es erneut.',
    });
  }
  return res;
};
