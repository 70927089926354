import { Payload } from '../types';

/**
 * @param url URL to strip values from
 * @param payload Payload that contains the values that should be stripped from URL
 * @returns Payload without values that are passed into URL as query params
 */
export const stripQueryParamsFromPayload = (
  url: string,
  payload: Payload,
): Payload => {
  const cleanedPayload = { ...payload };
  Object.keys(payload).forEach((key) => {
    if (url.includes(`{${key}}`)) {
      delete cleanedPayload[key];
    }
  });
  return cleanedPayload;
};
