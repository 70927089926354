/* eslint-disable @typescript-eslint/no-explicit-any */
import { ErrorInfo } from 'react';

export enum StackTemplates {
  NetworkError = 'NetworkError',
  CustomError = 'CustomError',
}

export type ErrorState =
  | {
      hasError: true;
      error: any;
      errorInfo?: ErrorInfo;
    }
  | {
      hasError: false;
      error: null;
      errorInfo?: null;
    };

export type ErrorBoundaryFallbackProps = {
  hasError: boolean;
  error: any;
  errorName: string | undefined;
  errorMessage: string | undefined;
  componentStack: string | null | undefined;
};
