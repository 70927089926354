import { AuthChallenge, AuthChallengeCompat } from '@quirion/types';

export function migrateChallengeProperties(
  challengeParam: AuthChallengeCompat,
): AuthChallenge {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const challenge: any = { ...challengeParam };

  if (challenge.ChallengeName) {
    challenge.challengeName = challenge.ChallengeName;
    delete challenge.ChallengeName;
  }
  if (challenge.ChallengeParameters) {
    challenge.challengeParameters = { ...challenge.ChallengeParameters };
    delete challenge.ChallengeParameters;
  }
  if (challenge.Session) {
    challenge.session = challenge.Session;
    delete challenge.Session;
  }
  if (challenge.AuthenticationResult) {
    challenge.authenticationResult = { ...challenge.AuthenticationResult };
    delete challenge.AuthenticationResult;
  }
  if (challenge.ExpiresIn) {
    challenge.espiresIn = { ...challenge.ExpiresIn };
    delete challenge.ExpiresIn;
  }

  return challenge;
}
