export enum PricePackage {
  Digital = 'S',
  Premium = 'M',
  Private = 'XL',
}

export type PricePackageData = {
  name: string;
  alias: PricePackage;
  highlights: string[];
  included: {
    name: string;
    items: string[];
  }[];
  notIncluded: string[];
};

export enum ConditionCategory {
  PensionPlan = 'AV.AV',
  AssetManagementSustainable = 'VV.NH',
  /**
   * ME = Meinungsportfolio
   * @deprecated
   */
  AssetManagementOpinion = 'VV.ME',
  AssetManagement = 'VV.VV',
  CashInvest = 'VV.CI',
  /**
   * @alias Megatrends-Portfolio
   * @alias Selektive-Anlagechance
   */
  AssetManagementTopics = 'VV.TH',
  /**
   * Marktplatz VV
   * Was not in use till now (2022-11-11)
   * @deprecated
   */
  Marketplace = 'VV.MP',
  /**
   * discontinued VV
   * @deprecated
   */
  VideoGamesAndESport = 'VV.VG',
  SmartCities = 'VV.SC',
  Semiconductor = 'VV.SCD',
  MachineLearning = 'VV.ML',
  Infrastructure = 'VV.IS',
  HealthcareAndInnovation = 'VV.HI',
  Fintech = 'VV.FT',
  EMobility = 'VV.EM',
  DigitalInfrastructure = 'VV.DI',
  CyberSecurity = 'VV.CS',
  CloudComputing = 'VV.CC',
  CleanEnergy = 'VV.CE',
  /**
   * discontinued VV
   * @deprecated
   */
  CannabisAndWellness = 'VV.CW',
  Batteries = 'VV.BA',
}

export enum ConditionCampaign {
  /**
   * @deprecated discontinued in 2024
   */
  Wirtschaftsrat = 'WR',
  /**
   * @deprecated discontinued after January 8th, 2024
   */
  ForFree = 'FF',
}

export type ConditionPayload = {
  conditionId?: string;
};
