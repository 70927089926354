import {
  EntrypointId,
  Session,
  SessionAnswers,
  SessionType,
} from '@quirion/types';

/**
 * @param {Partial<SessionAnswers<SessionType>>} answers
 * @returns {boolean}
 *
 * @todo Check if @duplicate with isTangibleAssets
 */
export const isSessionAnswersTypeTangibleAssets = (
  answers: Partial<SessionAnswers<SessionType>>,
): answers is Partial<SessionAnswers<SessionType.TangibleAssets>> =>
  answers.META__ENTRYPOINT_ID === EntrypointId.NewCustomerNewBpTangibleAssets;

export const isSessionTypeTangibleAssets = (
  session: Session<SessionType>,
): session is Session<SessionType.TangibleAssets> =>
  isSessionAnswersTypeTangibleAssets(session.answers);
