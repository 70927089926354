import { useCallback, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  AuthHelper,
  getExpiryTime,
  isAuthenticated,
  logout,
  refreshTokens,
} from '@quirion/api';
import { StorageKey, useDebounce } from '@quirion/utils';

import { clearReduxStore } from 'lib/redux/actions/userActions';

import { ROUTES } from 'pages/_constants/Routes';
import {
  isAllowedRouteForUnauthenticatedUsers,
  isAuthenticationRoute,
  isAuthMfaSetupRoute,
} from 'pages/_utils';

export const useCheckAuthentication = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const logoutIfUserWasInactive = () => {
    const now = Math.floor(Date.now() / 1000);
    if (isAuthenticated()) {
      const exp = getExpiryTime();
      if (exp && exp > 0 && exp < now) {
        logout().then(() => {
          clearReduxStore(dispatch);
          sessionStorage.removeItem(StorageKey.BankingMode);
          window.location.href = `${ROUTES.AUTH_SIGN_OUT__INACTIVITY}${window.location.search}`;
        });
      }
    }
  };

  const tick = useCallback(() => {
    logoutIfUserWasInactive();
  }, []);

  useEffect(() => {
    const intervalHandle = setInterval(tick, 10000);
    return () => clearInterval(intervalHandle);
  }, [tick]);

  const checkAuthenticationStatus = () => {
    const now = Math.floor(Date.now() / 1000);
    if (
      !window.location.pathname.includes(ROUTES.AUTH_SIGN_OUT) &&
      isAuthenticated()
    ) {
      const exp = getExpiryTime();
      // check token expiration in case of reload
      if (exp && exp > 0 && exp > now) {
        // refresh token after reload
        refreshTokens().catch(() => {
          logout().then(() => {
            clearReduxStore(dispatch);
            sessionStorage.removeItem(StorageKey.BankingMode);
            window.location.href = `${ROUTES.AUTH_SIGN_OUT__INACTIVITY}${window.location.search}`;
          });
        });

        tick();
      } else {
        logout().then(() => {
          clearReduxStore(dispatch);
          sessionStorage.removeItem(StorageKey.BankingMode);
          window.location.href = `${ROUTES.AUTH_SIGN_OUT__INACTIVITY}${window.location.search}`;
        });
      }
    } else if (
      !isAuthenticationRoute(pathname) &&
      !isAllowedRouteForUnauthenticatedUsers(pathname)
    ) {
      // Current pathname doesn't match with a string in allowedPaths

      const allowedDynamicPaths = [
        ROUTES.AUTH_SIGN_IN,
        ROUTES.AUTH_SIGN_OUT__INACTIVITY,
        '/user/activate/',
      ];

      let foundAllowedDynamicPath = false;
      allowedDynamicPaths.forEach((element) => {
        if (window.location.pathname.indexOf(element) >= 0) {
          foundAllowedDynamicPath = true;
        }
      });
      if (!foundAllowedDynamicPath) {
        sessionStorage.removeItem(StorageKey.BankingMode);
        window.location.href = `${ROUTES.AUTH_SIGN_IN}${window.location.search}`;
      }
    }
  };

  const initAuth = async () => {
    /*
          If there is a token injected into localStorage, use AuthHelper to setAuth
          again. This makes sure, that the correct storage method is available.
          This is specifically implemented as a fallback for legacy App injection.
        */
    const auth = new AuthHelper();
    const token = JSON.parse(localStorage?.getItem('authorization') || 'null');
    if (token && !auth.isAuthenticated()) {
      localStorage.removeItem('authorization');
      auth.setAuth(token);
    }

    checkAuthenticationStatus();
  };

  /**
   * Event - handler for the visibilitychange event.
   * Will automatically route to the frontpage if already logged in or login page
   * if not logged in.
   * https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
   */
  const debouncedVisibilityChangedHandler = useDebounce(() => {
    if (
      isAuthenticated() &&
      !isAuthMfaSetupRoute(pathname) &&
      isAuthenticationRoute(pathname)
    ) {
      setTimeout(() => {
        if (window.location.search?.includes('welcome')) {
          window.location.href = '/';
        } else {
          window.location.href = `/${window.location.search}`;
        }
      }, 200);
    } else if (!isAuthenticated() && !isAuthenticationRoute(pathname)) {
      setTimeout(() => {
        sessionStorage.removeItem(StorageKey.BankingMode);
        window.location.href = `${ROUTES.AUTH_SIGN_IN}${window.location.search}`;
      }, 200);
    }
  }, 200);

  useEffect(() => {
    document.addEventListener(
      'visibilitychange',
      debouncedVisibilityChangedHandler,
    );

    initAuth();

    return () =>
      document.removeEventListener(
        'visibilitychange',
        debouncedVisibilityChangedHandler,
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
