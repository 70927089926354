import type { DepotItem } from './DepotItem.types';
import type { DebitAccount, LiquidAccount } from '../AuxiliaryAccount.types';
import type { Condition } from '../Condition';
import type { DateIsoString } from '../Date.types';
import type { StrategyName } from '../Strategy';
import type { ValueHistory } from '../User';
import type { WphgDetailsExistingCustomer } from '../Wphg';

export enum IpsStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
  Inclose = 'INCLOSE',
  Pending = 'PENDING',
  Strategychange = 'STRATEGYCHANGE',
}

/**
 * Investment Policy Statement
 *
 * "External" strategy / product. Booked by users,
 * includes balance and how the balance is allocated.
 *
 * @url https://quirion.atlassian.net/wiki/spaces/ITQ/pages/71204932/Abkuerzungsverzeichnis+und+Glossar
 */
export type IPS = {
  primaryKey?: string;
  sortKey?: string;

  avaloqStrategyId?: number;
  balance: number;
  balanceDate: DateIsoString;
  businessPartnerId: number;
  category: Condition['category'];
  condition?: Condition;
  conditionId: string;
  createdAt: DateIsoString;
  customName?: string;
  debit?: DebitAccount;
  depotBalance: number;
  depotItems?: DepotItem[];
  history?: ValueHistory;
  historyYear?: ValueHistory;
  ipsId: number;
  k?: string;
  liquid?: LiquidAccount;
  mwrPerformanceStart: number;
  mwrPerformanceYearStart: number;
  name: string;
  onIndex?: string;
  package?: string;
  performanceStartDate: DateIsoString;
  rendite?: ValueHistory;
  returnOnInvestment: {
    /**
     *  return on investment as total number (in €)
     */
    absolute: number;
    /**
     * return on investment in decimal percentage number (e.g. `0.29` => `29 %`)
     */
    relative: number;
  };
  status: IpsStatus;
  strategy: StrategyName;
  strategyChangeDate?: DateIsoString;
  twrPerformanceStart: number;
  twrPerformanceYearStart?: number;
  updatedAt: DateIsoString;
  weightName?: string;
  wphg?: WphgDetailsExistingCustomer;
  wphgExpiresAt: DateIsoString;
};

export type Product = IPS & {
  assetId?: string; // bloxxter
};

export type IPSRecord = Record<IPS['ipsId'], IPS>;

export const isIPS = (value: unknown): value is IPS =>
  typeof value === 'object' &&
  value !== null &&
  'ipsId' in value &&
  'status' in value &&
  'strategy' in value;
