import { AppRoutes } from './AppRoutes';

/**
 * If adding new ROUTES, please align keys to `DynamicHintSlot`, if possible.
 */

export const ROUTES = {
  AUTH_MFA_SETUP:
    AppRoutes.authentication.children.settings.children.mfa.children.setup.path,
  AUTH_SIGN_IN: AppRoutes.authentication.children.signIn.path,
  AUTH_SIGN_OUT: AppRoutes.authentication.children.signOut.path,
  AUTH_SIGN_OUT__INACTIVITY:
    AppRoutes.authentication.children.signOut.children.inactivity.path,
  AUTH_PASSWORD_REQUEST:
    AppRoutes.authentication.children.settings.children.password.children
      .resetPassword.path,
  DASHBOARD: AppRoutes.dashboard.path,
};
