import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgBpJoint = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill={`url(#${_id})`}
        fillRule="nonzero"
        d="m2.895 14.178 10.244.001a1.81 1.81 0 0 1 1.803 1.667l.005.15v1.7c0 1.793-.958 2.941-2.266 3.645-1.388.747-3.155 1.012-4.669 1.012-1.48 0-3.204-.254-4.578-.964-1.293-.669-2.268-1.755-2.351-3.449l-.005-.249v-1.705a1.81 1.81 0 0 1 1.652-1.801zm-.009.768-.05.003-.109.011-.072.012a1.06 1.06 0 0 0-.502.275 1.07 1.07 0 0 0-.287.531l-.007.05-.011.109-.002.049v1.71c0 1.334.584 2.282 1.861 2.969.995.535 2.409.867 3.929.914l.376.006.377-.006c1.52-.047 2.933-.378 3.928-.914 1.199-.645 1.785-1.523 1.856-2.731l.006-.239v-1.707a1.04 1.04 0 0 0-.839-1.021l-.201-.02zm12.719-.767h5.509a1.81 1.81 0 0 1 1.803 1.667l.005.15v1.131c-.001 3.172-3.006 4.087-5.226 4.087-.522 0-2.192-.239-2.748-.357q.222-.333.396-.702l2.352.29.325-.007c.609-.026 1.562-.144 2.385-.547.897-.439 1.645-1.2 1.739-2.497l.009-.267v-1.139a1.04 1.04 0 0 0-.839-1.021l-.205-.019.004-.001h-5.202a3.8 3.8 0 0 0-.307-.768M8.012 1.647a4.66 4.66 0 0 1 4.657 4.657 4.66 4.66 0 0 1-4.657 4.656 4.66 4.66 0 0 1-4.656-4.656 4.66 4.66 0 0 1 4.656-4.657m10.254 2.279a3.516 3.516 0 0 1 3.517 3.517 3.52 3.52 0 0 1-3.517 3.517 3.52 3.52 0 0 1-3.517-3.517 3.52 3.52 0 0 1 3.517-3.517M8.012 2.415c-2.143 0-3.888 1.745-3.888 3.889s1.745 3.888 3.888 3.888 3.889-1.745 3.889-3.888-1.745-3.889-3.889-3.889m10.254 2.279c-1.516 0-2.749 1.233-2.749 2.749s1.233 2.749 2.749 2.749 2.749-1.233 2.749-2.749-1.233-2.749-2.749-2.749"
      />
      <defs>
        <linearGradient
          id={_id}
          x1={-0.508}
          x2={23.682}
          y1={19.318}
          y2={4.995}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939d4" />
          <stop offset={0.48} stopColor="#5d4df8" />
          <stop offset={0.74} stopColor="#b459f9" />
          <stop offset={1} stopColor="#fe3b68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgBpJoint;
