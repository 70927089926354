import { AuthConfig } from '@quirion/types';
import { AuthHelper } from '../Auth/utils/AuthHelper';
import { AUTH_CONFIG_BANKING } from '../constants';

/**
 * @param isPrivateEndpoint
 * @returns Object with authorization header set if the endpoint is private and thus needs auth token
 */
export const getCustomHeaders = (
  isPrivateEndpoint: boolean,
  authConfig: AuthConfig = AUTH_CONFIG_BANKING,
) => {
  const customHeaders: {
    authorization?: string;
  } = {};
  if (isPrivateEndpoint) {
    const auth = new AuthHelper(authConfig);
    const authToken = auth.getIdToken();
    if (!authToken) {
      throw new Error('No authentication token found.');
    }

    customHeaders.authorization = `Bearer ${authToken}`;
  }
  return customHeaders;
};
