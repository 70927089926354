import { AUTH_CONFIG_BANKING, ERROR_MESSAGES } from '../constants';
import { extendedFetch } from '../utils/extendedFetch';
import { getEndpointsDefinition } from '../utils/getEndpointsDefinition';
import { AuthHelper } from './utils/AuthHelper';

import { APICallInputWithAuth } from '../types';
import { RefreshTokensInternalResponse } from './refreshTokens.types';

let lastTimeRefreshed = 0;

export const refreshTokens = (conf?: APICallInputWithAuth): Promise<void> => {
  const { endpointsDefinition, authConfig } = conf ?? {
    endpointsDefinition: getEndpointsDefinition(),
    authConfig: AUTH_CONFIG_BANKING,
  };

  const auth = new AuthHelper(authConfig);
  const now = Math.floor(Date.now() / 1000);

  if (auth.isAuthenticated()) {
    if (now - lastTimeRefreshed > 10 || lastTimeRefreshed === 0) {
      lastTimeRefreshed = now;
      const token = auth.getRefreshToken();
      if (!token) {
        return new Promise((_, reject) => {
          reject(ERROR_MESSAGES.REFRESH_TOKEN_NOT_AUTHENTICATED);
        });
      }
      return extendedFetch({
        endpointConfig: endpointsDefinition.endpoints.refreshToken,
        payload: { token },
      })
        .then((response) => response.json())
        .then((response: RefreshTokensInternalResponse) => {
          const fullToken = { ...response.AuthenticationResult };
          fullToken.RefreshToken = token;
          auth.setAuth(fullToken);
        });
    }
    return new Promise((resolve) => {
      resolve();
    });
  }
  return new Promise((_, reject) => {
    reject(ERROR_MESSAGES.REFRESH_TOKEN_NOT_AUTHENTICATED);
  });
};
