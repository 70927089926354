import { MONTHS_PER_YEAR, STANDARD_NORMAL_DISTRIBUTION } from '@quirion/data';
import { Condition, CostSheet, Strategy } from '@quirion/types';

import { WPHG_MAP } from './domain/wphgMap';

export type ScenarioInstance = {
  year: number;
  pessimistic: number;
  expected: number;
  optimistic: number;
  payed: number;
};

export enum Scenarios {
  Pessimistic = 'pessimistic',
  Expected = 'expected',
  Optimistic = 'optimistic',
}

export type ProjectionParameters = {
  fullCostsPercentage: number;
  returnAfterCosts: number;
  returnSteady: number;
  volatilityLocal: number;
  currentYear: number;
  yearlyDeposits: number;
};

const YEARS_TO_DISPLAY = 10;
const MONTHLY_SAVINGS_INTERVAL = 1; // Once per month

export const extractInvestmentPeriod = (investmentPeriodAnswer: string) => {
  const investmentPeriod =
    WPHG_MAP?.INVESTMENT_PERIOD__GENERAL?.options?.[investmentPeriodAnswer]
      ?.calcValue ?? YEARS_TO_DISPLAY;
  const currentYear = new Date().getFullYear();
  const investmentPeriodEndYear = currentYear + investmentPeriod;

  return {
    investmentPeriod,
    investmentPeriodEndYear,
  };
};

export type GetProjectParametersArgs = {
  category: Condition['category'];
  allowance: Condition['allowance'];
  feeQuirion: Condition['feeQuirion'];
  gesamtKostenProzent: CostSheet['gesamtKostenProzent'];
  fondsKostenProzent: CostSheet['fondsKostenProzent'];
  strategyYield: Strategy['yield'];
  vola: Strategy['vola'];
  monthlySavings: number;
};

export const getProjectionParameters = ({
  category,
  allowance,
  feeQuirion,
  gesamtKostenProzent,
  fondsKostenProzent: fundCostsDecimalPercentage,
  strategyYield,
  vola,
  monthlySavings,
}: GetProjectParametersArgs): ProjectionParameters => {
  const isVV: boolean | undefined = category?.includes('VV.');
  const hasAllowance: boolean | undefined =
    typeof allowance === 'number' && allowance > 0;

  if (isVV === undefined) {
    throw Error(
      'To calculate total costs via getTotalCosts, the condition category must be defined.',
    );
  }
  if (hasAllowance === undefined) {
    throw Error(
      'To calculate total costs via getTotalCosts, the condition allowance must be defined.',
    );
  }
  if (!feeQuirion) {
    throw Error(
      'To calculate total costs via getTotalCosts, the condition feeQuirion must be defined.',
    );
  }

  const fullCostsPercentage: number | false =
    (isVV &&
      gesamtKostenProzent &&
      (hasAllowance
        ? parseFloat(
            feeQuirion.toString().replace(',', '.') +
              fundCostsDecimalPercentage,
          )
        : parseFloat(gesamtKostenProzent.toString().replace(',', '.')))) ||
    0;

  const returnAfterCosts =
    fullCostsPercentage && typeof strategyYield === 'number'
      ? (strategyYield - fullCostsPercentage) / 100
      : 0;
  const returnSteady = Math.log(1 + returnAfterCosts);
  const volatilityLocal = vola ? vola / 100 : 0;

  const currentYear = new Date().getFullYear();

  const yearlyDeposits =
    (MONTHS_PER_YEAR * monthlySavings) / MONTHLY_SAVINGS_INTERVAL;

  return {
    fullCostsPercentage,
    returnAfterCosts,
    returnSteady,
    volatilityLocal,
    currentYear,
    yearlyDeposits,
  };
};

export type GetInvestmentScenariosByYearArgs = GetProjectParametersArgs & {
  oneTimeDeposit: number;
  amountOfYears: number;
};

export const getInvestmentScenariosByYear = ({
  category,
  allowance,
  feeQuirion,
  gesamtKostenProzent,
  fondsKostenProzent,
  strategyYield,
  vola,
  monthlySavings,
  oneTimeDeposit,
  amountOfYears,
}: GetInvestmentScenariosByYearArgs): ScenarioInstance[] => {
  const { returnSteady, volatilityLocal, currentYear, yearlyDeposits } =
    getProjectionParameters({
      category,
      allowance,
      feeQuirion,
      gesamtKostenProzent,
      fondsKostenProzent,
      strategyYield,
      vola,
      monthlySavings,
    });

  const investmentScenarios: ScenarioInstance[] = [];

  const getPoint = (key: Scenarios, yearIdx: number) => {
    if (yearIdx === 0) return oneTimeDeposit;

    const latestPoint =
      investmentScenarios?.[investmentScenarios.length - 1]?.[key];

    const scenarios = {
      [Scenarios.Pessimistic]:
        (latestPoint * Math.exp(returnSteady)) /
          Math.exp(
            STANDARD_NORMAL_DISTRIBUTION *
              volatilityLocal *
              (-1 * Math.sqrt(yearIdx - 1) + Math.sqrt(yearIdx)),
          ) +
        yearlyDeposits,
      [Scenarios.Expected]:
        Math.exp(returnSteady + volatilityLocal ** 2 / 2) * latestPoint +
        yearlyDeposits,
      [Scenarios.Optimistic]:
        latestPoint *
          Math.exp(returnSteady) *
          Math.exp(
            STANDARD_NORMAL_DISTRIBUTION *
              volatilityLocal *
              (-1 * Math.sqrt(yearIdx - 1) + Math.sqrt(yearIdx)),
          ) +
        yearlyDeposits,
    };

    return scenarios[key];
  };

  for (let yearIdx = 0; yearIdx <= amountOfYears; yearIdx += 1) {
    const cumulativeMonthlyDeposits = yearIdx * yearlyDeposits;
    const totalDeposits = oneTimeDeposit + cumulativeMonthlyDeposits;

    investmentScenarios.push({
      pessimistic: getPoint(Scenarios.Pessimistic, yearIdx),
      expected: getPoint(Scenarios.Expected, yearIdx),
      optimistic: getPoint(Scenarios.Optimistic, yearIdx),
      year: currentYear + yearIdx,
      payed: totalDeposits,
    });
  }

  return investmentScenarios;
};

export type GetAssetsByScenariosArgs = GetInvestmentScenariosByYearArgs;

export const getAssetsByScenarios = ({
  category,
  allowance,
  feeQuirion,
  gesamtKostenProzent,
  fondsKostenProzent,
  strategyYield,
  vola,
  monthlySavings,
  oneTimeDeposit,
  amountOfYears,
}: GetAssetsByScenariosArgs): ScenarioInstance =>
  getInvestmentScenariosByYear({
    category,
    allowance,
    feeQuirion,
    gesamtKostenProzent,
    fondsKostenProzent,
    strategyYield,
    vola,
    monthlySavings,
    oneTimeDeposit,
    amountOfYears,
  })[amountOfYears ?? YEARS_TO_DISPLAY];
