import { getEndpointsDefinition } from '../utils/getEndpointsDefinition';
import { APICallInputWithPayload, ItemStatus } from '../types';
import { BusinessPartnerItem } from './readOrder.types';
import { GetChangePackageOrderPayload } from './getPendingPackageChangeOrders.types';

import { readOrder } from './readOrder';

export const getPendingPackageChangeOrders = ({
  endpointsDefinition = getEndpointsDefinition(),
  payload,
}: APICallInputWithPayload<GetChangePackageOrderPayload>): Promise<
  BusinessPartnerItem[]
> =>
  readOrder({
    endpointsDefinition,
    payload: {
      ...payload,
      status: ItemStatus.Open,
      range: 90,
      businessPartner: true,
    },
  }).then((res) => res.businessPartner);
