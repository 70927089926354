import { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { readWphg } from '@quirion/api';
import { useBannerContext } from '@quirion/components';
import type { BusinessPartner } from '@quirion/types';

import { getBp } from 'lib/redux/actions/userActions';
import type { State } from 'lib/redux/types';

import { isExpired } from '../updateData/isExpired';

import { isWphgDataValid } from './isWphgDataValid';

/**
 * Checks if WpHG data is valid, complete and not expired.
 */
export const useCheckWphgDataValidity = () => {
  const { notify } = useBannerContext();
  const dispatch = useDispatch();

  const [pendingBPs, setPendingBPs] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);

  const businessPartnerList = useSelector(
    (state: State) => state.businessPartner,
  );

  useEffect(() => {
    if (!pendingBPs.length && Object.keys(businessPartnerList).length) {
      let bpList: BusinessPartner[] = Object.values(businessPartnerList);
      bpList = bpList
        .filter((bp) => bp.provider !== 'BLOXXTER')
        .filter((bp) => !bp.wphg);
      setPendingBPs(bpList.map((bp) => bp.businessPartnerId));
      if (!bpList.length) {
        setLoading(false);
        return;
      }
      Promise.all(
        bpList.map(({ businessPartnerId }) =>
          readWphg({
            payload: {
              businessPartnerId,
              wphgHistory: false,
            },
          }).then((data) => getBp({ data }, businessPartnerId)(dispatch)),
        ),
      )
        .then(() => setLoading(false))
        .catch((error) => notify({ error }));
    }
  }, [businessPartnerList, pendingBPs.length]);

  const bpsWithInvalidWphg = useMemo(() => {
    const bpList: BusinessPartner[] = Object.values(businessPartnerList);
    return loading
      ? []
      : bpList
          .filter((bp) => bp.provider !== 'BLOXXTER')
          .filter(
            (bp) =>
              !bp.wphg ||
              !isWphgDataValid(bp.wphg) ||
              (bp.wphgExpiresAt && isExpired(bp.wphgExpiresAt)),
          );
  }, [businessPartnerList, loading]);

  return {
    bpsWithInvalidWphg,
  };
};
