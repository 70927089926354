import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgInvestmentNeueEnergienGradient = function (
  props: SVGProps<SVGSVGElement>,
) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill={`url(#${_id})`}
        d="M10.37 28.5H7.71v-1.43H6.15v-1h1.56v-1.72H6.15v-1h1.56v-1.43h2.66c1.64 0 3.01 1.21 3.25 2.79H16c5.57 0 10.11-4.53 10.11-10.11S21.58 4.49 16 4.49 5.89 9.03 5.89 14.61c0 2.09.63 4.1 1.83 5.8l-.82.57a11 11 0 0 1-2.01-6.38C4.89 8.48 9.87 3.49 16 3.49S27.11 8.47 27.11 14.6 22.13 25.71 16 25.71h-2.38a3.29 3.29 0 0 1-3.25 2.79m-.18-1h.18c1.26 0 2.29-1.03 2.29-2.29s-1.03-2.29-2.29-2.29H8.71v4.58h.49v-2.29h1v2.29Zm8.86-8.45c-.51 0-1.06-.05-1.63-.14l.17-.99c1.89.32 3.38.05 4.46-.78 1.18-.92 1.55-2.31 1.66-2.88-3.5-1.65-5.69 1.6-5.78 1.75l-.84-.55s.43-.63 1.18-1.27l-.27-.05c.02-.12.54-2.87-2-4.38-2.54 1.51-2.02 4.26-2 4.38l-.27.05c.75.64 1.17 1.25 1.18 1.27l-.84.55c-.09-.14-2.29-3.38-5.78-1.75.11.59.48 1.97 1.66 2.89 1.07.83 2.57 1.09 4.46.78l.17.99c-2.17.37-3.94.03-5.25-.98-1.86-1.45-2.06-3.74-2.07-3.84v-.41l.26-.14c2.28-1.23 4.15-.72 5.44.09-.03-1.18.27-3.54 2.81-4.88l.23-.12.23.12c2.55 1.35 2.84 3.71 2.81 4.88 1.29-.81 3.16-1.32 5.44-.09l.26.14v.41c0 .1-.21 2.39-2.07 3.84-.96.75-2.18 1.13-3.62 1.13Zm-2.56-1.36h-1v-4h1z"
      />
      <defs>
        <linearGradient
          id={_id}
          x1="0%"
          x2="50%"
          y1="50%"
          y2="0%"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939D4" />
          <stop offset={0.48} stopColor="#5D4DF8" />
          <stop offset={0.74} stopColor="#B459F9" />
          <stop offset={1} stopColor="#FE3B68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgInvestmentNeueEnergienGradient;
