import React, { ComponentProps, ComponentType, ElementType } from 'react';

import {
  SvgAlertCircle,
  SvgCircleCheck,
  SvgCircleX,
  SvgInfoCircle,
} from '@quirion/assets';

import { Icon } from '../Atoms/Icon';
import { ClassStyle } from '../types/ClassStyle.model';

import styles from './Hint.module.css';

export type HintOwnProps = {
  /**
   * Use a different color scheme and intent by setting the variant.
   */
  variant?:
    | 'default'
    | 'inverted'
    | 'success'
    | 'success-inverted'
    | 'warning'
    | 'warning-inverted'
    | 'error'
    | 'error-inverted';
  size?: 'sm' | 'md' | 'lg';
  icon?: ComponentType | keyof JSX.IntrinsicElements;
} & ClassStyle;

const variantIconMap = {
  default: SvgInfoCircle,
  inverted: SvgInfoCircle,
  success: SvgCircleCheck,
  'success-inverted': SvgCircleCheck,
  warning: SvgAlertCircle,
  'warning-inverted': SvgAlertCircle,
  error: SvgCircleX,
  'error-inverted': SvgCircleX,
};

const defaultElement = 'div';

export type HintProps<E extends ElementType = typeof defaultElement> = {
  as?: E;
} & HintOwnProps &
  Omit<ComponentProps<E>, keyof HintOwnProps>;

export const Hint = <E extends ElementType = typeof defaultElement>({
  as,
  children,
  variant = 'default',
  size = 'md',
  className = '',
  style,
  icon,
  ...rest
}: HintProps<E>) => {
  const Element: ElementType = as || defaultElement;
  return (
    <Element
      as={defaultElement}
      {...rest}
      className={[styles.hint, className].join(' | ')}
      style={style}
      data-variant={variant}
      data-size={size}
    >
      <Icon
        className={styles.icon}
        name={icon || variantIconMap[variant]}
        size="custom"
      />
      <span className={styles.text}>{children}</span>
    </Element>
  );
};
