/** Error Messages by Schema */

export enum ErrorMsg {
  CountryISO31661Alpha3 = 'Ungültiges Land (nach ISO 3166-1 alpha-3)',
  Date = 'Ungültiges Datum',
  Email = 'Ungültige E-Mail',
  MobileNumber = 'Ungültige Mobilnummer',
  PostalCode = 'Ungültige Postleitzahl',
  Required = 'Angabe erforderlich',
  TaxId = 'Ungültige Steuer ID (TIN)',
  TaxIdGermany = 'Ungültige deutsche Steuer ID (TIN). Bitte überprüfe die Richtigkeit oder passe das Steuerland an.',
}
