import React, { FC, useEffect, useRef, useState } from 'react';

import { FloatingOverlay } from '@floating-ui/react';
import FocusTrap from 'focus-trap-react';

import { SvgX } from '@quirion/assets';

import { Button } from '../../Atoms/Button';
import { Icon } from '../../Atoms/Icon';
import { Heading } from '../../Typefaces';
import { useAnimationPlayState, useKeyListener } from '../../utils';
import styles from '../CollapsibleSidebar.module.css';
import { SidebarInnerConfig } from '../CollapsibleSidebar.type';

export const CollapsibleSidebar: FC<SidebarInnerConfig> = ({
  id,
  title = undefined,
  content = undefined,
  footer = undefined,
  isVisible,
  variant = 'right',
  hasCloseButton = true,
  onClose,
  afterClose = undefined,
  afterOpened = undefined,
  removeSidebar,
  useOverlay,
  className = '',
  style,
}) => {
  const [focusTrapActive, setFocusTrapActive] = useState(false);
  const abortController = useRef(new AbortController());
  const { animationState, animate } = useAnimationPlayState();
  const sidebarRef = useRef<HTMLDivElement | null>(null);

  const close = () => {
    onClose?.();

    if (afterClose) {
      afterClose();
    }
  };

  useKeyListener('Escape', () => {
    if (isVisible) {
      close();
    }
  });

  // handle animation
  useEffect(() => {
    const listener = (e: TransitionEvent) => {
      if (e.target === sidebarRef.current) {
        if (isVisible) {
          setFocusTrapActive(true);
          abortController.current?.abort();
        } else {
          removeSidebar?.(id);
          sidebarRef.current?.removeEventListener('transitionend', listener);
        }
      }
    };

    abortController.current = new AbortController();
    sidebarRef.current?.addEventListener('transitionend', listener, {
      signal: abortController.current.signal,
    });

    if (isVisible) {
      animate('animateIn');
    } else {
      setFocusTrapActive(false);
      animate('animateOut');
    }

    return () => {
      if (abortController.current) abortController.current.abort();
    };
  }, [isVisible, animate, setFocusTrapActive, removeSidebar, id]);

  useEffect(() => {
    if (afterOpened) {
      afterOpened();
    }
  }, [afterOpened]);

  return (
    <FocusTrap
      active={focusTrapActive}
      focusTrapOptions={{
        allowOutsideClick: true,
        initialFocus: false,
        fallbackFocus: () => sidebarRef.current as HTMLElement,
      }}
    >
      <FloatingOverlay
        className={styles.overlay}
        data-use-overlay={useOverlay}
        data-is-visible={isVisible}
        lockScroll={useOverlay}
      >
        <div
          className={[styles.wrapper, className].join(' | ')}
          style={style}
          data-animation-state={animationState}
          data-variant={variant}
          aria-modal="true"
          aria-labelledby={title ? `collapsibleSidebar-${title}` : ''}
          role="dialog"
          ref={sidebarRef}
        >
          <div className={styles.container} data-has-footer={Boolean(footer)}>
            <div className={styles.header}>
              {title && (
                <Heading
                  tag="h4"
                  variant="h5"
                  id={`collapsibleSidebar-${title}`}
                  hyphenate
                  className={styles.title}
                >
                  {title}
                </Heading>
              )}
            </div>
            <div className={styles.content}>{content || null}</div>
            {footer ? <div className={styles.footer}>{footer}</div> : null}
            {hasCloseButton ? (
              <Button
                onClick={close}
                variant="light"
                size="sm"
                aria-label="Schließen"
                className={styles.close}
              >
                <Icon name={SvgX} size="inline" />
              </Button>
            ) : null}
          </div>
        </div>
      </FloatingOverlay>
    </FocusTrap>
  );
};
