import type { ConditionCategory, PricePackage } from './Condition.types';
import type { EntrypointId } from './Entrypoints.types';
import type { FieldWithAnswer } from './Field.types';
import type { RegularFormStep } from './Step.types';
import type { UtmData } from './UtmData.types';
import type { ShareBondSplit } from '../Strategy';

type SessionBase = {
  id: string;
  journeyId: string;
  currentStepId: string;
  utmData: UtmData;
};

export enum SessionStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
}

/**
 * @alias SustainabilityChoice
 */
export enum SustainabilityPreference {
  No = 'NO',
  Yes = 'YES',
}

export enum SustainabilityDetermination {
  No = 'NO',
  Yes = 'YES',
}

export enum SustainabilityDegree {
  No = 'false',
  Yes = 'true',
}

export enum SustainabilityPai {
  No = 'false',
  Yes = 'true',
}

export enum SustainabilityEcological {
  No = 'NO',
  Yes = 'YES',
}

export type SessionAnswersNewCustomer = {
  META__SESSION_STATUS: SessionStatus;
  META__CONDITION_CATEGORY: ConditionCategory;
  META__ENTRYPOINT_ID: EntrypointId;
  META__PRICE_PACKAGE: PricePackage;
  /**
   * If set only price packages included here are displayed / selectable in the investment proposal.
   */
  META__PRICE_PACKAGE_CUSTOM_AVAILABILITY?: PricePackage[];
  USER_CONSENT__ASSET_MANAGEMENT: 'true';
  USER_CONSENT__RISK: 'true';
  FINANCIAL_DATA__ASSETS__FREE_ASSETS: string;
  FINANCIAL_DATA__ASSETS__LOANS: string;
  FINANCIAL_DATA__ASSETS__TIED_ASSETS: string;
  FINANCIAL_DATA__ASSETS__TOTAL_AVAILABLE_ASSETS: string;
  FINANCIAL_DATA__MONTHLY_FINANCES__AVAILABLE_MONTHLY_INCOME: string;
  FINANCIAL_DATA__MONTHLY_FINANCES__MONTHLY_EXPENSES: string;
  FINANCIAL_DATA__MONTHLY_FINANCES__MONTHLY_NET_INCOME: string;
  INVESTMENT_AMOUNT__MONTHLY_SAVINGS: string;
  INVESTMENT_AMOUNT__ONE_TIME_DEPOSIT: string;
  INVESTMENT_EXPERIENCE__GENERAL: string;
  INVESTMENT_PERIOD__GENERAL: string;
  INVESTOR_PERSON__GENERAL: string;
  INVESTOR_TYPE_CHILD__GENERAL?: string;
  LOSS_MANAGEMENT__GENERAL: string;
  RISK__GENERAL: string;
  META__SHARE_BOND_SPLIT: ShareBondSplit;
  RISK_INVESTMENT_EXPERIENCE__GENERAL?: string;
  RISK_LOTTERY__GENERAL?: string;
  RISK_OCCUPATION__GENERAL?: string;
  RISK_STOCK_CRASHES_BEHAVIOR__GENERAL?: string;
  RISK_STOCK_CRASHES__STOCK_CRASH_2000?: string;
  RISK_STOCK_CRASHES__STOCK_CRASH_2008?: string;
  RISK_WORD_ASSOCIATION__GENERAL?: string;
  // SUSTAINABILITY
  /**
   * Sustainability selection.
   * Determines whether the user has sustainability preferences or not.
   */
  SUSTAINABILITY_PREFERENCES__SUSTAINABILITY_CHOICE: SustainabilityPreference;
  /**
   * Sustainability determination
   * Determines whether the user wants to make sustainability preferences on its own
   * or letting quirion make the choice.
   */
  SUSTAINABILITY_PREFERENCES__SUSTAINABILITY_DETERMINATION: SustainabilityDetermination;
  /**
   * Sustainability Investions
   * Determines if the user wants sustainable investments
   */
  SUSTAINABILITY_SELECTION__SUSTAINABILITY_DEGREE: SustainabilityDegree;
  /**
   * Specific sustainability extend.
   * @see SustainabilityPreferencesType.specificSustainabilityDegree
   */
  SUSTAINABILITY_SELECTION__SUSTAINABILITY_DEGREE_SELECTION: string;
  /**
   * Sustainability PAI
   * Determines if the user wants PAI or not.
   */
  SUSTAINABILITY_SELECTION__PAI: SustainabilityPai;
  /**
   * Selected PAIs to pay atttention to.
   * @see SustainabilityPreferencesType.paiSelection;
   */
  SUSTAINABILITY_SELECTION__PAI_SELECTION: string[];
  /**
   * Ecological portfolio?
   * @see SustainabilityPreferencesType.ecologicalSelected
   */
  SUSTAINABILITY_SELECTION__ECOLOGICAL_SELECTION?: string;
};

export type SessionAnswersExistingCustomerUpdateWphg =
  SessionAnswersExistingCustomer & {
    META__ENTRYPOINT_ID: EntrypointId.ExistingCustomerUpdateBp;
    META__SESSION_STATUS: SessionStatus;
    FINANCIAL_DATA__ASSETS__FREE_ASSETS: string;
    FINANCIAL_DATA__ASSETS__LOANS: string;
    FINANCIAL_DATA__ASSETS__TIED_ASSETS: string;
    FINANCIAL_DATA__ASSETS__TOTAL_AVAILABLE_ASSETS: string;
    FINANCIAL_DATA__MONTHLY_FINANCES__AVAILABLE_MONTHLY_INCOME: string;
    FINANCIAL_DATA__MONTHLY_FINANCES__MONTHLY_EXPENSES: string;
    FINANCIAL_DATA__MONTHLY_FINANCES__MONTHLY_NET_INCOME: string;
    INVESTOR_PERSON__GENERAL: string;
    INVESTOR_TYPE_CHILD__GENERAL?: string;
    INVESTMENT_EXPERIENCE__GENERAL: string;
    LOSS_MANAGEMENT__GENERAL: string;
    RISK__GENERAL: string;
  };

export type SessionAnswersExistingCustomer = SessionAnswersNewCustomer & {
  META__BUSINESS_PARTNER_ID: string;
  META__SUBSCRIPTION_BALANCE: string;
};

export type SessionAnswersExistingCustomerStrategyChange =
  SessionAnswersExistingCustomer & {
    META__IPS_ID: string;
  };

export type SessionAnswersTopics = SessionAnswersNewCustomer & {
  TOPIC_SELECTION__GENERAL: string;
};

export type SessionAnswersTangibleAssets = {
  META__SESSION_STATUS: SessionStatus;
  TANGIBLE_ASSETS_SELECTION__GENERAL: string;
  META__ENTRYPOINT_ID: EntrypointId;
  CONSENT__DIGITAL_SECURITY: 'true';
  CONSENT__KNOWLEDGE: 'true';
  FINANCIAL_DATA__ASSETS__FREE_ASSETS: string;
  FINANCIAL_DATA__ASSETS__LOANS: string;
  FINANCIAL_DATA__ASSETS__TIED_ASSETS: string;
  FINANCIAL_DATA__ASSETS__TOTAL_AVAILABLE_ASSETS: string;
  FINANCIAL_DATA__MONTHLY_FINANCES__AVAILABLE_MONTHLY_INCOME: string;
  FINANCIAL_DATA__MONTHLY_FINANCES__MONTHLY_EXPENSES: string;
  FINANCIAL_DATA__MONTHLY_FINANCES__MONTHLY_NET_INCOME: string;
  INVESTMENT_AMOUNT__ONE_TIME_DEPOSIT: string;
  INVESTOR_PERSON__GENERAL: 'ME';
};

export enum SessionType {
  ExistingCustomerVV = 'existingCustomer',
  NewCustomerVV = 'newCustomer',
  ExistingCustomerChangeStrategy = 'existingCustomerChangeStrategy',
  ExistingCustomerUpdateWphg = 'existingCustomerUpdateWphg',
  TangibleAssets = 'tangibleAssets',
  Topics = 'topics',
}

export type SessionTypeGeneric =
  | SessionType.ExistingCustomerVV
  | SessionType.NewCustomerVV
  | SessionType.Topics;

export type SessionAnswers<T = SessionType> =
  T extends SessionType.NewCustomerVV
    ? SessionAnswersNewCustomer
    : T extends SessionType.ExistingCustomerVV
      ? SessionAnswersExistingCustomer
      : T extends SessionType.TangibleAssets
        ? SessionAnswersTangibleAssets
        : T extends SessionType.Topics
          ? SessionAnswersTopics
          : T extends SessionType.ExistingCustomerChangeStrategy
            ? SessionAnswersExistingCustomerStrategyChange
            : T extends SessionType.ExistingCustomerUpdateWphg
              ? SessionAnswersExistingCustomerUpdateWphg
              : never;

export type SessionAnswersGeneric =
  SessionAnswers<SessionType.ExistingCustomerVV> &
    SessionAnswers<SessionType.NewCustomerVV> &
    SessionAnswers<SessionType.Topics>;

export type Session<T = SessionType> = SessionBase & {
  answers: SessionAnswers<T>;
};

export type SessionProtocol = (RegularFormStep & {
  fields: FieldWithAnswer[];
})[];
