export const MIN_INVEST = 25;
export const MIN_INVEST_WITH_CONSULTANT = 25000;
export const MIN_INVEST_TANGIBLE_ASSETS = 500;

export const getMinInvest = ({
  isExistingPrivate,
  isTangibleAssets,
}: {
  /**
   * For existing customers with the private package,
   * no minimum investment is needed when adding new
   * products / IPS, as it is assumed their balance
   * still meets the minimum investment amount.
   */
  isExistingPrivate: boolean;
  isTangibleAssets: boolean;
}) => {
  if (isTangibleAssets) return MIN_INVEST_TANGIBLE_ASSETS;
  if (isExistingPrivate) return MIN_INVEST_WITH_CONSULTANT;
  return MIN_INVEST;
};

const MAX_INVEST = 5000000;
const MAX_INVEST_TANGIBLE_ASSETS = 25000;

export const getMaxInvest = ({
  isTangibleAssets,
}: {
  isTangibleAssets: boolean;
}) => {
  if (isTangibleAssets) {
    return MAX_INVEST_TANGIBLE_ASSETS;
  }
  return MAX_INVEST;
};

export const MIN_SAVINGS_RATE = 25;
export const MAX_SAVINGS_RATE = 5000000;

export const MIN_EXPENSES = 1;
