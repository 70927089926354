import React, { FC } from 'react';

import { StackTemplateObject } from '../types/Banner.types';

import styles from './StackTemplates.module.css';

export type NetworkErrorContent = {
  url: string;
  stack: string;
  body: string;
};

type NetworkErrorProps = StackTemplateObject<NetworkErrorContent>;

const NetworkError: FC<NetworkErrorProps> = (props) => {
  const { content } = props;

  return (
    <div className={[styles.contained, styles.moreInfo].join(' ')}>
      {content?.url && <div>{content.url}</div>}
      {content?.body && <div>{content.body}</div>}
      {content?.stack && <div>{content.stack}</div>}
    </div>
  );
};

export type CustomErrorProps = StackTemplateObject<React.ReactNode>;

const CustomError: FC<CustomErrorProps> = (props) => {
  const { content } = props;

  return <div>{content}</div>;
};

export const StackTemplate = {
  NetworkError,
  CustomError,
};
