import { AuthConfig } from '@quirion/types';
import { AuthHelper } from './utils/AuthHelper';
import { AUTH_CONFIG_BANKING } from '../constants';

export const logout = (
  authConfig: AuthConfig = AUTH_CONFIG_BANKING,
): Promise<void> =>
  new Promise((resolve) => {
    const auth = new AuthHelper(authConfig);
    auth.removeChallenge();
    auth.removeAuth();
    resolve();
  });
