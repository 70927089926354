import { BusinessPartnerItem } from '@quirion/api/types';
import {
  getOrderStatusText,
  getOrderTypeText,
  ORDER_TYPE_LABELS,
  OrderStatusText,
} from '@quirion/utils';

export const isOrderAPendingSubscriptionChangeRequest = (
  order: BusinessPartnerItem,
) =>
  getOrderStatusText(order.status) === OrderStatusText.OPEN &&
  getOrderTypeText(order.orderId) === ORDER_TYPE_LABELS.SUBSCRIPTION;
