import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgBpChild = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill={`url(#${_id})`}
        fillRule="nonzero"
        d="M15.33 16.29c.86 0 1.55.69 1.55 1.55V19c.12 2.3-1.66 3.35-4.72 3.35s-4.88-1.05-4.88-3.34v-1.18c0-.86.69-1.55 1.55-1.55h6.5zm0 .74h-6.5c-.45 0-.81.36-.81.82v1.18c0 1.63 1.13 2.6 4.15 2.6.96 0 1.72-.09 2.29-.27.68-.21 1.12-.55 1.39-.98.23-.37.34-.83.31-1.37v-1.16c0-.45-.36-.82-.81-.82zm1.96-6.64v-.01h4.55c.86 0 1.55.69 1.55 1.55v1.17l.01.22v.02c0 2.14-1.77 3.13-4.73 3.13a12 12 0 0 1-.93-.04c-.14-.33-.37-.61-.66-.81a9 9 0 0 0 1.38.11v-.01h.22c.96 0 1.72-.09 2.29-.27.68-.21 1.12-.55 1.39-.98.21-.33.31-.73.32-1.19v-1.33c0-.45-.36-.81-.82-.81H16.1V11c0-.2-.02-.4-.05-.6h1.26zM2.23 15.73C1.29 15.19.77 14.32.77 13.1v-1.18c0-.86.69-1.55 1.55-1.55h4.55v.01h1.26c-.03.2-.04.4-.04.6v.14H2.33c-.45 0-.81.36-.81.81v1.18c0 .88.33 1.56 1.1 2.01.66.38 1.65.58 3.03.58h.06v.01a10 10 0 0 0 1.55-.11 2.2 2.2 0 0 0-.72.81l-.88.04c-1.44 0-2.6-.23-3.43-.71zM12.08 8c1.67 0 3.03 1.36 3.03 3.03s-1.36 3.03-3.03 3.03-3.03-1.36-3.03-3.03S10.41 8 12.08 8m0 .74a2.3 2.3 0 0 0-2.29 2.29 2.3 2.3 0 0 0 2.29 2.29 2.3 2.3 0 0 0 2.29-2.29 2.3 2.3 0 0 0-2.29-2.29M5.57 2.1c1.67 0 3.03 1.36 3.03 3.03S7.24 8.15 5.57 8.15 2.54 6.8 2.54 5.12 3.9 2.1 5.57 2.1m13.01 0c1.67 0 3.03 1.36 3.03 3.03s-1.36 3.03-3.03 3.03-3.03-1.36-3.03-3.03 1.36-3.03 3.03-3.03m-13.01.74a2.3 2.3 0 0 0-2.29 2.29 2.3 2.3 0 0 0 2.29 2.29 2.3 2.3 0 0 0 2.29-2.29 2.3 2.3 0 0 0-2.29-2.29m13.01 0a2.3 2.3 0 0 0-2.29 2.29 2.3 2.3 0 0 0 2.29 2.29 2.3 2.3 0 0 0 2.29-2.29 2.3 2.3 0 0 0-2.29-2.29"
      />
      <defs>
        <linearGradient
          id={_id}
          x1={0}
          x2={22.48}
          y1={18.01}
          y2={3.96}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939d4" />
          <stop offset={0.48} stopColor="#5d4df8" />
          <stop offset={0.74} stopColor="#b459f9" />
          <stop offset={1} stopColor="#fe3b68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgBpChild;
