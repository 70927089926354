/**
 * @see https://github.com/validatorjs/validator.js
 */
export const IS_TAX_ID_SUPPORTED_LOCALES = [
  'bg-BG',
  'cs-CZ',
  'de-AT',
  'de-DE',
  'dk-DK',
  'el-CY',
  'el-GR',
  'en-CA',
  'en-GB',
  'en-IE',
  'en-US',
  'es-ES',
  'et-EE',
  'fi-FI',
  'fr-BE',
  'fr-CA',
  'fr-FR',
  'fr-LU',
  'hr-HR',
  'hu-HU',
  'it-IT',
  'lb-LU',
  'lt-LT',
  'lv-LV',
  'mt-MT',
  'nl-BE',
  'nl-NL',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ro-RO',
  'sk-SK',
  'sl-SI',
  'sv-SE',
];

/**
 * @see https://github.com/validatorjs/validator.js
 */
export const IS_POSTAL_CODE_SUPPORTED_LOCALES = [
  'AD',
  'AT',
  'AU',
  'AZ',
  'BA',
  'BE',
  'BG',
  'BR',
  'BY',
  'CA',
  'CH',
  'CN',
  'CZ',
  'DE',
  'DK',
  'DO',
  'DZ',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HR',
  'HT',
  'HU',
  'ID',
  'IE',
  'IL',
  'IN',
  'IR',
  'IS',
  'IT',
  'JP',
  'KE',
  'KR',
  'LI',
  'LK',
  'LT',
  'LU',
  'LV',
  'MG',
  'MT',
  'MX',
  'MY',
  'NL',
  'NO',
  'NP',
  'NZ',
  'PL',
  'PR',
  'PT',
  'RO',
  'RU',
  'SA',
  'SE',
  'SG',
  'SI',
  'SK',
  'TH',
  'TN',
  'TW',
  'UA',
  'US',
  'ZA',
  'ZM',
];
