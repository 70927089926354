export default [
  { 63.11: 'Information und Kommunikation' },
  { '86.10': 'Gesundheitswesen' },
  { 90.01: 'Kunst, Unterhaltung und Erholung' },
  { 10.83: 'Verarbeitendes Gewerbe' },
  { 66.19: 'Finanz- und Versicherungsdienstleistungen' },
  { 47.19: 'Handel' },
  { '56.10': 'Gastronomie' },
  { '70.10': 'Public-Relations- und Unternehmensberatung' },
  { '79.10': 'Reisedienstleistungen' },
  { 35.11: 'Energieversorgung' },
  { '36.00': 'Wasserversorgung' },
  { '55.10': 'Gastgewerbe' },
  { '05.10': 'Bergbau' },
  { '01.11': 'Land- und Forstwirtschaft, Fischerei' },
  { '68.10': 'Grundstücks- und Wohnungswesen' },
  { 82.11: 'Sonstige Dienstleistungen' },
  { '69.10': 'Rechts- und Steuerberatung, Wirtschaftsprüfung' },
  { '41.20': 'Baugewerbe' },
  { '85.10': 'Erziehung und Unterricht' },
  { '45.20': 'Instandhaltung und Reparatur von Kfz.' },
  { '49.10': 'Verkehr und Lagerei' },
];
