import React, { CSSProperties, FC, PropsWithChildren } from 'react';

import styles from './RamGrid.module.css';

/**
 * @description This is a css grid using the repeat, auto, minmax (RAM) features.
 * This way it is completely responsive
 */
export type RamGridProps = PropsWithChildren<{
  className?: string;
  /**
   * Sets the minimum width of the grid item.
   * Is required but defaults to 150px.
   * @default 150px
   */
  minWidth: string;
  gap?: string;
  style?: CSSProperties;
}>;

const RamGrid: FC<RamGridProps> = ({
  children,
  className = '',
  minWidth,
  gap = 'var(--space-4)',
  style,
}) => (
  <section
    className={[styles.grid, className].join(' ')}
    style={{
      '--min-width': minWidth,
      '--gap': gap,
      ...style,
    }}
  >
    {children}
  </section>
);

export { RamGrid };
