import { WphgDetailsExistingCustomer } from '@quirion/types';

const isWphgDataIncomplete = (wphg: Partial<WphgDetailsExistingCustomer>) =>
  [
    wphg?.wphg_liquid_assets,
    wphg?.wphg_liabilities,
    wphg?.wphg_illiquid_assets,
    wphg?.wphg_total_assets,
    wphg?.wphg_expenses,
    wphg?.wphg_net_income,
    wphg?.wphg_knowledge_self,
    wphg?.wphg_drawdown_capacity,
    wphg?.wphg_risk_preference,
  ].some((value) => {
    return (
      value === undefined || value === null || value === '' || value === 'NaN'
    );
  });

export const isWphgDataValid = (
  wphg: WphgDetailsExistingCustomer | undefined,
): boolean => !!wphg && !isWphgDataIncomplete(wphg);
