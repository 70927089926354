/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { NetworkError } from '@quirion/api';

import { StackTemplates } from '../../../types';
import { NotificationInput } from '../types/Banner.types';

export const getErrorDetails = async (notificationInput: NotificationInput) => {
  const { error }: { error?: any } = notificationInput;
  let { description, title } = notificationInput;
  let stack;

  // Handle errors with a JSON response
  if (error.json) {
    const e = await error.json().then((res: any) => res.error || res);
    title = title || e.header || e.errorCode || e.name || e.error;
    description = description || e.message || e.cause;
    console.error(e);
  }
  // Handle standard errors with embedded error objects
  else if (error.error) {
    title = title || error.header || error.name;
    description = description || error.message || error.cause;
    console.error(error.error);
  }
  // Handle instances of Error
  else if (error instanceof Error) {
    title = title || error.name;
    description = description || error.message;
    stack = error.stack;
    console.error(error);
  }
  // Handle other types of errors, such as NetworkErrors with custom stack templates
  else {
    console.error(error);
    title = title || error.message;
    description = description || error.description;
    stack = {
      templateName:
        error instanceof NetworkError
          ? StackTemplates.NetworkError
          : StackTemplates.CustomError,
      content: {
        url: error.url,
        stack: error.stack,
        body: error.body,
      },
    };
  }

  return { title, description, stack };
};
