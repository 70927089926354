import { APICallInputWithPayload } from '../types';
import { extendedFetch } from '../utils/extendedFetch';
import { getEndpointsDefinition } from '../utils/getEndpointsDefinition';
import {
  GetCostSheetPayload,
  GetCostSheetResponse,
} from './getCostSheet.types';

const PAYLOAD_DEFAULTS = {
  yearsTillNoIncome: 0,
};

export const getCostSheet = ({
  endpointsDefinition = getEndpointsDefinition(),
  payload,
}: APICallInputWithPayload<GetCostSheetPayload>): Promise<GetCostSheetResponse> =>
  extendedFetch({
    endpointConfig: endpointsDefinition.endpoints.costsheet,
    payload: {
      ...PAYLOAD_DEFAULTS,
      ...payload,
    },
  }).then((res) => res.json());
