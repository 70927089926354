import React, { FC } from 'react';

import { Stack } from '../Container/Stack';
import { Logo } from '../Logo';
import { ClassStyle } from '../types/ClassStyle.model';

import { Loader } from './Loader';

import styles from './WrappedLoader.module.css';

export type WrappedLoaderProps = {
  global?: boolean;
  logo?: boolean;
  children?: React.ReactNode;
} & ClassStyle;

export const WrappedLoader: FC<WrappedLoaderProps> = ({
  global = false,
  logo = false,
  className = '',
  style,
  children = null,
}) => (
  <div
    className={[
      className,
      global ? styles['loader-global'] : styles.loader,
    ].join(' ')}
    style={style}
  >
    <Stack justifyItems="center" gap="var(--space-4)">
      {!!logo && (
        <Logo
          variant="imageOnly"
          blockSize="var(--sizes-15)"
          inlineSize="var(--sizes-15)"
        />
      )}
      <Loader width="var(--sizes-30)" height="var(--sizes-3)" />
      {children}
    </Stack>
  </div>
);
