/* eslint-disable @typescript-eslint/no-explicit-any */
import { createElement, FC, FunctionComponent, ReactNode } from 'react';

import { useRouteError } from 'react-router-dom';

import { ErrorBoundaryFallbackProps } from '../types';

import { ErrorBoundaryDefaultFallback } from './ErrorBoundaryDefaultFallback';

type ErrorRouterBoundaryProps = {
  children?: ReactNode;
  fallback?: FunctionComponent<Partial<ErrorBoundaryFallbackProps>>;
};

export const ErrorRouterBoundary: FC<ErrorRouterBoundaryProps> = ({
  children,
  fallback,
}) => {
  const error: any = useRouteError();

  const fallbackProps: ErrorBoundaryFallbackProps = {
    hasError: true,
    error,
    errorName:
      error?.status === 404 ? 'Seite nicht gefunden' : error?.statusText,
    errorMessage: error?.status === 404 ? error?.error?.message : error?.data,
    componentStack: error?.error?.stack,
  };

  if (fallback) {
    return createElement(fallback, fallbackProps);
  }

  return (
    <ErrorBoundaryDefaultFallback {...fallbackProps}>
      {children}
    </ErrorBoundaryDefaultFallback>
  );
};
