import isDate from 'validator/lib/isDate';
import isISO31661Alpha3 from 'validator/lib/isISO31661Alpha3';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isPostalCode from 'validator/lib/isPostalCode';
import isTaxID from 'validator/lib/isTaxID';
import { string } from 'yup';

import { COUNTRIES_ALPHA3_ALPHA2, ISO31661Alpha3ToLocale } from '@quirion/data';

import {
  IS_POSTAL_CODE_SUPPORTED_LOCALES,
  IS_TAX_ID_SUPPORTED_LOCALES,
} from './constants';
import { ErrorMsg } from './errors';

import type { PostalCodeLocale } from 'validator/lib/isPostalCode';

/**
 * # Validation Schema
 * Handles data validation via a validation library.
 * Validation Schemas provide validation presets for specific data types.
 * See https://github.com/jquense/yup
 * See https://github.com/validatorjs/validator.js/
 *
 * Test function: https://github.com/jquense/yup#mixedtestname-string-message-string--function-test-function-schema
 */

export const qValidationSchema = {
  email: string().email(ErrorMsg.Email).required(ErrorMsg.Required),

  mobileNumber: string().test('mobileNumber', ErrorMsg.MobileNumber, (value) =>
    value ? isMobilePhone(`${value}`, 'any', { strictMode: true }) : true,
  ),
  date: string().test('date', ErrorMsg.Date, (value) =>
    value ? isDate(`${value}`) : true,
  ),
  countryISO31661Alpha3: string()
    .length(3)
    .test('ISO31661Alpha3', ErrorMsg.CountryISO31661Alpha3, (value) =>
      value ? isISO31661Alpha3(`${value}`) : true,
    ),
  postalCode: string().when('residenceCountry', ([country], schema) => {
    if (country in COUNTRIES_ALPHA3_ALPHA2) {
      const alpha2code =
        COUNTRIES_ALPHA3_ALPHA2[
          country as keyof typeof COUNTRIES_ALPHA3_ALPHA2
        ];

      if (new Set(IS_POSTAL_CODE_SUPPORTED_LOCALES).has(alpha2code)) {
        return schema.test('postalCode', ErrorMsg.PostalCode, (value) =>
          value
            ? isPostalCode(`${value}`, alpha2code as PostalCodeLocale)
            : true,
        );
      }
    }

    return schema.test('postalCode', ErrorMsg.PostalCode, (value) =>
      value ? isPostalCode(`${value}`, 'any') : true,
    );
  }),
  /**
   * Validated the tax ID (TIN) of a specific country as yup schema
   * @param countryName The locale of the country to validated against (e.g. `en-US`)
   */
  taxId: string().when('country', ([country], schema) => {
    if (!country)
      return schema.test('taxId', ErrorMsg.TaxIdGermany, (value) =>
        value ? isTaxID(`${value}`.replaceAll(' ', ''), 'de-DE') : true,
      );

    const getLocale = () => {
      // get locale for given country code
      if (!(country in ISO31661Alpha3ToLocale)) return false;
      const locale =
        ISO31661Alpha3ToLocale[
          country as keyof typeof ISO31661Alpha3ToLocale
        ] ?? false;

      // return locale if locale is supported by `isTaxID` or `false`
      if (!locale) return false;
      if (Array.isArray(locale))
        return locale.find((l) => IS_TAX_ID_SUPPORTED_LOCALES.includes(l));
      return new Set(IS_TAX_ID_SUPPORTED_LOCALES).has(locale) && locale;
    };

    const locale = getLocale();

    return locale
      ? schema.test('taxId', `${ErrorMsg.TaxId} (${locale})`, (value) =>
          value ? isTaxID(`${value}`.replaceAll(' ', ''), locale) : true,
        )
      : schema.test('taxId', (value) =>
          value ? typeof value === 'string' : true,
        );
  }),
  // Add new schemas here...
};
