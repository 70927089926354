import type { SVGProps } from 'react';
const SvgCreditCard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={1.5}
    className="credit-card_svg__icon credit-card_svg__icon-tabler credit-card_svg__icon-tabler-credit-card"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <rect width={18} height={14} x={3} y={5} rx={3} />
    <path d="M3 10h18M7 15h.01M11 15h2" />
  </svg>
);
export default SvgCreditCard;
