import { ParsedAccessToken } from '@quirion/types';

export function parseAccessToken(
  token: string | undefined,
): ParsedAccessToken | undefined {
  const split = token?.split('.');
  return split
    ? [JSON.parse(atob(split[0])), JSON.parse(atob(split[1])), split[2]]
    : undefined;
}
