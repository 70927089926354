import { useEffect } from 'react';

export const useKeyListener = (key: string, func: () => void) => {
  useEffect(() => {
    function keyListener(event: Event & { key: string }) {
      if (event.key !== key) {
        return;
      }
      func();
    }
    window.addEventListener('keydown', keyListener);

    return () => window.removeEventListener('keydown', keyListener);
  }, [key, func]);
};
