import { PricePackage } from '@quirion/types';

export const isPrivatePackage = ({
  pricePackage,
}: {
  pricePackage: string | undefined;
}) => {
  if (pricePackage) return pricePackage === PricePackage.Private;
  return false;
};
