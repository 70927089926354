import { isJSON } from './isJSON';

/**
 * Safely parse JSON strings
 * @param value - The string to parse
 */
export const safeJSONParse = (value: string | null | undefined) => {
  if (value === null || value === undefined || !isJSON(value)) {
    return {};
  }

  return JSON.parse(value);
};
