import { isISOString } from '@quirion/utils';

/**
 * isExpired - Checks if passed date has passed
 * @param {string} expiresAt some date that can be used by Date constructor
 * @returns {boolean} true if expiresAt has passed
 */

export function isExpired(expiresAt: string): boolean {
  // if no date or a faulty date is given, treat it as if it were expired
  if (!expiresAt) {
    return true;
  }
  try {
    if (!isISOString(new Date(expiresAt).toISOString())) {
      return true;
    }
  } catch (e) {
    return true;
  }
  return new Date() > new Date(expiresAt);
}
