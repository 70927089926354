import { PhoneNumbers } from '../constants';

const TANGIBLE_ASSETS_CONTACT_PROMPT = `Möchten Sie dennoch mehr Geld anlegen, wenden Sie sich bitte telefonisch unter ${PhoneNumbers.SupportHotline} an unsere Kundenbetreuung.`;

export const ERROR_VALIDATION_MESSAGES = {
  DEFAULT_CHECKBOX: 'Bitte bestätigen Sie dieses Feld.',
  DEFAULT_RADIO: 'Bitte wählen Sie eine Option aus.',
  DEFAULT_INPUT: 'Bitte geben Sie einen Wert ein.',
  DEFAULT_RADIO_OR_INPUT:
    'Bitte wählen Sie eine Option aus oder geben Sie einen Wert ein.',
  ONE_TIME_DEPOSIT_MAX: 'Sie können höchstens #amount eingeben.',
  ONE_TIME_DEPOSIT_TANGIBLE_ASSETS_MAX: `Sie können höchstens #amount eingeben. ${TANGIBLE_ASSETS_CONTACT_PROMPT}`,
  ONE_TIME_DEPOSIT_MIN: 'Der Mindestanlagebetrag beträgt #amount.',
  ONE_TIME_DEPOSIT_TANGIBLE_ASSETS_MIN:
    'Der Mindestzeichnungsbetrag beträgt #amount.',
  EXPENSES_MIN: 'Bitte geben Sie mindestens #amount ein.',
  FREE_ASSETS_SINGLE_MIN:
    'Ihr frei verfügbares Vermögen muss größer sein als Ihr Anlagebetrag bei quirion.',
  FREE_ASSETS_COUPLE_MIN:
    'Ihr gemeinsames frei verfügbares Vermögen muss größer sein als Ihr Anlagebetrag bei quirion.',
  MONTHLY_NET_INCOME_MIN:
    'Ihr Einkommen abzüglich Ihrer Ausgaben reicht nicht aus, Ihren monatlichen Sparbetrag abzudecken.',
  MONTHLY_SAVINGS_MIN: 'Der Mindestsparbetrag beträgt #amount.',
  MONTHLY_SAVINGS_MAX: 'Sie können höchstens #amount eingeben.',
  MONTHLY_SAVINGS_DEFAULT: 'Der monatliche Betrag ist ungültig.',
  INVEST_OR_SAVINGS:
    'Wählen Sie eine einmalige Einzahlung und/oder einen Sparbetrag.',
  EMAIL_REQUIRED: 'Bitte geben Sie eine E-Mail-Adresse ein.',
  EMAIL_WRONG_FORMAT: 'Dies ist keine valide E-Mail-Adresse.',
  PASSWORD_WRONG_FORMAT:
    'Das Passwort muss die angezeigten Kriterien erfüllen.',
  PASSWORD_REQUIRED: 'Bitte geben Sie ein Passwort ein.',
  PASSWORD_MISMATCH: 'Die angegebenen Passwörter sind nicht identisch.',
  ECOLOGICAL_SELECTION_NOT_SUPPORTED:
    'Wir können Ihnen derzeit kein Angebot machen, das Ihren Nachhaltigkeitspräferenzen entspricht. Bitte entscheiden Sie, ob Sie Ihre Vorgaben zu Ihren Nachhaltigkeitspräferenzen dahingehend anpassen möchten.',
  SUSTAINABLE_DEGREE_SELECTION:
    'Wir können Ihnen derzeit kein Angebot machen, das Ihren Nachhaltigkeitspräferenzen entspricht. Bitte entscheiden Sie, ob Sie Ihre Vorgaben zu Ihren Nachhaltigkeitspräferenzen dahingehend anpassen möchten. Es können maximal 90% in nachhaltige Investments getätigt werden.',
  DISBURSEMENT_NOT_POSSIBLE: 'Keine Auszahlung möglich.',
  DISBURSEMENT_MAX: 'Sie können sich maximal #amount auszahlen lassen.',
  DISBURSEMENT_MIN: 'Geben Sie bitte einen Betrag größer #amount an.',
  INPUT_REQUIRED: 'Eine Angabe ist erforderlich',
};
