import React, {
  ComponentProps,
  ComponentPropsWithoutRef,
  CSSProperties,
  ElementType,
  FC,
  forwardRef,
  useMemo,
} from 'react';

import { NavItem, NavItemOwnProps } from '../NavItem';
import { ClassStyle } from '../types/ClassStyle.model';

import { OverflowMode } from './OverflowMenu.types';
import { useOverflowContext } from './OverflowProvider';

import styles from './OverflowMenu.module.css';

export type OverflowExtraProps = {
  /**
   * This key is used to identify your overflowing item.
   */
  itemKey: string;
  overflowStyle?: CSSProperties;
  /**
   * Adjust how your item should overflow:
   *
   * - "auto" is the default behaviour. Will flow with space.
   * - "always" will make the item always be in the overflowing.
   * - "hidden" will completely hide the item and remove it from the flow.
   */
  overflowMode?: OverflowMode;
};

export type OverflowItemProps = ComponentPropsWithoutRef<'span'> &
  OverflowExtraProps &
  ClassStyle;

/**
 * Key part of the overflowing menu functionality. The logic will check for key
 * and size of these and return the itemKeys that are overflowing.
 *
 * Make sure to either wrap your items with this or use a version that does it
 * for you.
 */
export const OverflowItem: FC<OverflowItemProps> = ({
  children,
  className = '',
  itemKey,
  overflowStyle,
  overflowMode = 'auto',
  ...rest
}) => {
  const { overflowingItems } = useOverflowContext();
  const isVisible = useMemo(
    () => !overflowingItems.includes(itemKey),
    [itemKey, overflowingItems],
  );

  // hide hidden items
  if (overflowMode === 'hidden') {
    return null;
  }

  return (
    <span
      data-is-visible={isVisible}
      data-overflow-mode={overflowMode}
      data-targetid={itemKey}
      className={[styles.item, className].join(' ')}
      style={overflowStyle}
      {...rest}
    >
      {children}
    </span>
  );
};

export type MoreNavItemProps = NavItemOwnProps & {
  isVisible: boolean;
  overflowClass?: string;
  overflowStyle?: CSSProperties;
};

export const OverflowMoreNavItem = forwardRef<
  HTMLSpanElement,
  MoreNavItemProps
>(
  (
    {
      isVisible,
      overflowClass = '',
      overflowStyle,
      active,
      label,
      className,
      style,
      icon,
      customIcon,
      horizontal,
      ...rest
    }: MoreNavItemProps,
    ref,
  ) => (
    <span
      className={[styles.item, overflowClass].join(' ')}
      style={overflowStyle}
      data-is-visible={isVisible}
      ref={ref}
      data-is-more
      {...rest}
    >
      <NavItem
        active={active}
        label={label}
        className={className}
        style={style}
        horizontal={horizontal}
        icon={icon}
        customIcon={customIcon}
      />
    </span>
  ),
);

export type OverflowMoreItemOwnProps = {
  isVisible: boolean;
};

const defaultElement = 'span';

export type OverflowMoreItemProps<
  E extends ElementType = typeof defaultElement,
> = {
  as?: E;
} & OverflowMoreItemOwnProps &
  Omit<ComponentProps<E>, keyof OverflowMoreItemOwnProps>;

export const OverflowMoreItem = <
  E extends ElementType = typeof defaultElement,
>({
  as,
  children,
  isVisible,
  className,
  style,
  ...rest
}: OverflowMoreItemProps<E>) => {
  const Element: ElementType = as || defaultElement;
  return (
    <Element
      className={[styles.item, className].join(' ')}
      style={style}
      data-is-visible={isVisible}
      data-is-more
      {...rest}
    >
      {children}
    </Element>
  );
};
