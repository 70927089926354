import type { SVGProps } from 'react';
const SvgTransferIn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={1.5}
    className="transfer-in_svg__icon transfer-in_svg__icon-tabler transfer-in_svg__icon-tabler-transfer-in"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <path d="M4 18v3h16V7l-8-4-8 4v3M4 14h9" />
    <path d="m10 11 3 3-3 3" />
  </svg>
);
export default SvgTransferIn;
