import type { FC } from 'react';

import { Provider } from 'react-redux';
import {
  createBrowserRouter,
  RouterProvider,
  ScrollRestoration,
} from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import '@quirion/assets/src/fonts/gilroy/fonts.css';
import {
  BannerProvider,
  Caption,
  ErrorBoundary,
  ErrorRouterBoundary,
  SidebarProvider,
  WrappedLoader,
} from '@quirion/components';
import '@quirion/theme/src/global.css';
import '@quirion/theme/src/quirion/light2.0/build/css/_variables.css';
import '@quirion/theme/src/quirion/typography/v2/build/css/_typography.css';
import { getOnboardingUrl } from '@quirion/utils';
import type { Env } from '@quirion/utils';

import { BrowserTitle } from 'lib/components';
import { ChecksProvider } from 'lib/contexts/ChecksContext';
import { TrackingProvider } from 'lib/contexts/TrackingContext';
import { useInitApp } from 'lib/hooks';
import rootReducer from 'lib/redux/reducers/rootReducer';

import { getSubNavigationsAsArray } from 'pages/_utils';

import '../../app.css';
import { AppRoutes } from '../_constants/AppRoutes';
import { useCheckAuthentication } from '../_hooks/useCheckAuthentication';

import { AppFrame } from './AppFrame';
import { AppStatusIndicators } from './AppStatusIndicators';

import type { NonIndexRouteObject } from 'react-router-dom';

// To use the redux devtools extension (e.g. in Chrome) as implemented below, see https://github.com/zalmoxisus/redux-devtools-extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));
const store = createStore(rootReducer, enhancer);

const Main: FC = () => {
  useCheckAuthentication();
  const { status, LOADING_MSG } = useInitApp();

  return (
    <>
      {status === 'OK' ? (
        <AppFrame />
      ) : (
        <WrappedLoader logo global>
          <Caption>{status ? String(status) : LOADING_MSG}</Caption>
        </WrappedLoader>
      )}

      <AppStatusIndicators />
    </>
  );
};

const RootStart: FC = () => (
  <ErrorBoundary>
    <Provider store={store}>
      <TrackingProvider>
        <ChecksProvider>
          <BannerProvider>
            <SidebarProvider>
              <ScrollRestoration />
              <BrowserTitle />
              <Main />
            </SidebarProvider>
          </BannerProvider>
        </ChecksProvider>
      </TrackingProvider>
    </Provider>
  </ErrorBoundary>
);

const routes: NonIndexRouteObject[] = [
  {
    path: '/',
    element: <RootStart />,
    children: getSubNavigationsAsArray(AppRoutes),
    errorElement: <ErrorRouterBoundary />,
  },
];

const browserRouter = createBrowserRouter(routes);

export const Root: FC = () => {
  // @deprecated but route is still used by mobile apps - see AUF-13853
  if (window.location.pathname.includes('/robo')) {
    window.location.href = getOnboardingUrl(import.meta.env?.MODE as Env);
    return null;
  }

  return <RouterProvider router={browserRouter} />;
};
