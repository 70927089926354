import React from 'react';

import { ClassStyle } from '../types/ClassStyle.model';

type SidebarVariant = 'right' | 'left';

export type SidebarConfig = {
  title?: React.ReactNode;
  content?: React.ReactNode;
  footer?: React.ReactNode;
  hasCloseButton?: boolean;
  onClose?: () => void;
  afterClose?: () => void;
  afterOpened?: () => void;
  useOverlay?: boolean;
  variant?: SidebarVariant;
} & ClassStyle;

export type SidebarInnerConfig = SidebarConfig & {
  isVisible: boolean;
  id: string;
  removeSidebar?: (id: string) => void;
};

export type SidebarState = {
  sidebars: SidebarInnerConfig[];
  removeSidebar?(id: string): void;
  openSidebar?(config?: SidebarConfig): string;
  closeSidebar?(id: string): void;
  closeAllSidebars?(): void;
  hasSidebar?(id: string): boolean;
};

export enum SidebarActions {
  OPEN_SIDEBAR = 'open_sidebar',
  CLOSE_SIDEBAR = 'close_sidebar',
  CLOSE_ALL_SIDEBARS = 'close_all_sidebars',
  REMOVE_SIDEBAR = 'remove_sidebar',
}

export type SidebarActionType =
  | { type: SidebarActions.OPEN_SIDEBAR; payload: SidebarInnerConfig }
  | { type: SidebarActions.CLOSE_SIDEBAR; payload: string }
  | { type: SidebarActions.CLOSE_ALL_SIDEBARS }
  | { type: SidebarActions.REMOVE_SIDEBAR; payload: string };
