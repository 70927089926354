import { isValidElement } from 'react';

/**
 * Check if the node is a ReactNode.
 */
export const isReactNode = (node: unknown): node is React.ReactNode => {
  return (
    isValidElement(node) || // Checks for React elements
    ['string', 'number'].includes(typeof node) || // Checks for strings and numbers
    node === null || // Checks for null
    node === undefined || // Checks for undefined
    (Array.isArray(node) && node.every(isReactNode)) // Checks for arrays of renderable nodes
  );
};

/**
 *Check the parameter if it is an renderable element.
 */
export const isRenderable = (node: unknown) => isReactNode(node);
