import { AuthMethod } from '@quirion/types';
import { StorageKey } from '@quirion/utils';

export const AUTH_EVENT_NAME_DEFAULT = 'qauth';

export const AUTH_CONFIG_BANKING = {
  authKey: StorageKey.Authorization,
  challengeKey: StorageKey.AuthorizationChallenge,
  method: AuthMethod.Cookie,
  eventName: AUTH_EVENT_NAME_DEFAULT,
};

export const AUTH_CONFIG_ADMIN = {
  authKey: StorageKey.AdminAuthorization,
  challengeKey: StorageKey.AdminAuthorizationChallenge,
  method: AuthMethod.Cookie,
  eventName: AUTH_EVENT_NAME_DEFAULT,
};
