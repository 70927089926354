import { array, mixed, number, NumberSchema, string } from 'yup';

import {
  ErrorValidationKey,
  SessionAnswers,
  SessionTypeGeneric,
  SustainabilityPreference,
} from '@quirion/types';

import { formatAsCurrency } from '../formatAsCurrency';
import { isExistingPrivate } from '../isExistingPrivate';
import { isPrivatePackage } from '../isPrivatePackage';
import { isSessionAnswersTypeTangibleAssets } from '../sessionPredicates';

import { ERROR_VALIDATION_MESSAGES } from './errorValidationMessages';
import { ErrorValidationBuilderMap, ValidationProps } from './validation.types';
import {
  getMaxInvest,
  getMinInvest,
  MAX_SAVINGS_RATE,
  MIN_EXPENSES,
  MIN_SAVINGS_RATE,
} from './validationValues';

// Map of functions that build validation schemas for defined keys
export const ERROR_VALIDATION_BUILDERS: ErrorValidationBuilderMap = {
  // ONE TIME INVESTMENT VALIDATOR
  [ErrorValidationKey.OneTimeDeposit]: ({
    sessionAnswers,
  }: ValidationProps) => {
    const isTangibleAssets = isSessionAnswersTypeTangibleAssets(sessionAnswers);
    const existingPrivate = isExistingPrivate(sessionAnswers as SessionAnswers);
    const isPrivate = isPrivatePackage({
      pricePackage:
        'META__PRICE_PACKAGE' in sessionAnswers
          ? sessionAnswers.META__PRICE_PACKAGE
          : '',
    });

    const minInvest = getMinInvest({
      isExistingPrivate: existingPrivate ? false : isPrivate,
      isTangibleAssets,
    });

    const wphgMaxInvest: number =
      // eslint-disable-next-line no-nested-ternary
      'FINANCIAL_DATA__ASSETS__FREE_ASSETS' in sessionAnswers
        ? parseInt(
            sessionAnswers.FINANCIAL_DATA__ASSETS__FREE_ASSETS as string,
            10,
          )
        : 'FINANCIAL_DATA__ASSETS__TIED_ASSETS' in sessionAnswers
          ? parseInt(
              sessionAnswers.FINANCIAL_DATA__ASSETS__TIED_ASSETS as string,
              10,
            )
          : 0;

    const oneTimeDepositMinErrorMessage =
      isTangibleAssets && sessionAnswers.TANGIBLE_ASSETS_SELECTION__GENERAL
        ? ERROR_VALIDATION_MESSAGES.ONE_TIME_DEPOSIT_TANGIBLE_ASSETS_MIN
        : ERROR_VALIDATION_MESSAGES.ONE_TIME_DEPOSIT_MIN;

    const oneTimeDepositMaxErrorMessage =
      isTangibleAssets && sessionAnswers.TANGIBLE_ASSETS_SELECTION__GENERAL
        ? ERROR_VALIDATION_MESSAGES.ONE_TIME_DEPOSIT_TANGIBLE_ASSETS_MAX
        : ERROR_VALIDATION_MESSAGES.ONE_TIME_DEPOSIT_MAX;

    const isMonthlySavingsFieldRequired = !isTangibleAssets;

    // VALIDATION FOR ONE TIME INVESTMENT
    return number().when('INVESTMENT_AMOUNT__MONTHLY_SAVINGS', {
      is: (savingsRate: number) =>
        savingsRate && !Number.isNaN(savingsRate) && savingsRate > 0,
      then: (schema: NumberSchema) =>
        schema
          .typeError(ERROR_VALIDATION_MESSAGES.DEFAULT_RADIO_OR_INPUT)
          .max(
            getMaxInvest({ isTangibleAssets }),
            oneTimeDepositMaxErrorMessage.replace(
              '#amount',
              formatAsCurrency(getMaxInvest({ isTangibleAssets }), {
                fractionDigits: 0,
              }),
            ),
          )
          // .required(
          //   oneTimeDepositMinErrorMessage.replace(
          //     '#amount',
          //     formatAsCurrency(minInvest, { fractionDigits: 0 }),
          //   ),
          // )
          .test(
            'minOneTimeInvestWithSparplan',
            oneTimeDepositMinErrorMessage.replace(
              '#amount',
              formatAsCurrency(minInvest, { fractionDigits: 0 }),
            ),
            (value) => {
              if (value !== undefined) {
                if (existingPrivate) return value >= minInvest || value === 0;
                if (isPrivate || isTangibleAssets) return value >= minInvest;
                return !(value > 0 && value < minInvest);
              }
              return true;
            },
          )
          .test(
            'wphgMaxAvailableOneTimeDeposit',
            () =>
              oneTimeDepositMaxErrorMessage.replace(
                '#amount',
                formatAsCurrency(wphgMaxInvest, { fractionDigits: 0 }),
              ),
            (value) => {
              if (wphgMaxInvest !== 0) {
                if (value !== undefined && value >= 0)
                  return value <= wphgMaxInvest;
                return false;
              }
              return true;
            },
          ),
      otherwise: (schema: NumberSchema) =>
        schema
          .typeError(ERROR_VALIDATION_MESSAGES.DEFAULT_RADIO_OR_INPUT)
          .test(
            'minOneTimeInvestWithoutSparplan',
            (params) => {
              if (params.value === 0) {
                return isMonthlySavingsFieldRequired
                  ? ERROR_VALIDATION_MESSAGES.INVEST_OR_SAVINGS
                  : oneTimeDepositMinErrorMessage.replace(
                      '#amount',
                      formatAsCurrency(minInvest, { fractionDigits: 0 }),
                    );
              }
              return oneTimeDepositMinErrorMessage.replace(
                '#amount',
                formatAsCurrency(minInvest, { fractionDigits: 0 }),
              );
            },
            (value) => (value ? value >= minInvest : false),
          )
          .test(
            'wphgMaxAvailableOneTimeDeposit',
            () =>
              oneTimeDepositMaxErrorMessage.replace(
                '#amount',
                formatAsCurrency(wphgMaxInvest, { fractionDigits: 0 }),
              ),
            (value) => {
              if (wphgMaxInvest !== 0) {
                if (value !== undefined && value >= 0)
                  return value <= wphgMaxInvest;
                return false;
              }
              return true;
            },
          )
          .max(
            getMaxInvest({ isTangibleAssets }),
            oneTimeDepositMaxErrorMessage.replace(
              '#amount',
              formatAsCurrency(getMaxInvest({ isTangibleAssets }), {
                fractionDigits: 0,
              }),
            ),
          )
          .required(
            isMonthlySavingsFieldRequired
              ? ERROR_VALIDATION_MESSAGES.INVEST_OR_SAVINGS
              : oneTimeDepositMinErrorMessage.replace(
                  '#amount',
                  formatAsCurrency(minInvest, { fractionDigits: 0 }),
                ),
          ),
    });
  },

  // VALIDATOR FOR MONTHLY INVESTMENT
  [ErrorValidationKey.MonthlySavings]: ({
    sessionAnswers,
  }: ValidationProps) => {
    const wphgMaxRate: number = Math.max(
      0,
      parseInt(
        sessionAnswers.FINANCIAL_DATA__MONTHLY_FINANCES__MONTHLY_NET_INCOME ||
          '0',
        10,
      ) -
        parseInt(
          sessionAnswers.FINANCIAL_DATA__MONTHLY_FINANCES__MONTHLY_EXPENSES ||
            '0',
          10,
        ),
    );

    return number().when('INVESTMENT_AMOUNT__ONE_TIME_DEPOSIT', {
      is: (oneTimeDeposit: number) =>
        oneTimeDeposit !== null &&
        !Number.isNaN(oneTimeDeposit) &&
        oneTimeDeposit > 0,
      then: (schema: NumberSchema) =>
        schema
          .max(
            MAX_SAVINGS_RATE,
            ERROR_VALIDATION_MESSAGES.MONTHLY_SAVINGS_MAX.replace(
              '#amount',
              formatAsCurrency(MAX_SAVINGS_RATE, { fractionDigits: 0 }),
            ),
          )
          .test(
            'maxWphgLimitMonthlyInvestment',
            (params) => {
              if (params.value > wphgMaxRate) {
                return ERROR_VALIDATION_MESSAGES.MONTHLY_SAVINGS_MAX.replace(
                  '#amount',
                  formatAsCurrency(wphgMaxRate, { fractionDigits: 0 }),
                );
              }
              return ERROR_VALIDATION_MESSAGES.MONTHLY_SAVINGS_DEFAULT;
            },
            (value) => {
              if (wphgMaxRate !== 0) {
                if (value || value === 0) {
                  return value <= wphgMaxRate;
                }
                return false;
              }
              return true;
            },
          )
          .test(
            'minMonthlyInvestmentWithOneTimeInvestment',
            ERROR_VALIDATION_MESSAGES.MONTHLY_SAVINGS_MIN.replace(
              '#amount',
              formatAsCurrency(MIN_SAVINGS_RATE, { fractionDigits: 0 }),
            ),
            (value) =>
              value ? !(value > 0 && value < MIN_SAVINGS_RATE) : true,
          )
          .typeError(ERROR_VALIDATION_MESSAGES.DEFAULT_INPUT)
          .required(ERROR_VALIDATION_MESSAGES.DEFAULT_INPUT),
      otherwise: (schema: NumberSchema) =>
        schema
          .test(
            'minMonthlyInvestmentWithoutOneTimeInvestment',
            ERROR_VALIDATION_MESSAGES.MONTHLY_SAVINGS_MIN.replace(
              '#amount',
              formatAsCurrency(MIN_SAVINGS_RATE, { fractionDigits: 0 }),
            ),
            (value) => {
              if (value) return value >= MIN_SAVINGS_RATE;
              return false;
            },
          )
          .test(
            'maxWphgLimitMonthlyInvestment',
            (params) => {
              if (params.value === 0) {
                return ERROR_VALIDATION_MESSAGES.INVEST_OR_SAVINGS;
              }
              if (params.value < MIN_SAVINGS_RATE) {
                return ERROR_VALIDATION_MESSAGES.MONTHLY_SAVINGS_MIN.replace(
                  '#amount',
                  formatAsCurrency(MIN_SAVINGS_RATE, { fractionDigits: 0 }),
                );
              }
              if (wphgMaxRate !== 0 && params.value > wphgMaxRate) {
                return ERROR_VALIDATION_MESSAGES.MONTHLY_SAVINGS_MAX.replace(
                  '#amount',
                  formatAsCurrency(wphgMaxRate, { fractionDigits: 0 }),
                );
              }
              return ERROR_VALIDATION_MESSAGES.MONTHLY_SAVINGS_DEFAULT;
            },
            (value) => {
              if (value && wphgMaxRate !== 0) {
                if (value <= wphgMaxRate && value >= MIN_SAVINGS_RATE)
                  return true;
                return false;
              }
              return true;
            },
          )
          .max(
            MAX_SAVINGS_RATE,
            ERROR_VALIDATION_MESSAGES.MONTHLY_SAVINGS_MAX.replace(
              '#amount',
              formatAsCurrency(MAX_SAVINGS_RATE, { fractionDigits: 0 }),
            ),
          )
          .typeError(ERROR_VALIDATION_MESSAGES.DEFAULT_INPUT)
          .required(ERROR_VALIDATION_MESSAGES.INVEST_OR_SAVINGS),
    });
  },
  [ErrorValidationKey.MonthlyNetIncome]: ({
    sessionAnswers,
  }: ValidationProps<SessionTypeGeneric>) => {
    if (sessionAnswers.INVESTOR_PERSON__GENERAL === 'CHILD') {
      return number()
        .typeError(ERROR_VALIDATION_MESSAGES.DEFAULT_INPUT)
        .nullable();
    }
    const savingsRate =
      parseFloat(sessionAnswers.INVESTMENT_AMOUNT__MONTHLY_SAVINGS as string) ||
      0;

    if (savingsRate === 0) {
      return number()
        .typeError(ERROR_VALIDATION_MESSAGES.DEFAULT_INPUT)
        .nullable();
    }

    return number().when('FINANCIAL_DATA__MONTHLY_FINANCES__MONTHLY_EXPENSES', {
      is: (expenses: string) =>
        expenses !== null && !Number.isNaN(parseInt(expenses, 10)),
      then: (schema: NumberSchema) =>
        schema
          .typeError(ERROR_VALIDATION_MESSAGES.DEFAULT_INPUT)
          .positive(ERROR_VALIDATION_MESSAGES.MONTHLY_NET_INCOME_MIN)
          .test(
            'min',
            ERROR_VALIDATION_MESSAGES.MONTHLY_NET_INCOME_MIN,
            (value, testContext) =>
              value
                ? value >=
                  parseFloat(
                    testContext.parent
                      .FINANCIAL_DATA__MONTHLY_FINANCES__MONTHLY_EXPENSES,
                  ) +
                    savingsRate
                : false,
          )
          .required(),
      otherwise: (schema: NumberSchema) =>
        schema
          .typeError(ERROR_VALIDATION_MESSAGES.DEFAULT_INPUT)
          .positive(ERROR_VALIDATION_MESSAGES.MONTHLY_NET_INCOME_MIN)
          .min(savingsRate, ERROR_VALIDATION_MESSAGES.MONTHLY_NET_INCOME_MIN)
          .required(ERROR_VALIDATION_MESSAGES.DEFAULT_INPUT),
    });
  },
  [ErrorValidationKey.MonthlyExpenses]: ({
    sessionAnswers,
  }: ValidationProps) => {
    if (sessionAnswers.INVESTOR_PERSON__GENERAL === 'CHILD') {
      return number()
        .typeError(ERROR_VALIDATION_MESSAGES.DEFAULT_INPUT)
        .nullable();
    }
    return number()
      .typeError(ERROR_VALIDATION_MESSAGES.DEFAULT_INPUT)
      .positive(
        ERROR_VALIDATION_MESSAGES.EXPENSES_MIN.replace(
          '#amount',
          formatAsCurrency(MIN_EXPENSES, { fractionDigits: 0 }),
        ),
      )
      .min(
        1,
        ERROR_VALIDATION_MESSAGES.EXPENSES_MIN.replace(
          '#amount',
          formatAsCurrency(MIN_EXPENSES, { fractionDigits: 0 }),
        ),
      )
      .required(ERROR_VALIDATION_MESSAGES.DEFAULT_INPUT);
  },
  [ErrorValidationKey.FreeAssets]: ({ sessionAnswers }: ValidationProps) => {
    if (sessionAnswers.INVESTOR_PERSON__GENERAL === 'CHILD') {
      return number().typeError(ERROR_VALIDATION_MESSAGES.DEFAULT_INPUT);
    }
    return number()
      .typeError(ERROR_VALIDATION_MESSAGES.DEFAULT_INPUT)
      .min(
        parseFloat(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (sessionAnswers as any).INVESTMENT_AMOUNT__ONE_TIME_DEPOSIT || 0,
        ),
        sessionAnswers.INVESTOR_PERSON__GENERAL === 'US'
          ? ERROR_VALIDATION_MESSAGES.FREE_ASSETS_COUPLE_MIN
          : ERROR_VALIDATION_MESSAGES.FREE_ASSETS_SINGLE_MIN,
      )
      .required(ERROR_VALIDATION_MESSAGES.DEFAULT_INPUT);
  },
  [ErrorValidationKey.EcologicalSelection]: () =>
    string().oneOf(
      ['false'],
      ERROR_VALIDATION_MESSAGES.ECOLOGICAL_SELECTION_NOT_SUPPORTED,
    ),
  [ErrorValidationKey.SustainableDegreeSelection]: () =>
    mixed()
      .notOneOf(['1'], ERROR_VALIDATION_MESSAGES.SUSTAINABLE_DEGREE_SELECTION)
      .notRequired(),
  [ErrorValidationKey.SustainabilityChoice]: () =>
    string().required(ERROR_VALIDATION_MESSAGES.DEFAULT_RADIO),
  [ErrorValidationKey.SustainabilityChoiceNotAvail]: () =>
    string()
      .matches(new RegExp(SustainabilityPreference.No), {
        message:
          'Dieses Produkt kann nur ohne Nachhaltigkeit angeboten werden. Wählen Sie „Nein“ um fortzufahren.',
      })
      .required(ERROR_VALIDATION_MESSAGES.DEFAULT_RADIO),
  [ErrorValidationKey.SustainabilityDetermination]: () =>
    string().when('SUSTAINABILITY_PREFERENCES__SUSTAINABILITY_CHOICE', {
      is: (choice: string) => choice === SustainabilityPreference.Yes,
      then: (schema) =>
        schema.required(ERROR_VALIDATION_MESSAGES.DEFAULT_RADIO),
    }),
  [ErrorValidationKey.PaiSelection]: () => array().of(string()).nullable(),
};
