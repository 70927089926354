import React, { FC, useContext } from 'react';

import { FloatingPortal } from '@floating-ui/react';

import { StorageKey } from '@quirion/utils';

import { SidebarContext } from '../CollapsibleSidebarContext';

import { CollapsibleSidebar } from './CollapsibleSidebar';

export const SidebarContainer: FC = () => {
  const sidebarContext = useContext(SidebarContext);
  return (
    <FloatingPortal id="sidebar-root">
      {sidebarContext?.sidebars.map((sidebarConfig) => (
        <CollapsibleSidebar
          key={sidebarConfig.id}
          title={sidebarConfig.title}
          content={sidebarConfig.content}
          footer={sidebarConfig.footer}
          id={sidebarConfig.id}
          hasCloseButton={sidebarConfig.hasCloseButton}
          onClose={() => {
            if (sidebarConfig.onClose) {
              sidebarConfig.onClose();
              sessionStorage.setItem(StorageKey.SidebarId, '');
            } else {
              sidebarContext?.closeSidebar?.(sidebarConfig.id);
              sessionStorage.setItem(StorageKey.SidebarId, '');
            }
          }}
          afterClose={sidebarConfig.afterClose}
          afterOpened={sidebarConfig.afterOpened}
          removeSidebar={() =>
            sidebarContext?.removeSidebar?.(sidebarConfig.id)
          }
          useOverlay={sidebarConfig.useOverlay}
          variant={sidebarConfig.variant}
          isVisible={sidebarConfig.isVisible}
          className={sidebarConfig.className}
          style={sidebarConfig.style}
        />
      ))}
    </FloatingPortal>
  );
};
