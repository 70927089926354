import { EntrypointId } from '@quirion/types';

export const EXISTING_CUSTOMER_ENTRYPOINT_IDS = [
  EntrypointId.ExistingCustomerNewBpCashInvest,
  EntrypointId.ExistingCustomerNewBpInterest,
  EntrypointId.ExistingCustomerNewBpTopics,
  EntrypointId.ExistingCustomerNewBpVV,
  EntrypointId.ExistingCustomerNewIpsCashInvest,
  EntrypointId.ExistingCustomerNewIpsTopics,
  EntrypointId.ExistingCustomerNewIpsVV,
];

export const NEW_CUSTOMER_ENTRYPOINT_IDS = [
  EntrypointId.NewCustomerNewBpCashInvest,
  EntrypointId.NewCustomerNewBpTangibleAssets,
  EntrypointId.NewCustomerNewBpTopics,
  EntrypointId.NewCustomerNewBpVV,
];

export const NEW_BP_ENTRYPOINT_IDS = [
  EntrypointId.ExistingCustomerNewBpCashInvest,
  EntrypointId.ExistingCustomerNewBpTopics,
  EntrypointId.ExistingCustomerNewBpVV,
  EntrypointId.NewCustomerNewBpCashInvest,
  EntrypointId.NewCustomerNewBpTangibleAssets,
  EntrypointId.NewCustomerNewBpTopics,
  EntrypointId.NewCustomerNewBpVV,
];

export const NEW_IPS_ENTRYPOINT_IDS = [
  EntrypointId.ExistingCustomerNewIpsCashInvest,
  EntrypointId.ExistingCustomerNewIpsTopics,
  EntrypointId.ExistingCustomerNewIpsVV,
];

/**
 *
 * @param entrypointId Entrypoint ID of onboarding session
 * @returns {boolean} Returns `true` if this entrypoint is used for an existing customer
 */
export const isExistingCustomerEntrypoint = (
  entrypointId: EntrypointId,
): boolean => EXISTING_CUSTOMER_ENTRYPOINT_IDS.includes(entrypointId);

/**
 * Determines if a given entrypoint is for creating a new business partner.
 * @param {EntrypointId} entrypointId
 * @returns {boolean}
 */
export const isNewBusinessPartner = (entrypointId: EntrypointId): boolean =>
  new Set(NEW_BP_ENTRYPOINT_IDS).has(entrypointId);

/**
 * Determines if a given entrypoint is for topics (or "Selektiv  Anlagechancen", "Themenportfolio")
 * @param {EntrypointIntrypointId} entrypointId
 */
export const isTopics = (entrypointId: EntrypointId): boolean =>
  new Set([
    EntrypointId.ExistingCustomerNewIpsTopics,
    EntrypointId.NewCustomerNewBpTopics,
  ]).has(entrypointId);

/**
 * Determines if a given entrypoint is for tangible assets (or "Bloxxter", "Sachwerte")
 * @param {EntrypointId} entrypointId
 * @returns {boolean}
 */
export const isTangibleAssets = (entrypointId: EntrypointId): boolean =>
  new Set([
    EntrypointId.NewCustomerNewBpTangibleAssets,
    // new entrypoints to be added
  ]).has(entrypointId);
