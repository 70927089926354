import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgInvestmentCannabisGradient = function (
  props: SVGProps<SVGSVGElement>,
) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill={`url(#${_id})`}>
        <path d="m22.59 23.13-.88-.16c-1.36-.25-2.77-.82-4.17-1.7-.47-.3-1-.46-1.53-.48h-.04c-.52.01-1.05.18-1.52.48-1.4.88-2.8 1.46-4.17 1.7l-.88.16.32-.83c.49-1.28 1.26-2.37 1.96-3.2-1.5-.33-3.38-.93-5.07-2.03l-.75-.49.81-.38c1.12-.53 2.38-.91 3.76-1.13-1.02-1.55-2.17-3.73-2.68-6.18l-.18-.87.84.3c1.69.61 3.37 1.57 4.99 2.87.18-1.98.7-4.85 2.15-7.43l.44-.78.44.78c1.45 2.58 1.97 5.45 2.15 7.43 1.62-1.3 3.3-2.27 4.99-2.87l.84-.3-.18.87c-.51 2.45-1.66 4.62-2.68 6.18 1.38.22 2.64.6 3.76 1.13l.81.38-.75.49c-1.68 1.11-3.57 1.71-5.07 2.03.7.83 1.46 1.92 1.96 3.2l.32.83Zm-6.61-3.34h.06c.71.02 1.42.24 2.04.63.7.44 1.69.98 2.84 1.33-.58-1.1-1.34-2.01-1.93-2.63l-.65-.68.93-.16c1.28-.21 3.06-.66 4.73-1.56-1.01-.38-2.14-.64-3.36-.78l-.84-.1.49-.69c.93-1.31 2.1-3.28 2.75-5.55-1.58.69-3.14 1.72-4.66 3.07l-.98.87.15-1.3c.03-.27.07-.74.05-.92-.15-1.67-.55-4.02-1.6-6.24-1.05 2.22-1.45 4.57-1.6 6.24-.02.17.02.64.05.92l.15 1.3-.98-.87c-1.52-1.35-3.08-2.38-4.66-3.07.64 2.27 1.82 4.24 2.75 5.55l.49.69-.84.1c-1.22.14-2.35.4-3.36.78 1.67.9 3.45 1.34 4.73 1.56l.93.16-.65.68c-.59.62-1.35 1.53-1.93 2.63 1.14-.35 2.14-.89 2.84-1.33.63-.4 1.33-.62 2.04-.63h.01Z" />
        <path d="M15.48 20.29h1V28h-1z" />
      </g>
      <defs>
        <linearGradient
          id={_id}
          x1="0%"
          x2="50%"
          y1="50%"
          y2="0%"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939D4" />
          <stop offset={0.48} stopColor="#5D4DF8" />
          <stop offset={0.74} stopColor="#B459F9" />
          <stop offset={1} stopColor="#FE3B68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgInvestmentCannabisGradient;
