import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';

import { isReactNode } from '@quirion/utils';

import { mergeWithDefault } from '../utils/mergeWithDefault';

import { SingleBanner } from './components/SingleBanner';
import {
  DEFAULT_AUTO_HIDE_DURATION,
  DEFAULT_BANNER_TYPE,
  DEFAULT_DISABLE_CLOSE,
} from './constants';
import {
  BannerAction,
  BannerActionType,
  BannerNotificationOptions,
  BannerStack,
  BannerState,
  BannerVariant,
  NotificationInput,
} from './types/Banner.types';
import { getErrorDetails } from './utils';

import styles from './Banner.module.css';

const EMPTY_FUNCTION = () => '';

const initialState: BannerState = {
  banners: [],
  notify: async () => '',
  showError: EMPTY_FUNCTION,
  showSuccess: EMPTY_FUNCTION,
  showInfo: EMPTY_FUNCTION,
  showErrorNotification: EMPTY_FUNCTION,
  showSuccessNotification: EMPTY_FUNCTION,
  showInfoNotification: EMPTY_FUNCTION,
  hideBanner: EMPTY_FUNCTION,
};

export const generateDummyUUID = () => `banner-${Date.now()}`;

export const defaultBannerNotificationOptions: BannerNotificationOptions = {
  autoHide: DEFAULT_AUTO_HIDE_DURATION,
  disableClose: DEFAULT_DISABLE_CLOSE,
  variant: DEFAULT_BANNER_TYPE,
};

/** Reducer */
const reducer = (state: BannerState, action: BannerAction) => {
  switch (action.type) {
    case BannerActionType.HideBanner: {
      const newBanners = state.banners.filter(
        (banner) => banner.id !== action.payload,
      );
      return {
        ...state,
        banners: newBanners,
      };
    }
    case BannerActionType.Notify:
      return {
        ...state,
        banners: [
          ...state.banners,
          {
            animationDuration: action.payload.animationDuration,
            autoHide: action.payload.autoHide,
            description: action.payload.description,
            disableClose: action.payload.disableClose,
            id: action.payload.id,
            stack: action.payload.stack,
            title: action.payload.title,
            variant: action.payload.variant,
          },
        ],
      };
    case BannerActionType.ShowError:
      return {
        ...state,
        banners: [
          ...state.banners,
          {
            description: action.payload.description,
            id: action.payload.id,
            stack: action.payload.stack,
            title: action.payload.title,
            variant: BannerVariant.Error,
          },
        ],
      };
    case BannerActionType.ShowSuccess:
      return {
        ...state,
        banners: [
          ...state.banners,
          {
            description: action.payload.description,
            id: action.payload.id,
            title: action.payload.title,
            variant: BannerVariant.Success,
          },
        ],
      };
    case BannerActionType.ShowInfo:
      return {
        ...state,
        banners: [
          ...state.banners,
          {
            description: action.payload.description,
            id: action.payload.id,
            title: action.payload.title,
            variant: BannerVariant.Info,
          },
        ],
      };
    case BannerActionType.ShowErrorNotification:
      return {
        ...state,
        banners: [
          ...state.banners,
          {
            autoHide: action.payload.options.autoHide,
            description: action.payload.description,
            disableClose: action.payload.options.disableClose,
            id: action.payload.id,
            title: action.payload.title,
            variant: BannerVariant.Error,
          },
        ],
      };
    case BannerActionType.ShowSuccessNotification:
      return {
        ...state,
        banners: [
          ...state.banners,
          {
            autoHide: action.payload.options.autoHide,
            description: action.payload.description,
            disableClose: action.payload.options.disableClose,
            id: action.payload.id,
            title: action.payload.title,
            variant: BannerVariant.Success,
          },
        ],
      };
    case BannerActionType.ShowInfoNotification:
      return {
        ...state,
        banners: [
          ...state.banners,
          {
            autoHide: action.payload.options.autoHide,
            description: action.payload.description,
            disableClose: action.payload.options.disableClose,
            id: action.payload.id,
            title: action.payload.title,
            variant: BannerVariant.Info,
          },
        ],
      };
    default:
      throw new Error(`Reducer does not have action "${action}"`);
  }
};

/** BannerContext */
export const BannerContext = React.createContext<BannerState | undefined>(
  undefined,
);

const Banner: FC = () => {
  const bannerContext = useContext(BannerContext);

  return (
    <div className={styles.container}>
      {bannerContext?.banners.map((bannerProp) => (
        <SingleBanner
          title={bannerProp.title}
          description={bannerProp.description}
          stack={bannerProp.stack}
          variant={bannerProp.variant}
          id={bannerProp.id}
          hide={bannerContext?.hideBanner}
          key={bannerProp.id}
          autoHide={bannerProp.autoHide}
          animationDuration={bannerProp.animationDuration}
          disableClose={bannerProp.disableClose}
        />
      ))}
    </div>
  );
};

// For ease of use

export const BannerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const notify = useCallback(
    async (notificationInput: ReactNode | NotificationInput) => {
      const id = generateDummyUUID();

      if (isReactNode(notificationInput)) {
        dispatch({
          type: BannerActionType.Notify,
          payload: {
            id,
            title: notificationInput,
          },
        });
      } else {
        const { error } = notificationInput;

        if (error) {
          const { title, description, stack } =
            await getErrorDetails(notificationInput);

          dispatch({
            type: BannerActionType.Notify,
            payload: {
              id,
              title,
              description,
              stack,
              ...notificationInput,
            },
          });
        } else {
          dispatch({
            type: BannerActionType.Notify,
            payload: {
              ...notificationInput,
              id,
            },
          });
        }
      }

      return id;
    },
    [],
  );

  const showError = useCallback(
    (title: string, description?: string, stack?: BannerStack) => {
      const id = generateDummyUUID();
      dispatch({
        type: BannerActionType.ShowError,
        payload: {
          title,
          description,
          stack,
          id,
        },
      });
      return id;
    },
    [],
  );

  const showSuccess = useCallback((title: string, description?: string) => {
    const id = generateDummyUUID();
    dispatch({
      type: BannerActionType.ShowSuccess,
      payload: { title, description, id },
    });
    return id;
  }, []);

  const showInfo = useCallback((title: string, description?: string) => {
    const id = generateDummyUUID();
    dispatch({
      type: BannerActionType.ShowInfo,
      payload: { title, description, id },
    });
    return id;
  }, []);

  const showErrorNotification = useCallback(
    (
      title: string,
      description?: string,
      options?: Partial<BannerNotificationOptions>,
    ) => {
      const id = generateDummyUUID();
      const mergedOptions = options
        ? mergeWithDefault(options, defaultBannerNotificationOptions)
        : defaultBannerNotificationOptions;
      dispatch({
        type: BannerActionType.ShowErrorNotification,
        payload: {
          title,
          description,
          id,
          options: mergedOptions,
        },
      });
      return id;
    },
    [],
  );

  const showSuccessNotification = useCallback(
    (
      title: string,
      description?: string,
      options?: Partial<BannerNotificationOptions>,
    ) => {
      const id = generateDummyUUID();
      const mergedOptions = options
        ? mergeWithDefault(options, defaultBannerNotificationOptions)
        : defaultBannerNotificationOptions;
      dispatch({
        type: BannerActionType.ShowSuccessNotification,
        payload: {
          title,
          description,
          id,
          options: mergedOptions,
        },
      });
      return id;
    },
    [],
  );

  const showInfoNotification = useCallback(
    (
      title: string,
      description?: string,
      options?: Partial<BannerNotificationOptions>,
    ) => {
      const id = generateDummyUUID();
      const mergedOptions = options
        ? mergeWithDefault(options, defaultBannerNotificationOptions)
        : defaultBannerNotificationOptions;
      dispatch({
        type: BannerActionType.ShowInfoNotification,
        payload: {
          title,
          description,
          id,
          options: mergedOptions,
        },
      });
      return id;
    },
    [],
  );

  const hideBanner = useCallback((id: string) => {
    if (dispatch) {
      dispatch({ type: BannerActionType.HideBanner, payload: id });
    }
  }, []);

  const bannerContextValue = useMemo(
    () => ({
      banners: state.banners,
      notify,
      showError,
      showSuccess,
      showInfo,
      showInfoNotification,
      showErrorNotification,
      showSuccessNotification,
      hideBanner,
    }),
    [
      state.banners,
      notify,
      showError,
      showSuccess,
      showInfo,
      showInfoNotification,
      showErrorNotification,
      showSuccessNotification,
      hideBanner,
    ],
  );

  return (
    <BannerContext.Provider value={bannerContextValue}>
      {children}
      <Banner />
    </BannerContext.Provider>
  );
};

export const useBannerContext = () => {
  const bannerContext = useContext(BannerContext);
  if (bannerContext === undefined) {
    throw new Error(
      'useBannerContext must be used inside a BannerContext.Provider or BannerProvider',
    );
  }
  return bannerContext;
};
