import { AuthConfig } from '@quirion/types';

import { AUTH_CONFIG_BANKING } from '../constants';
import { EndpointConfig } from '../fetchEndpoints.types';
import { Payload } from '../types';

import { buildUrlForFetch } from './buildUrlForFetch';
import { getCustomHeaders } from './getCustomHeaders';
import { replaceUrlPlaceholders } from './replaceUrlPlaceholders';
import { stripQueryParamsFromPayload } from './stripQueryParamsFromPayload';
import { unifyPayload } from './unifyPayload';

export const fetchWithPayload = ({
  authConfig = AUTH_CONFIG_BANKING,
  endpointConfig,
  payload,
}: {
  authConfig?: AuthConfig;
  endpointConfig: EndpointConfig;
  payload: Payload;
}) => {
  const { method, url, private: isPrivateEndpoint } = endpointConfig;
  const payloadWithoutQueryParams = stripQueryParamsFromPayload(url, payload);

  const finishedUrl = buildUrlForFetch({
    url: replaceUrlPlaceholders(url, payload),
    method,
    payload: unifyPayload(payloadWithoutQueryParams),
  });

  const headers: { authorization?: string; 'Content-Type'?: string } =
    getCustomHeaders(isPrivateEndpoint, authConfig);

  const body =
    Object.keys(payloadWithoutQueryParams).length &&
    method !== 'GET' &&
    method !== 'DELETE'
      ? JSON.stringify(payloadWithoutQueryParams)
      : undefined;

  if (body) {
    headers['Content-Type'] = 'application/json; charset=UTF-8';
  }

  return fetch(finishedUrl, {
    method,
    body,
    headers,
  });
};
