import React, { CSSProperties, FC } from 'react';

import { Icon } from '../Icon';

import styles from './Tag.module.css';

export type TagVariant =
  | 'default'
  | 'success'
  | 'success-inverted'
  | 'warning'
  | 'warning-inverted'
  | 'error'
  | 'error-inverted'
  | 'info'
  | 'inverted'
  | 'light'
  | 'disabled'
  | 'muted';

export type TagProps = {
  text?: string;
  icon?: React.ComponentType | keyof JSX.IntrinsicElements;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  className?: string;
  radius?: 'default' | 'round';
  size?: 'sm' | 'md';
  style?: CSSProperties;
  variant?: TagVariant;
};

export const Tag: FC<TagProps> = ({
  text,
  icon,
  onClick,
  radius = 'default',
  className = '',
  style,
  variant = 'default',
  size = 'md',
}) =>
  onClick ? (
    <button
      className={[styles.tag, className].join(' ')}
      style={style}
      onClick={onClick}
      type="button"
      data-variant={variant}
      data-radius={radius}
      data-size={size}
    >
      {icon ? <Icon name={icon} size="sm" /> : null}
      {text}
    </button>
  ) : (
    <span
      className={[styles.tag, className].join(' ')}
      style={style}
      data-variant={variant}
      data-radius={radius}
      data-size={size}
    >
      {icon ? <Icon name={icon} size="sm" /> : null}
      {text}
    </span>
  );
