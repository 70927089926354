import { useCallback, useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { StorageKey } from '@quirion/utils';

import { isProd } from 'lib/env';
import { doInit } from 'lib/utils/global/doInit';

const LOADING_MSG = 'Wird geladen…';

export const useInitApp = () => {
  const [searchParams] = useSearchParams();

  const [status, setState] = useState(LOADING_MSG);

  const initializeApp = useCallback(async () => {
    try {
      const mode =
        searchParams.get('mode') ||
        sessionStorage.getItem(StorageKey.BankingMode);

      const payload = mode ? { mode } : undefined;
      const init = await doInit(isProd(), payload);

      if (mode) sessionStorage.setItem(StorageKey.BankingMode, mode);

      setState(init?.status || 'INIT');
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setState(String(error).toUpperCase());
    }
  }, [searchParams]);

  useEffect(() => {
    initializeApp();
  }, [initializeApp]);

  return {
    status,
    LOADING_MSG,
  };
};
