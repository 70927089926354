import { APICallInputWithPayload } from '../types';
import { extendedFetch } from '../utils/extendedFetch';
import { getEndpointsDefinition } from '../utils/getEndpointsDefinition';

import {
  ReadCMSProxyPayload,
  ReadCMSProxyResponse,
} from './readCMSProxy.types';

/**
 * @summary Fetches data from webflow CMS and returns it via this proxy endpoint.
 */
export const readCMSProxy = <T extends Record<string, unknown>>({
  endpointsDefinition = getEndpointsDefinition(),
  payload,
}: APICallInputWithPayload<ReadCMSProxyPayload>): Promise<
  ReadCMSProxyResponse<T>
> =>
  extendedFetch({
    endpointConfig: {
      ...endpointsDefinition.endpoints.getCMSProxy,
      url: `${endpointsDefinition.endpoints.getCMSProxy.url}/collections/${
        payload.collectionId
      }/items${payload?.itemId ? `/${payload?.itemId}` : ''}`,
    },
  })
    .then((res) => res.json())
    .catch((error) => error);
