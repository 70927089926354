export enum EntrypointId {
  NewCustomerNewBpVV = 'NEW_CUSTOMER__NEW_BP__VV', // globales portfolio
  NewCustomerNewBpTopics = 'NEW_CUSTOMER__NEW_BP__TOPICS', // selective anlage
  NewCustomerNewBpTangibleAssets = 'NEW_CUSTOMER__NEW_BP__TANGIBLE_ASSETS', // bloxxter
  NewCustomerNewBpCashInvest = 'NEW_CUSTOMER__NEW_BP__CASH_INVEST',
  ExistingCustomerNewBpVV = 'EXISTING_CUSTOMER__NEW_BP__VV', // new bp with globales portfolio
  ExistingCustomerNewBpTopics = 'EXISTING_CUSTOMER__NEW_BP__TOPICS', // new bp with topics option
  ExistingCustomerNewBpCashInvest = 'EXISTING_CUSTOMER__NEW_BP__CASH_INVEST', // new bp with cash invest option
  ExistingCustomerNewBpInterest = 'EXISTING_CUSTOMER__NEW_BP__INTEREST', // new bp with interest product (Zinsprodukt)
  ExistingCustomerNewIpsVV = 'EXISTING_CUSTOMER__NEW_IPS__VV', // new ips globales?
  ExistingCustomerNewIpsTopics = 'EXISTING_CUSTOMER__NEW_IPS__TOPICS', // new ips seletive anlage
  ExistingCustomerNewIpsCashInvest = 'EXISTING_CUSTOMER__NEW_IPS__CASH_INVEST', // new ips cash invest
  ExistingCustomerUpdateBp = 'EXISTING_CUSTOMER__UPDATE_BP',
  ExistingCustomerChangeStrategy = 'EXISTING_CUSTOMER__CHANGE_STRATEGY',
}
