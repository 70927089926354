import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgInvestmentDigitalisierungGradient = function (
  props: SVGProps<SVGSVGElement>,
) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill={`url(#${_id})`}
        d="M16.5 28.19h-1V17.76h1zm7.34-4.83-.63-.78c2.72-2.21 4.29-5.49 4.29-9s-1.56-6.79-4.29-9l.63-.78c2.96 2.4 4.66 5.96 4.66 9.78s-1.7 7.38-4.66 9.78m-15.69 0c-2.96-2.4-4.66-5.96-4.66-9.78S5.19 6.2 8.15 3.8l.63.78c-2.72 2.21-4.29 5.49-4.29 9s1.56 6.79 4.29 9zm11.16-1.58-.37-.93c2.98-1.2 4.9-4.06 4.9-7.27s-1.93-6.07-4.9-7.27l.37-.93c3.36 1.36 5.53 4.58 5.53 8.2s-2.17 6.84-5.53 8.2m-6.63 0c-3.36-1.36-5.53-4.58-5.53-8.2s2.17-6.84 5.53-8.2l.37.93c-2.98 1.2-4.9 4.06-4.9 7.27s1.93 6.07 4.9 7.27zm6.75-3.63-.6-.8c1.2-.9 1.89-2.27 1.89-3.77s-.69-2.87-1.89-3.77l.6-.8c1.45 1.09 2.29 2.75 2.29 4.57s-.83 3.48-2.29 4.57m-6.86 0c-1.45-1.09-2.29-2.75-2.29-4.57s.83-3.48 2.29-4.57l.6.8c-1.2.9-1.89 2.27-1.89 3.77s.69 2.87 1.89 3.77zM16 15.12a1.54 1.54 0 1 1 .001-3.081A1.54 1.54 0 0 1 16 15.12m0-2.09c-.3 0-.54.24-.54.54s.24.54.54.54.54-.24.54-.54-.24-.54-.54-.54"
      />
      <defs>
        <linearGradient
          id={_id}
          x1="0%"
          x2="50%"
          y1="50%"
          y2="0%"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939D4" />
          <stop offset={0.48} stopColor="#5D4DF8" />
          <stop offset={0.74} stopColor="#B459F9" />
          <stop offset={1} stopColor="#FE3B68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgInvestmentDigitalisierungGradient;
