import type { SVGProps } from 'react';
const SvgMail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={1.5}
    className="mail_svg__icon mail_svg__icon-tabler mail_svg__icon-tabler-mail"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <rect width={18} height={14} x={3} y={5} rx={2} />
    <path d="m3 7 9 6 9-6" />
  </svg>
);
export default SvgMail;
