import React, {
  ComponentProps,
  ComponentType,
  ElementType,
  ReactNode,
} from 'react';

import { Icon } from '../Atoms/Icon';
import { ClassStyle } from '../types/ClassStyle.model';

import styles from './NavItem.module.css';

export type NavItemOwnProps = {
  /**
   * Label of NavItem
   */
  label: ReactNode;
  /**
   * Icon to display
   */
  icon?: ComponentType | keyof JSX.IntrinsicElements;
  /**
   * Icon to display
   */
  customIcon?: ReactNode;
  /**
   * Register your on click handler for navigation here
   */
  onClick?(): void;
  /**
   * Set item active (current link, for example)
   */
  active?: boolean;
  /**
   * Align items horizontally instead of vertically.
   */
  horizontal?: boolean;
  /**
   * Disabled
   */
  disabled?: boolean;
} & ClassStyle;

const defaultElement = 'button';

export type NavItemProps<E extends ElementType = typeof defaultElement> = {
  as?: E;
} & NavItemOwnProps &
  Omit<ComponentProps<E>, keyof NavItemOwnProps>;

export const NavItem = <E extends ElementType = typeof defaultElement>({
  as,
  active = false,
  horizontal = false,
  label,
  icon,
  customIcon,
  onClick,
  style,
  className = '',
  disabled = false,
  ...rest
}: NavItemProps<E>) => {
  const Element: ElementType = as || defaultElement;
  return (
    <Element
      className={[styles.container, className].join(' | ')}
      style={style}
      data-is-active={active}
      data-is-horizontal={horizontal}
      data-is-disabled={disabled}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {customIcon ?? customIcon}
      {icon && !customIcon && <Icon name={icon} />}
      <span className={styles.label}>{label}</span>
    </Element>
  );
};
