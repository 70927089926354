import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgInvestmentCybersicherheitGradient = function (
  props: SVGProps<SVGSVGElement>,
) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      {...props}
    >
      <g fill={`url(#${_id})`}>
        <path d="m15.95 11.87-1.56-1.39.66-.75.78.69 1.36-1.65.77.63z" />
        <path d="m16 15.73-.14-.04C9.68 13.86 10.59 5.5 10.6 5.42l.06-.5.5.06c2.74.32 4.49-1.24 4.5-1.25l.34-.31.34.31s1.77 1.58 4.5 1.25l.5-.06.06.5c0 .08.92 8.45-5.26 10.27zm-4.44-9.72c-.08 1.68 0 7.25 4.44 8.67 4.43-1.43 4.52-6.99 4.44-8.67A7.08 7.08 0 0 1 16 4.73a7.08 7.08 0 0 1-4.44 1.28M16 24.6c-1.63 0-3.22-.21-4.71-.64a16.3 16.3 0 0 1-4.31-1.9l.53-.85c1.26.79 2.62 1.39 4.05 1.78 2.81.8 6.07.8 8.88 0 1.43-.4 2.79-1 4.05-1.78l.53.85a16.3 16.3 0 0 1-4.31 1.9c-1.49.42-3.08.64-4.71.64" />
        <path d="m19.15 27.94-.85-.53c.79-1.26 1.39-2.62 1.78-4.05.4-1.41.6-2.9.6-4.44 0-.93-.07-1.86-.22-2.74l.99-.16c.16.94.23 1.92.23 2.9 0 1.63-.21 3.22-.64 4.71a16.3 16.3 0 0 1-1.9 4.31Z" />
        <path d="M16 28.59a9.683 9.683 0 0 1-9.13-6.49 9.5 9.5 0 0 1-.54-3.19c0-1.01.15-2 .46-2.94A9.56 9.56 0 0 1 10 11.34l.62.78a8.66 8.66 0 0 0-3.29 6.8c0 .99.16 1.95.49 2.85a8.72 8.72 0 0 0 5.52 5.4c1.71.56 3.61.56 5.32 0 2.57-.83 4.64-2.84 5.52-5.39.32-.91.49-1.87.49-2.86a8.643 8.643 0 0 0-3.29-6.8l.62-.78c1.52 1.2 2.62 2.8 3.21 4.64.3.95.46 1.94.46 2.94 0 1.1-.18 2.17-.54 3.19A9.69 9.69 0 0 1 16 28.59" />
        <path d="M24.49 16.62c-1.26-.79-2.62-1.39-4.05-1.78l-.29-.07.25-.97.31.08c1.52.42 2.97 1.06 4.31 1.9l-.53.85ZM7.51 16.62l-.53-.85a16.3 16.3 0 0 1 4.31-1.9l.3-.08.25.97-.29.07c-1.43.4-2.79 1-4.05 1.78ZM12.85 27.94a16.3 16.3 0 0 1-1.9-4.31 17.4 17.4 0 0 1-.64-4.71c0-.98.08-1.95.23-2.89l.99.16c-.14.88-.22 1.8-.22 2.73 0 1.54.2 3.04.6 4.44.4 1.43 1 2.79 1.78 4.05l-.85.53Z" />
      </g>
      <defs>
        <linearGradient
          id={_id}
          x1="0%"
          x2="50%"
          y1="50%"
          y2="0%"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939D4" />
          <stop offset={0.48} stopColor="#5D4DF8" />
          <stop offset={0.74} stopColor="#B459F9" />
          <stop offset={1} stopColor="#FE3B68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgInvestmentCybersicherheitGradient;
