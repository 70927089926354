import { Env, PROJECT_DEVELOPMENT_PORTS, ProjectName } from './constants';

const DEVELOPMENT_DOMAIN = 'http://127.0.0.1';

// eslint-disable-next-line consistent-return
export const getProjectDomain = (projectName: ProjectName, env: Env) => {
  // eslint-disable-next-line default-case
  switch (projectName) {
    case ProjectName.Banking:
      if (env === Env.Production) {
        return 'https://banking.quirion.de/';
      }
      if (env === Env.Preproduction) {
        return 'https://banking.quirion.de/';
      }
      if (env === Env.Development) {
        return 'https://banking-dev.quirion.de/';
      }
      return `${DEVELOPMENT_DOMAIN}:${PROJECT_DEVELOPMENT_PORTS.banking}/`;
    case ProjectName.Onboarding:
      // eslint-disable-next-line no-case-declarations
      const productionUrl = 'https://onboarding.quirion.de/';
      if (env === Env.Production) {
        return productionUrl;
      }
      if (env === Env.Preproduction) {
        console.warn(
          `This environment does not exist in the ${projectName} project. Falling back to ${productionUrl}.`,
        );
        return productionUrl;
      }
      if (env === Env.Development) {
        return 'https://onboarding.it-aws-dev.quirion.de/';
      }
      return `${DEVELOPMENT_DOMAIN}:${PROJECT_DEVELOPMENT_PORTS.onboarding}/`;
  }
};
