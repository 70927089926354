import { SVGProps } from 'react';

import {
  SvgBuildingCommunity,
  SvgUser,
  SvgUsers,
  SvgUsersGroup,
} from '@quirion/assets';
import { AccountType } from '@quirion/types';

export const ContractIcons: Record<
  AccountType,
  (props: SVGProps<SVGSVGElement>) => JSX.Element
> = {
  [AccountType.SingleAccount]: SvgUser,
  [AccountType.ChildSingleAccount]: SvgUsersGroup,
  [AccountType.ChildJointAccount]: SvgUsersGroup,
  [AccountType.JointAccount]: SvgUsers,
  /** @deprecated */
  [AccountType.ChildAccount]: SvgUsersGroup,
  /** @deprecated */
  [AccountType.ChildJoinedAccount]: SvgUsersGroup,
  /** @deprecated */
  [AccountType.JoinedAccount]: SvgUsers,
};

export const getContractIcon = (
  accountType: AccountType,
  provider = 'QUIRION',
) => {
  if (provider === 'BLOXXTER') return SvgBuildingCommunity;
  const icon = ContractIcons[accountType];
  if (icon) {
    return icon;
  }
  return SvgUser;
};
