import { ORDER_TYPE_LABELS } from '../constants';

export const getOrderTypeText = (orderId: string) => {
  const orderType = orderId
    .split('#')[1]
    .toString() as keyof typeof ORDER_TYPE_LABELS;
  if (orderType in ORDER_TYPE_LABELS) {
    return ORDER_TYPE_LABELS[orderType];
  }
  return orderType;
};
