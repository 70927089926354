import { FC } from 'react';

import {
  ExternalLinks,
  MailAddresses,
  PhoneNumbers,
  SUPPORT_WORKING_HOURS,
} from '@quirion/utils';

import { TextLink } from '../../Atoms/TextLink';
import { Screen, Stack } from '../../Container';
import { Hint } from '../../Hint';
import { Heading } from '../../Typefaces';
import { ErrorBoundaryFallbackProps } from '../types';

export const ErrorBoundaryDefaultFallback: FC<ErrorBoundaryFallbackProps> = ({
  errorName = 'Allgemeiner Fehler',
  errorMessage,
  componentStack,
}) => (
  <Screen withPadding heading="Es ist ein Problem aufgetreten.">
    <Stack gap="var(--space-2)" justifyItems="stretch">
      <div>
        Bitte versuchen Sie es später erneut. Sollte das Problem weiter
        bestehen, wenden Sie sich bitte an unseren Support.
      </div>

      <div>
        Telefon:{' '}
        <TextLink href={ExternalLinks.SupportHotline}>
          {PhoneNumbers.SupportHotline}
        </TextLink>
      </div>

      <div>
        E-Mail:{' '}
        <TextLink href={ExternalLinks.MailSupport}>
          {MailAddresses.MailSupport}
        </TextLink>
      </div>

      <div>{SUPPORT_WORKING_HOURS}</div>

      <Hint
        variant="error"
        style={{ overflow: 'scroll', margin: 'var(--space-6) 0' }}
      >
        <Heading variant="h4" tag="h4" style={{ color: 'inherit' }}>
          {errorName}
        </Heading>

        {!!errorMessage && (
          <Heading variant="h5" tag="h5" style={{ color: 'inherit' }}>
            {errorMessage}
          </Heading>
        )}

        {componentStack && (
          <pre
            style={{
              fontSize: 'var(--font-sizes-1)',
              marginLeft: '-2em',
            }}
          >
            {componentStack}
          </pre>
        )}
      </Hint>
    </Stack>
  </Screen>
);
