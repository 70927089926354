import { formatAsCurrency } from '@quirion/utils';

export const SEPA_DAYS_UNTIL_INVESTMENT = 5;

/**
 * Minimum SEPA top up amount in EUR
 */
export const SEPA_MINIMUM_AMOUNT = 1;

/**
 * Maximum voucher amount per business partner per year in EUR
 */
export const VOUCHER_AMOUNT_YEARLY_LIMIT = 250;

export const VOUCHER_CODE_LENGTH = 19;

export const DESCRIPTION_CLEARING =
  'Über Ihr Verrechnungskonto (PLUS) werden Auszahlungen auf Ihr Referenzkonto sowie Umbuchungen zwischen Strategien abgewickelt.';

export const DESCRIPTION_CLEARING_SHORT = 'Interne Umbuchung.';

export const DESCRIPTION_SEPA = `Beachten Sie, dass eine Lastschrift bis zu ${SEPA_DAYS_UNTIL_INVESTMENT} Werktage bis zur Investition benötigt.`;

export const DESCRIPTION_SEPA_REFERENCE_SHORT = 'Einzahlung per Lastschrift.';

export const DESCRIPTION_SEPA_DEBIT_SHORT = 'Einzahlung von einem neuen Konto.';

export const DESCRIPTION_VOUCHER = `Sie haben drei Erfassungsversuche. Nach der Eingabe von drei falschen Gutscheincodes ist für weitere Gutscheincodes ein erneutes Login notwendig. Die Einlösung ist auf maximal ${formatAsCurrency(
  VOUCHER_AMOUNT_YEARLY_LIMIT,
)} je Kunde und Kalenderjahr begrenzt.`;

export const DESCRIPTION_VOUCHER_SHORT =
  'Einlösung von quirion Guthabenkarten.';

export const DESCRIPTION_WIRE =
  'Eine Einzahlung per Überweisung wird in der Regel bereits am folgenden Werktag nach Geldeingang investiert.';

export const DESCRIPTION_WIRE_SHORT = 'Ihre Kontoverbindung für Überweisungen.';
