import { APICallInputWithPayload } from '../types';
import { extendedFetch } from '../utils/extendedFetch';
import { getEndpointsDefinition } from '../utils/getEndpointsDefinition';

import { GetStrategyPayload, GetStrategyResponse } from './getStrategy.types';

export const getStrategy = ({
  endpointsDefinition = getEndpointsDefinition(),
  payload,
}: APICallInputWithPayload<GetStrategyPayload>): Promise<GetStrategyResponse> =>
  extendedFetch({
    endpointConfig: endpointsDefinition.endpoints.strategy,
    payload,
  }).then((res) => res.json());
