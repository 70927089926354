import {
  DEFAULT_CURRENCY_FORMATTING_OPTIONS,
  DEFAULT_LOCALE,
} from './constants';

/**
 * Formats number as currency
 *
 * @param value Number to format
 * @param formattingOptions Options to override defaults
 * @returns Input value formatted as currency
 */
export const formatAsCurrency = (
  value: number | string,
  formattingOptions?: {
    currency?: string | undefined;
    fractionDigits?: number;
    isPerAnnum?: boolean;
    locales?: string | string[] | undefined;
  },
): string => {
  const numberValue = typeof value === 'string' ? parseFloat(value) : value;

  if (Number.isNaN(numberValue) || typeof numberValue !== 'number') {
    throw new Error('Input not of type number');
  }

  let options = DEFAULT_CURRENCY_FORMATTING_OPTIONS;

  if (formattingOptions?.currency) {
    options = {
      ...options,
      currency: formattingOptions.currency,
    };
  }
  if (formattingOptions?.fractionDigits !== undefined) {
    options = {
      ...options,
      minimumFractionDigits: formattingOptions.fractionDigits,
      maximumFractionDigits: formattingOptions.fractionDigits,
    };
  }

  return `${numberValue.toLocaleString(
    formattingOptions?.locales || DEFAULT_LOCALE,
    options,
  )}${formattingOptions?.isPerAnnum ? ' p.\u202Fa.' : ''}`;
};
