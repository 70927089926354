import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgInvestmentFintechGradient = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill={`url(#${_id})`}
        d="M19.25 27.33c-.55 0-1.11-.12-1.54-.37L4.48 19.32c-.49-.28-.77-.7-.77-1.15h1c0 .07.08.18.27.29l13.23 7.64c.55.32 1.53.32 2.08 0l6.73-3.89c.2-.12.27-.23.27-.29v-1l-6.5 3.76c-.86.5-2.21.5-3.08 0L4.48 17.04c-.49-.28-.76-.7-.76-1.15s.28-.87.76-1.15l6.79-3.92c.15-.09.32-.16.5-.22.77-.24 1.64-.13 2.37.31l.66.4-.52.86-.66-.4c-.49-.3-1.06-.37-1.56-.22-.11.04-.21.08-.3.13l-.35.2.24.14c.35.2.54.51.54.84s-.2.64-.54.84l-3.16 1.83c-.55.32-1.37.32-1.92 0l-.74-.43-.85.49c-.2.11-.26.23-.26.29s.07.17.26.29l13.23 7.64c.55.32 1.53.32 2.08 0l6.73-3.89c.19-.11.26-.22.27-.29v-.02c0-.07-.08-.18-.27-.29l-4.26-2.46.5-.87 4.26 2.46c.47.27.75.68.77 1.11v2.34h-.5.51c0 .45-.28.88-.77 1.16l-6.73 3.89c-.43.25-.99.37-1.54.37ZM6.83 14.51l.24.14c.24.14.68.14.92 0l3.12-1.8-.69-.41-3.58 2.07Zm11.9 3.5a4.009 4.009 0 0 1-2.01-7.48c.11-.77.51-2.99 1.56-4.1.65-.69 1.88-.9 2.97-1.09.3-.05.58-.1.83-.16l.12-.03c1.27-.29 2.19-.5 4.11-.5h1.91v1h-1.91c-1.82 0-2.64.19-3.89.47l-.12.03c-.26.06-.56.11-.88.17-.88.15-1.98.34-2.41.79-.63.67-1.01 2.02-1.2 2.98.58-.14 1.22-.14 1.83 0l-.22.97c-.22-.05-.45-.08-.67-.08-1.66 0-3.01 1.35-3.01 3.01S17.09 17 18.75 17a3.013 3.013 0 0 0 2.89-3.86l.96-.28c.11.37.16.75.16 1.14 0 2.21-1.8 4.01-4.01 4.01Zm1.17-4.36c-.28 0-.58-.11-.87-.44-.24-.29-.34-.62-.3-.95s.21-.64.48-.84c.61-.46 1.26-.96 1.91-1.83-.06.01-.56 0-.97.47l-.76-.65c.75-.88 1.73-.81 1.77-.81.34 0 .68.21.84.54.17.34.14.73-.09 1.03-.73.99-1.48 1.56-2.03 1.98-.15.11-.16.18-.17.21 0 .03 0 .1.05.16.11.13.14.17.51-.08.21-.14.44-.3.65-.46.25-.18.5-.36.7-.49.37-.24.79-.45 1.33-.66 1.2-.48 1.71-1.23 1.93-1.78.25-.62.85-1.02 1.52-1.02v1c-.26 0-.5.16-.6.39-.29.72-.96 1.72-2.49 2.33-.47.19-.84.37-1.16.58-.2.12-.42.29-.66.46-.23.16-.46.33-.69.48-.18.12-.53.35-.94.35Zm8.31-4.59h-1.78v-1h1.78z"
      />
      <defs>
        <linearGradient
          id={_id}
          x1="0%"
          x2="50%"
          y1="50%"
          y2="0%"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939D4" />
          <stop offset={0.48} stopColor="#5D4DF8" />
          <stop offset={0.74} stopColor="#B459F9" />
          <stop offset={1} stopColor="#FE3B68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgInvestmentFintechGradient;
