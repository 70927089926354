export function buildErrorMessageFromFetch(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bodyParam: any, // any, because there are varied styles of API responses
  url: string,
): string {
  let body = bodyParam;
  let message = body?.message || '';

  const urlParts = url ? url.split('/') : [];
  const endpoint = urlParts.length
    ? urlParts[urlParts.length - 1].toUpperCase()
    : 'NO_URL';

  if (typeof body === 'string') {
    try {
      // is probably stringifed JSON
      body = JSON.parse(body);
    } catch (err) {
      // no, it's not
      message = `${body} (${endpoint})`;
    }
  }

  if (body && body.message) {
    // is message
    message = body.message;
  } else if (typeof body === 'number') {
    // is just error code
    message = body;
  } else if (body && body.error) {
    // there is an specific error object
    if (body.error.code) {
      // it has a code and maybe an additional message
      message = body.error.code;
      message += body.error.message ? ` ${body.error.message}` : '';
    } else if (body.error.message) {
      message = body.error.message;
    }
  }

  // Add validation errors, if any
  if (body && body.validationErrors) {
    message += ` ${body.validationErrors} (${endpoint})`;
  }

  return message;
}
