import { createRoot } from 'react-dom/client';

import App from './pages';

if (import.meta.env.MODE === 'development-local') {
  const { host } = window.location;
  if (host.includes('localhost')) {
    window.location.host = host.replace('localhost', '127.0.0.1');
  }
}

const element = document.getElementById('root');
if (element) {
  const root = createRoot(element);
  root.render(<App />);
}
