import { AccountType, TaskState, TaskType } from '@quirion/types';
import type { IPS } from '@quirion/types';

import type { State } from 'lib/redux/types';

import dataCountries from './data/countries';
import dataIndustries from './data/industries';
import dataMaritalStatus from './data/marital_status';
import dataOccupations from './data/occupations';

const initState: State = {
  user: {},
  businessPartner: {},
  ips: {},
  cases: [],
  savingsPlans: {},
  // TODO: check everything below here for necessity
  conditions: [],
  messages: [],
  formdata: {
    maritalStatus: dataMaritalStatus,
    occupations: dataOccupations,
    countries: dataCountries,
    industries: dataIndustries,
  },
  tasks: [],
  servicePackageDetails: [],
  migrationDetails: [],
};

const rootReducer = (stateInput: State | undefined, action: any) => {
  // console.log('rootReducer',action.type, action)

  const state = stateInput ?? initState;

  if (action.type === 'CLEAR_STORE') {
    return {
      ...initState,
    };
  }

  if (action.type === 'SET_TASK') {
    return {
      ...state,
      tasks: [...state.tasks, action.task],
    };
  }

  /* Identifying tasks is specific to the taskType (metaData), as long as task ids are not generally
     sent by the api and/or add by the frontend for `fake` tasks. So currently this is WPHG-only */
  if (action.type === 'CLOSE_TASK_WPHG') {
    const { businessPartnerId } = action;
    const tasks = state.tasks.map((task) =>
      task.taskType === TaskType.WpHG &&
      task.metaData?.businessPartnerId === businessPartnerId
        ? {
            ...task,
            state: TaskState.Closed,
          }
        : task,
    );
    return {
      ...state,
      tasks,
    };
  }

  // NEW STUFF for STA, will probably deprecate a lot of stuff above

  if (action.type === 'API_GET_USER') {
    if (action?.response) {
      const responseData = action?.response?.data
        ? { ...action.response.data }
        : { ...action.response };
      // extract businessPartner data to separate place in store
      const businessPartner = { ...state.businessPartner };
      responseData.businessPartner.forEach((bp) => {
        const id = bp.businessPartnerId;
        if (id) {
          if (businessPartner[id]) {
            businessPartner[id] = {
              ...businessPartner[id],
              ...bp,
            };
          } else {
            businessPartner[id] = bp;
          }
        }
      });
      const tasks = responseData?.tasks?.length > 0 ? responseData.tasks : [];

      return {
        ...state,
        user: {
          ...responseData.user,
          businessPartnerTotalAmount: responseData.businessPartnerTotalAmount,
          businessPartner: responseData.businessPartner.map(
            (bp) => bp.businessPartnerId,
          ),
          cases: responseData.cases.map((cs) => cs.caseId),
          loaded: true,
        },
        cases: responseData.cases,
        businessPartner,
        tasks: [
          ...(state.tasks ? state.tasks.filter((task) => task.fake) : []), // include existing FE generated `fake` tasks
          ...tasks,
        ],
      };
    }
  }

  if (action.type === 'API_GET_BP') {
    if (action?.response && action.id) {
      const responseData = action?.response?.data
        ? { ...action.response.data }
        : { ...action.response };

      const loadedProducts = responseData.products;
      const validProducts = loadedProducts?.filter((item: any) => {
        const id = item.ipsId || item.assetId || item.productId;
        if (!id) {
          console.warn(`BP ${action.id} has an invalid product.`);
          return false;
        }
        return true;
      });

      // extract product data to separate place in store
      const products = { ...state.products };
      if (validProducts?.length) {
        validProducts.forEach((item: IPS) => {
          const id = item.ipsId || item.assetId || item.productId;
          if (id) {
            if (products[id]) {
              products[id] = {
                ...products[id],
                ...item,
              };
            } else {
              products[id] = item;
            }
          }
        });

        // only keep the id in the businessPartner
        validProducts.forEach((item, idx) => {
          const id = item.ipsId || item.assetId || item.productId;
          validProducts[idx] = id;
        });
      }

      // merge with existing businessPartners
      const businessPartner = { ...state.businessPartner };
      businessPartner[action.id] = {
        ...businessPartner[action.id],
        ...responseData,
        // TODO Remove. (workaround for compatibility)
        products: validProducts || businessPartner[action.id].products,
        ips: validProducts || businessPartner[action.id].ips,
      };
      return {
        ...state,
        businessPartner,
        products,
        ips: products, // TODO Remove (workaround for compatibility)
      };
    }
  }

  if (action.type === 'API_GET_IPS') {
    if (action?.response && action.id) {
      const responseData = action?.response?.data
        ? { ...action.response.data }
        : { ...action.response };

      // extract savingsPlans data to separate place in store
      const savingsPlans = { ...state.savingsPlans };
      if (responseData.savingsPlans) {
        responseData.savingsPlans.forEach((item) => {
          const sp = { ...item };
          const id = sp.savingsPlanId;
          if (id) {
            sp.ipsId = action.id; // missing in `getIps` call
            if (savingsPlans[id]) {
              savingsPlans[id] = {
                ...savingsPlans[id],
                ...sp,
              };
            } else {
              savingsPlans[id] = sp;
            }
          }
        });
      }
      const conditions = [...state.conditions];
      if (responseData.condition) {
        const id = responseData.condition.conditionId;

        if (!conditions.find((condition) => condition.conditionId === id)) {
          conditions.push(responseData.condition);
        }
      }

      // only keep the savingsPlansId in the ips
      if (responseData.savingsPlans) {
        responseData.savingsPlans.forEach((item, idx) => {
          responseData.savingsPlans[idx] = item.savingsPlanId;
        });
      }

      // merge with existing ipses
      const products = { ...state.products };
      products[action.id] = {
        ...products[action.id],
        ...responseData,
      };
      return {
        ...state,
        products,
        ips: products, // TODO Remove (workaround for compatibility)
        savingsPlans,
        conditions,
      };
    }
  }

  if (action.type === 'API_SET_SAVINGSPLANS') {
    if (action?.payload) {
      const savingsPlans = { ...state.savingsPlans };
      if (action.payload.savingsPlans) {
        action.payload.savingsPlans.forEach((item) => {
          const id = item.savingsPlanId;
          if (id) {
            if (savingsPlans[id]) {
              savingsPlans[id] = {
                ...savingsPlans[id],
                ...item,
              };
            } else {
              savingsPlans[id] = item;
            }
          }
        });
      }
      return {
        ...state,
        savingsPlans,
      };
    }
    return {
      ...state,
    };
  }

  if (action.type === 'API_DELETE_SAVINGSPLAN') {
    if (action?.id) {
      const savingsPlans = { ...state.savingsPlans };
      delete savingsPlans[action.id];
      return {
        ...state,
        savingsPlans,
      };
    }
  }

  if (action.type === 'API_GET_CONDITIONS') {
    const accountTypeMap = {
      SA: AccountType.SingleAccount,
      JA: AccountType.JointAccount,
      CA: AccountType.ChildAccount,
    };

    const responseData = action?.response?.data
      ? [...action.response.data]
      : [...action.response];

    const newConditionsById = {};
    const newConditions =
      responseData && responseData.length
        ? responseData.map((item) => {
            const condition = { ...item };
            if (accountTypeMap[condition.accountType]) {
              condition.accountType = accountTypeMap[condition.accountType];
            }
            newConditionsById[condition.conditionId] = condition;
            return condition;
          })
        : [];

    let conditions = newConditions;

    if (state.conditions.length) {
      const oldConditionsById = {};
      state.conditions.forEach((item) => {
        oldConditionsById[item.conditionId] = item;
      });

      const allConditionsById = {
        ...oldConditionsById,
        ...newConditionsById,
      };

      conditions = Object.keys(allConditionsById).map((conditionId) => ({
        ...oldConditionsById[conditionId],
        ...newConditionsById[conditionId],
      }));
    }

    return {
      ...state,
      conditions,
    };
  }

  if (action.type === 'SET_SERVICE_PACKAGE_DETAILS') {
    return {
      ...state,
      servicePackageDetails: action.payload,
    };
  }

  if (action.type === 'SET_MIGRATION_DETAILS') {
    // either an array for all or a single object for a single migration

    return {
      ...state,
      migrationDetails: {
        ...state.migrationDetails,
        ...action.payload,
      },
    };
  }

  return state;
};

export default rootReducer;
