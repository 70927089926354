/* eslint-disable @typescript-eslint/ban-ts-comment */
import { DEFAULT_ERROR_DESCRIPTION, ErrorName, ErrorOptions } from './helpers';

/**
 * Extended Error Object used for network errors.
 *
 * Also See https://codesandbox.io/s/practical-hypatia-ft1cxb?file=/src/index.ts
 *
 * @param message - Describes the nature of the Error (e.g. "Page Not Found")
 * @param status - Describes a network status code (see https://developer.mozilla.org/de/docs/Web/HTTP/Status)
 * @param options - Optional additional options
 *
 * @example
 * ```js
 * const fetchData = async () => {
 *  try {
 *    const res = await fetch(
 *      "https://g7qmw0qi9h.execute-api.eu-central-1.amazonaws.com/dev/costsheet"
 *    );
 *    const body = await res.json();
 *    if (res.status !== 200) {
 *      throw new NetworkError(body.message, res.status);
 *    }
 *  } catch (error) {
 *    console.log(error);
 *  }
 * };
 * fetchData();
 * ```
 */
export class NetworkError extends Error {
  description: ErrorOptions['description'];

  cause: ErrorOptions['cause'];

  body: ErrorOptions['body'];

  status: number;

  url: string;

  constructor(
    message: string,
    status: number,
    url: string,
    options: ErrorOptions = {},
    ...props: any
  ) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(...props);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    // @ts-ignore
    if (Error?.captureStackTrace) {
      // @ts-ignore
      Error.captureStackTrace(this, NetworkError);
    }

    this.message = `${message}`;
    this.description = options.description ?? DEFAULT_ERROR_DESCRIPTION;
    this.name = ErrorName.Network;
    this.status = status;
    this.url = url;
    this.cause = options.cause;
    this.body = options.body;
  }
}
