import type { SVGProps } from 'react';
const SvgCircleCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth={1.5}
    className="circle-check_svg__icon circle-check_svg__icon-tabler circle-check_svg__icon-tabler-circle-check"
    viewBox="0 0 24 24"
    width="1em"
    height="1em"
    {...props}
  >
    <path stroke="none" d="M0 0h24v24H0z" />
    <circle cx={12} cy={12} r={9} />
    <path d="m9 12 2 2 4-4" />
  </svg>
);
export default SvgCircleCheck;
