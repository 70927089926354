export enum OrderStatusText {
  CANCELED = 'abgebrochen',
  CLOSED = 'ausgeführt',
  NOTASSIGNED = 'Warten auf Geldeingang',
  OPEN = 'offen',
  PENDING = 'vorgemerkt',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  RUNNING = 'Warten auf Geldeingang',
  /*
    SUCCEEDED
    FAILED
    TIMED_OUT
    ABORTED
  */
}

export const getOrderStatusText = (status: string) => {
  if (status in OrderStatusText) {
    return OrderStatusText[status as keyof typeof OrderStatusText];
  }
  return status;
};

export const getOrderStatusVariant = (status: string) => {
  switch (status) {
    case 'CLOSED':
      return 'success';
    case 'NOTASSIGNED':
      return 'error';
    case 'PENDING':
      return 'warning';
    case 'OPEN':
      return 'info';
    default:
      return 'info';
  }
};
