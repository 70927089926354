import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgIpsCiThin = function (props: SVGProps<SVGSVGElement>) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      viewBox="0 0 24 24"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill={`url(#${_id})`}
        d="M5.25 21.375H21a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.75 0v2.625H5.25a.375.375 0 0 0 0 .75m13.5-3.75H3.375V15a.375.375 0 0 0-.75 0v3c0 .207.168.375.375.375h15.75a.375.375 0 0 0 0-.75M20.625 7.5v7.125H5.25a.375.375 0 0 0 0 .75H21a.375.375 0 0 0 .375-.375V7.5a.375.375 0 0 0-.75 0m-1.5-4.5a.375.375 0 0 0-.375-.375H3A.375.375 0 0 0 2.625 3v9c0 .207.168.375.375.375h15.75a.375.375 0 0 0 .375-.375zm-.75.375v8.25h-15v-8.25zM10.5 5.625a1.876 1.876 0 1 0 .001 3.751 1.876 1.876 0 0 0-.001-3.751m0 .75a1.125 1.125 0 1 1 0 2.25 1.125 1.125 0 0 1 0-2.25"
      />
      <defs>
        <linearGradient
          id={_id}
          x1={1}
          x2={27.113}
          y1={23}
          y2={8.312}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939d4" />
          <stop offset={0.48} stopColor="#5d4df8" />
          <stop offset={0.74} stopColor="#b459f9" />
          <stop offset={1} stopColor="#fe3b68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgIpsCiThin;
