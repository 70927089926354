import React, { CSSProperties, FC } from 'react';

import styles from './Icon.module.css';

/** Sizes */
export enum IconSize {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xl',
  Inline = 'inline',
}

/** Animations */
export type IconAnimation = 'spinning' | '';

export type IconProps = {
  /**
   * Represents the name of icon and returns a function using SVGR (https://react-svgr.com/).
   */
  name: React.ComponentType | keyof JSX.IntrinsicElements;
  /**
   * Scales the icon in both width and height.
   * Both a string stating one of the options (e.g. 'sm') or
   * a custom size via a css value string (e.g. '128px') is possible.
   */
  size?: IconSize | string;
  /**
   * Used to flip the icon horizontally.
   * It is either false or true.
   */
  flip?: boolean;
  /**
   * If a valid animation name is passed, a css animation is applied to the Icon.
   * E.g. 'spinning' for a spinning loading spinner icon.
   */
  animation?: IconAnimation;
  /**
   * Defining the width of the stroke to be applied to the shape.
   */
  strokeWidth?: string;
  /**
   * Determines the stroke color of the icon.
   */
  stroke?: string;
  /**
   * Determines the fill color of the icon.
   */
  fill?: string;
  /**
   * Sets the text color of the icon, for use of `currentcolor` in styles
   */
  color?: string;
  className?: string;
  style?: CSSProperties;
};

export const Icon: FC<IconProps> = ({
  animation,
  className = '',
  color,
  fill,
  flip = false,
  name,
  size = 'md',
  stroke,
  strokeWidth,
  style,
  ...rest
}) => {
  const Tag = name ?? 'div';

  const visualProps: Record<string, unknown> = {
    ...(strokeWidth ? { strokeWidth } : {}),
    ...(stroke ? { stroke } : {}),
    ...(fill ? { fill } : {}),
    ...(color ? { color } : {}),
  };

  return (
    <Tag
      data-size={size}
      data-flip={flip.toString()}
      data-animation={animation}
      className={[styles.icon, className].join(' ')}
      style={{
        ...style,
        ...(!Object.values(IconSize).includes(size as IconSize)
          ? {
              '--custom-size': size,
            }
          : {}),
      }}
      {...visualProps}
      {...rest}
    />
  );
};
