import { useUniqueInlineId } from '@inline-svg-unique-id/react';
import type { SVGProps } from 'react';
const SvgInvestmentGesundheitInnovationGradient = function (
  props: SVGProps<SVGSVGElement>,
) {
  const _id = useUniqueInlineId();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width="1em"
      height="1em"
      {...props}
    >
      <path
        fill={`url(#${_id})`}
        d="M19.02 28.5h-7.68l-.02-.47c-.11-2.01-.8-3.54-1.1-4.1a2.09 2.09 0 0 1-1.99-2.09V10.16c0-1.12.89-2.04 1.99-2.09.29-.56.99-2.09 1.1-4.1l.02-.47h7.68l.02.47c.11 2.01.8 3.54 1.1 4.1 1.11.05 1.99.97 1.99 2.09v4.07h.66c.54 0 .97.44.97.97v1.59c0 .54-.44.97-.97.97h-.66v4.07c0 1.12-.89 2.04-1.99 2.09-.29.56-.99 2.09-1.1 4.1l-.02.47Zm-6.74-1h5.8c.15-1.57.61-2.83.94-3.57h-5.38v-1h6.4c.6 0 1.09-.49 1.09-1.09V10.16c0-.6-.49-1.09-1.09-1.09H18.3v-1h.72c-.34-.75-.79-2-.94-3.57h-5.8c-.15 1.57-.61 2.83-.94 3.57h5.34v1h-6.36c-.6 0-1.09.49-1.09 1.09v11.68c0 .6.49 1.09 1.09 1.09h1.72v1h-.7c.34.75.79 2 .94 3.57m9.85-10.73h.66l-.03-1.56h-.64v1.56Zm-4.82 5.34h-4.27c-1.65 0-3-1.34-3-3v-6.22c0-1.65 1.34-3 3-3h4.27c1.65 0 3 1.34 3 3v6.22c0 1.65-1.34 3-3 3m-6.27-5.61v2.61c0 1.1.9 2 2 2h4.27c1.1 0 2-.9 2-2V16.5h-.55l-1.23 3.13-1.14-4.11-.26.67-.46-.18v.5h-1.8l-.32-.82-1.05 3.78-1.17-2.96h-.28Zm5.51-4.13 1.14 4.11.39-.98h1.23v-2.61c0-1.1-.9-2-2-2h-4.27c-1.1 0-2 .9-2 2v2.61H12l.32.82 1.05-3.78 1.17 2.96h.78z"
      />
      <defs>
        <linearGradient
          id={_id}
          x1="0%"
          x2="50%"
          y1="50%"
          y2="0%"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#142279" />
          <stop offset={0.23} stopColor="#1939D4" />
          <stop offset={0.48} stopColor="#5D4DF8" />
          <stop offset={0.74} stopColor="#B459F9" />
          <stop offset={1} stopColor="#FE3B68" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default SvgInvestmentGesundheitInnovationGradient;
