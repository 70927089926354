import { LOCAL_DOMAIN, REMOTE_DOMAIN } from '../../constants/cookies';

export function setCookie(
  key: string,
  value = '',
  maxAge: number | undefined = 1,
  domain = REMOTE_DOMAIN,
) {
  let cookie = `${key}=${value || ''};path=/;`;
  if (maxAge) cookie += `max-age=${maxAge};`;
  if (window.location.hostname === 'localhost') {
    // eslint-disable-next-line no-console
    console.warn(
      `Please use ${LOCAL_DOMAIN} instead of localhost for local development in order to make auth work.`,
    );
  } else if (
    process.env.MODE?.includes('-local') ||
    process.env.MODE?.includes('-e2e')
  ) {
    cookie += `domain=${window.location.hostname};`;
  } else {
    cookie += `samesite=none;secure;domain=${domain};`;
  }

  document.cookie = cookie;

  return cookie;
}
