import { BusinessPartnerRecord, SubscriptionPackage } from '@quirion/types';

const SubscriptionOrder = {
  [SubscriptionPackage.Unset]: 0,
  [SubscriptionPackage.Digital]: 1,
  [SubscriptionPackage.Premium]: 2,
  [SubscriptionPackage.Privat]: 3,
};

const getOrderValue = (subscriptionType: SubscriptionPackage): number =>
  SubscriptionOrder[subscriptionType];

/**
 * @description Returns the highest subscription type from bp objects if there is one.
 * @param bps
 * @returns string of type SubscriptionPackage
 */
export const getHighestPackageFromBps = (
  bps: BusinessPartnerRecord,
): SubscriptionPackage => {
  if (Object.keys(bps).length === 0) {
    return SubscriptionPackage.Unset;
  }

  const highestPackage = Object.values(bps).reduce((highest, bp) => {
    if (!bp.subscriptionType) {
      return highest;
    }

    const highestValue = getOrderValue(highest);
    const currentValue = getOrderValue(bp.subscriptionType);

    if (highestValue >= currentValue) {
      return highest;
    }

    return bp.subscriptionType;
  }, SubscriptionPackage.Unset);

  return highestPackage;
};
