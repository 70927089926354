import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import TagManager from 'react-gtm-module';

import { getGtmId, TrackingObject } from '@quirion/utils';

import { getAppMode } from 'lib/env';

type TrackingContextOptions = {
  pushToDataLayer(trackingObject: TrackingObject): void;
};

export const TrackingContext = createContext<TrackingContextOptions>({
  pushToDataLayer: () => {},
});

export const useTrackingContext = () => useContext(TrackingContext);

export const TrackingProvider: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    const gtmId = getGtmId(getAppMode());

    if (gtmId) TagManager.initialize({ gtmId });
  }, []);

  const pushToDataLayer = useMemo(
    () => (trackingObject: TrackingObject) => {
      TagManager.dataLayer({
        dataLayer: {
          ...trackingObject,
          timestamp: Math.floor(Date.now() / 1000),
        },
      });
    },
    [],
  );

  const value = useMemo(
    () => ({
      pushToDataLayer,
    }),
    [pushToDataLayer],
  );

  return (
    <TrackingContext.Provider value={value}>
      {children}
    </TrackingContext.Provider>
  );
};
