/**
 * @deprecated
 */
export enum SubscriptionPackageLegacy {
  RegularOld = 'Regular', // old package type
  PremiumOld = 'Premium', // old package type
  ComfortOld = 'Comfort', // old package type
}

export enum SubscriptionPackage {
  Digital = 'DIGITAL',
  Premium = 'PREMIUM',
  Privat = 'PRIVAT',
  Unset = '',
}
