import { SVGProps } from 'react';

import { SvgBpChild, SvgBpJoint, SvgBpSingle } from '@quirion/assets';
import { AccountType } from '@quirion/types';

export const ContractIconsGradient: Record<
  AccountType,
  (props: SVGProps<SVGSVGElement>) => JSX.Element
> = {
  [AccountType.SingleAccount]: SvgBpSingle,
  [AccountType.ChildSingleAccount]: SvgBpChild,
  [AccountType.ChildJointAccount]: SvgBpChild,
  [AccountType.JointAccount]: SvgBpJoint,
  /** @deprecated */
  [AccountType.ChildAccount]: SvgBpChild,
  /** @deprecated */
  [AccountType.ChildJoinedAccount]: SvgBpChild,
  /** @deprecated */
  [AccountType.JoinedAccount]: SvgBpJoint,
};

export const getContractIconGradient = (accountType: AccountType) => {
  const icon = ContractIconsGradient[accountType];
  return icon || SvgBpSingle;
};
