/**
 * @todo Improve typescript types, e.g. removing {@type {StringDictionary}} and using `as const`.
 */
import COUNTRIES_ALPHA3 from './json/countries_alpha3.json'; // ISO 3166-1 alpha-3
import COUNTRIES_ALPHA3_TO_ALPHA2 from './json/countries_alpha3_to_alpha2.json';
import INDUSTRIES_SRC from './json/industries.json';
import COUNTRIES_ISO3166 from './json/iso3166.json';
import OCCUPATIONS_SRC from './json/occupations.json';
import WPHG_HELP_TEXTS from './json/wphg_help_texts.json';

type StringDictionary = { [key: string]: string };

export const ACCOUNT_TYPES: StringDictionary = {
  singleAccount: 'Einzelkonto',
  jointAccount: 'Gemeinschaftskonto',
  joinedAccount: 'Gemeinschaftskonto', // legacy support
  childSingleAccount: 'Kinderkonto',
  childJointAccount: 'Kinderkonto',
  childJoinedAccount: 'Kinderkonto', // legacy support
};

export const COUNTRIES = COUNTRIES_ALPHA3;
export const COUNTRIES_ALPHA3_ALPHA2 = COUNTRIES_ALPHA3_TO_ALPHA2;

export const GENDER: StringDictionary = {
  MALE: 'männlich',
  FEMALE: 'weiblich',
};

export const SALUTATION: StringDictionary = {
  MALE: 'Herr',
  FEMALE: 'Frau',
};

export const INDUSTRIES: StringDictionary = INDUSTRIES_SRC;

export const MARITAL_STATUS: StringDictionary = {
  married: 'verheiratet',
  single: 'ledig',
  divorced: 'geschieden',
  widowed: 'verwitwet',
};

export const OCCUPATIONS: StringDictionary = OCCUPATIONS_SRC;

/**
 * Maps from ISO 3166 Alpha 3 country codes to its
 * respecitve locale.
 * For countries with multiple locales, an array
 * of locales is used
 *
 * @example DEU: 'de-DE'
 * @example CAN: ['en-CA', 'fr-CA']
 */
export const ISO31661Alpha3ToLocale: Partial<
  Record<keyof typeof COUNTRIES, string | string[]>
> = {
  BGR: 'bg-BG',
  CZE: 'cs-CZ',
  AUT: 'de-AT',
  DEU: 'de-DE',
  DNK: 'dk-DK',
  CYP: 'el-CY',
  GRC: 'el-GR',
  CAN: ['en-CA', 'fr-CA'],
  GBR: 'en-GB',
  IRL: 'en-IE',
  USA: 'en-US',
  ESP: 'es-ES',
  EST: 'et-EE',
  FIN: 'fi-FI',
  BEL: 'fr-BE',
  FRA: 'fr-FR',
  LUX: 'fr-LU',
  POL: 'pl-PL',
  BRA: 'pt-BR',
  PRT: 'pt-PT',
  ROU: 'ro-RO',
  SVK: 'sk-SK',
  SVN: 'sl-SI',
  SLV: 'sv-SE',
};

export const getCountryMap = (
  key: keyof (typeof COUNTRIES_ISO3166)[number],
  value: keyof (typeof COUNTRIES_ISO3166)[number],
) =>
  Object.fromEntries(
    COUNTRIES_ISO3166.map((item) => {
      if (key in item && value in item) {
        return [item[key], item[value]];
      }
      throw new Error(`Cannot find "${key}" and/or "${value}" as key of item.`);
    }),
  );

export const COUNTRY_CODE_MAP = [
  { '7': 'Abchasien' },
  { '93': 'Afghanistan' },
  { '20': 'Ägypten' },
  { '355': 'Albanien' },
  { '213': 'Algerien' },
  { '1': 'Amerikanisch-Samoa' },
  { '376': 'Andorra' },
  { '244': 'Angola' },
  { '1': 'Anguilla' },
  { '1': 'Antigua und Barbuda' },
  { '240': 'Äquatorialguinea' },
  { '54': 'Argentinien' },
  { '374': 'Armenien' },
  { '297': 'Aruba' },
  { '247': 'Sankt Helena Ascension und Tristan da Cunha Ascension' },
  { '994': 'Aserbaidschan' },
  { '251': 'Äthiopien' },
  { '61': 'Australien' },
  { '1': 'Bahamas' },
  { '973': 'Bahrain' },
  { '880': 'Bangladesch' },
  { '1': 'Barbados' },
  { '375': 'Weißrussland Belarus' },
  { '32': 'Belgien' },
  { '501': 'Belize' },
  { '229': 'Benin' },
  { '1': 'Bermuda' },
  { '975': 'Bhutan' },
  { '591': 'Bolivien' },
  { '599': 'Bonaire' },
  { '387': 'Bosnien und Herzegowina' },
  { '267': 'Botswana' },
  { '55': 'Brasilien' },
  { '673': 'Brunei' },
  { '359': 'Bulgarien' },
  { '226': 'Burkina Faso' },
  { '257': 'Burundi' },
  { '56': 'Chile' },
  { '86': 'Volksrepublik China' },
  { '682': 'Cookinseln' },
  { '506': 'Costa Rica' },
  { '599': 'Curaçao' },
  { '45': 'Dänemark' },
  { '49': 'Deutschland' },
  { '1': 'Dominica' },
  { '1': 'Dominikanische Republik' },
  { '253': 'Dschibuti' },
  { '593': 'Ecuador' },
  { '503': 'El Salvador' },
  { '225': 'Elfenbeinküste' },
  { '291': 'Eritrea' },
  { '372': 'Estland' },
  { '500': 'Falklandinseln' },
  { '298': 'Färöer' },
  { '679': 'Fidschi' },
  { '358': 'Finnland' },
  { '33': 'Frankreich (ohne Überseegebiete)' },
  { '594': 'Französisch-Guayana' },
  { '689': 'Französisch-Polynesien' },
  { '241': 'Gabun' },
  { '220': 'Gambia' },
  { '995': 'Georgien' },
  { '233': 'Ghana' },
  { '350': 'Gibraltar' },
  { '1': 'Grenada' },
  { '30': 'Griechenland' },
  { '299': 'Grönland' },
  { '590': 'Guadeloupe' },
  { '1': 'Guam' },
  { '502': 'Guatemala' },
  { '224': 'Guinea' },
  { '245': 'Guinea-Bissau' },
  { '592': 'Guyana' },
  { '509': 'Haiti' },
  { '1': 'Hawaii' },
  { '504': 'Honduras' },
  { '852': 'Hongkong' },
  { '91': 'Indien' },
  { '62': 'Indonesien' },
  { '964': 'Irak' },
  { '98': 'Iran' },
  { '353': 'Irland' },
  { '354': 'Island' },
  { '972': 'Israel' },
  { '39': 'Italien' },
  { '1': 'Jamaika' },
  { '81': 'Japan' },
  { '967': 'Jemen' },
  { '962': 'Jordanien' },
  { '1': 'Kaimaninseln' },
  { '855': 'Kambodscha' },
  { '237': 'Kamerun' },
  { '1': 'Kanada' },
  { '238': 'Kap Verde' },
  { '7': 'Kasachstan' },
  { '974': 'Katar' },
  { '254': 'Kenia' },
  { '996': 'Kirgisistan' },
  { '686': 'Kiribati' },
  { '61': 'Kokosinseln' },
  { '57': 'Kolumbien' },
  { '269': 'Komoren' },
  { '242': 'Republik Kongo' },
  { '243': 'Demokratische Republik Kongo' },
  { '383': 'Kosovo' },
  { '385': 'Kroatien' },
  { '53': 'Kuba' },
  { '965': 'Kuwait' },
  { '856': 'Laos' },
  { '266': 'Lesotho' },
  { '371': 'Lettland' },
  { '961': 'Libanon' },
  { '231': 'Liberia' },
  { '218': 'Libyen' },
  { '423': 'Liechtenstein' },
  { '370': 'Litauen' },
  { '352': 'Luxemburg' },
  { '853': 'Macau' },
  { '261': 'Madagaskar' },
  { '265': 'Malawi' },
  { '60': 'Malaysia' },
  { '960': 'Malediven' },
  { '223': 'Mali' },
  { '356': 'Malta' },
  { '212': 'Marokko' },
  { '692': 'Marshallinseln' },
  { '596': 'Martinique' },
  { '222': 'Mauretanien' },
  { '230': 'Mauritius' },
  { '262': 'Mayotte' },
  { '389': 'Nordmazedonien' },
  { '52': 'Mexiko' },
  { '691': 'Mikronesien' },
  { '373': 'Republik Moldau' },
  { '377': 'Monaco' },
  { '976': 'Mongolei' },
  { '382': 'Montenegro' },
  { '1': 'Montserrat' },
  { '258': 'Mosambik' },
  { '95': 'Myanmar' },
  { '264': 'Namibia' },
  { '674': 'Nauru' },
  { '977': 'Nepal' },
  { '687': 'Neukaledonien' },
  { '64': 'Neuseeland' },
  { '505': 'Nicaragua' },
  { '31': 'Niederlande' },
  { '227': 'Niger' },
  { '234': 'Nigeria' },
  { '683': 'Niue' },
  { '850': 'Nordkorea' },
  { '6723': 'Norfolkinsel' },
  { '47': 'Norwegen' },
  { '968': 'Oman' },
  { '43': 'Österreich' },
  { '670': 'Osttimor' },
  { '92': 'Pakistan' },
  { '970': 'Palästinensische Autonomiegebiete' },
  { '680': 'Palau Palau' },
  { '507': 'Panama' },
  { '675': 'Papua-Neuguinea' },
  { '595': 'Paraguay' },
  { '51': 'Peru' },
  { '63': 'Philippinen' },
  { '649': 'Pitcairninseln' },
  { '48': 'Polen' },
  { '351': 'Portugal' },
  { '1': 'Puerto Rico' },
  { '262': 'Réunion' },
  { '250': 'Ruanda' },
  { '40': 'Rumänien' },
  { '7': 'Russland' },
  { '599': 'Saba' },
  { '508': 'Sankt Pierre und Miquelon' },
  { '677': 'Salomonen' },
  { '260': 'Sambia' },
  { '685': 'Samoa' },
  { '378': 'San Marino' },
  { '239': 'São Tomé und Príncipe São' },
  { '966': 'Saudi-Arabien' },
  { '46': 'Schweden' },
  { '41': 'Schweiz' },
  { '221': 'Senegal' },
  { '381': 'Serbien' },
  { '248': 'Seychellen' },
  { '232': 'Sierra Leone' },
  { '263': 'Simbabwe' },
  { '65': 'Singapur' },
  { '599': 'Sint Eustatius' },
  { '1': 'Sint Maarten' },
  { '421': 'Slowakei' },
  { '386': 'Slowenien' },
  { '252': 'Somalia' },
  { '34': 'Spanien' },
  { '94': 'Sri Lanka' },
  { '290': 'Sankt Helena Ascension und Tristan da Cunha' },
  { '1': 'St. Kitts und Nevis' },
  { '1': 'St. Lucia' },
  { '1': 'St. Vincent und die Grenadinen' },
  { '27': 'Südafrika' },
  { '249': 'Sudan' },
  { '249': 'Südsudan' },
  { '82': 'Südkorea' },
  { '7': 'Südossetien' },
  { '597': 'Suriname' },
  { '268': 'Swasiland' },
  { '963': 'Syrien' },
  { '992': 'Tadschikistan' },
  { '886': 'Taiwan' },
  { '255': 'Tansania' },
  { '66': 'Thailand' },
  { '228': 'Togo' },
  { '690': 'Tokelau' },
  { '676': 'Tonga' },
  { '1': 'Trinidad und Tobago' },
  { '235': 'Tschad' },
  { '420': 'Tschechien' },
  { '216': 'Tunesien' },
  { '90': 'Türkei' },
  { '90': 'Türkische Republik Nordzypern' },
  { '993': 'Turkmenistan' },
  { '1': 'Turks- und Caicosinseln' },
  { '688': 'Tuvalu' },
  { '256': 'Uganda' },
  { '380': 'Ukraine' },
  { '36': 'Ungarn' },
  { '598': 'Uruguay' },
  { '1': 'Vereinigte Staaten USA' },
  { '998': 'Usbekistan' },
  { '678': 'Vanuatu' },
  { '58': 'Venezuela' },
  { '971': 'Vereinigte Arabische Emirate' },
  { '44': 'Vereinigtes Königreich' },
  { '84': 'Vietnam' },
  { '681': 'Wallis und Futuna' },
  { '61': 'Weihnachtsinsel' },
  { '236': 'Zentralafrikanische Republik' },
  { '357': 'Zypern' },
];

export { WPHG_HELP_TEXTS };

/**
 * Describes the standard score (z-score) of the standard deviation from the expected value in which 90% of the measured values lie.
 *
 * @see https://de.wikipedia.org/wiki/Normalverteilung#:~:text=90%C2%A0%25%20aller%20Messwerte,vom%20Erwartungswert%2C
 */
export const STANDARD_NORMAL_DISTRIBUTION = 1.645;
export const MONTHS_PER_YEAR = 12;
